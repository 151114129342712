import { lazy } from "react";

const GlobalIndex = lazy(() => import("src/views/arkadu/global/GlobalIndex"));
const GlobalLayout = lazy(() => import("src/views/arkadu/global/GlobalLayout"));

export default [
  {
    element: <GlobalLayout />,
    children: [
      {
        index: true,
        element: <GlobalIndex />,
      },
    ],
  },
];
