import React from "react";

const useStoreDB = (dbName) => {
  const [storeState, setStoreState] = React.useState({
    dbLoading: false,
    error: null,
    version: 3,
    db: null,
    dbInitialized: false,
  });

  const open = async () => {
    if (!dbName) {
      console.log("no dbName");
      return;
    }
    let dbRequest = indexedDB.open(dbName);
    dbRequest.onsuccess = (event) => {
      console.log("db opened", event);
      console.log("db version", event.target.result.version);
      setStoreState({
        ...storeState,
        db: event.target.result,
        version: event.target.result.version,
        dbInitialized: true,
      });
    };
    dbRequest.onerror = (event) => {
      console.log("db error", event);
    };
    dbRequest.onupgradeneeded = (event) => {
      console.log("upgrade needed", event);
      const db = event.target.result;
      const objectsStores = [
        "user",
        "customers",
        "settings",
        "members",
        "config",
      ];
      objectsStores.forEach((store) => {
        const objRequest = db.createObjectStore(store, { keyPath: "id" });
        objRequest.onsuccess = (event) => {
          console.log("object store created");
        };
        objRequest.onerror = (event) => {
          console.log("error creating object store");
        };
        objRequest.oncomplete = (event) => {
          console.log("object store creation complete");
        };
      });
    };
  };

  const initialized = React.useRef(false);
  const read = async (storeName, key) => {
    return new Promise((resolve, reject) => {
      var db = storeState.db;
      console.log("reading", storeName, key);
      if (!db) {
        console.log("no db");
        return;
      }
      if (!db.objectStoreNames.contains(storeName)) {
        console.log("no object store");
        return;
      }
      if (!key) {
        // get first
        const store = db.transaction(storeName).objectStore(storeName);
        const request = store.getAll(null, 1);
        request.onsuccess = (event) => {
          console.log("data read", event.target.result[0]);
          resolve(event.target.result[0]);
        };
        request.onerror = (event) => {
          console.log("error reading data", event);
          reject(event);
        };
      } else {
        // get the object store if not key return first
        const store = db.transaction(storeName).objectStore(storeName);
        const request = store.get(key);
        request.onsuccess = (event) => {
          console.log("data read", event.target.result);
          resolve(event.target.result);
        };
        request.onerror = (event) => {
          console.log("error reading data", event);
          reject(event);
        };
      }
    });
  };

  const internalWrite = async (storeName, data) => {
    var db = storeState.db;
    const transaction = db.transaction(storeName, "readwrite");
    const store = transaction.objectStore(storeName);
    const request = store.put(data);
    request.onsuccess = (event) => {
      console.log("data added");
    };
    request.onerror = (event) => {
      console.log("error adding data", event);
    };
  };

  const write = async (storeName, data) => {
    var db = storeState.db;
    console.log(`write ${storeName} ${data} ${db?.objectStoreNames[0]}`);
    if (!db) {
      console.log("no db");
      return;
    }
    if (!db.objectStoreNames.contains(storeName)) {
      // close the db
      try {
        await db.close();
      } catch (e) {
        console.log("error closing db", e);
      }
      dbVersion++;

      console.log("creating object store db version", dbVersion);
      db = await indexedDB.open(dbName, dbVersion);
      setStoreState({
        ...storeState,
        db: db,
        version: db,
        dbInitialized: true,
      });
      console.log("db opened", db);
      db.onupgradeneeded = (event) => {
        console.log("upgrade needed", event);
        const db = event.target.result;
        const objRequest = db.createObjectStore(storeName, { keyPath: "id" });
        objRequest.onsuccess = (event) => {
          console.log("object store created");
        };
        objRequest.onerror = (event) => {
          console.log("error creating object store");
        };
        objRequest.oncomplete = (event) => {
          console.log("object store creation complete");
        };
      };
      db.onsuccess = (event) => {
        console.log("db opened", event);
        internalWrite(storeName, data);
      };
      db.onerror = (event) => {
        console.log("db error", event);
      };
      db.onblocked = (event) => {
        console.log("db blocked", event);
      };
    } else {
      internalWrite(storeName, data);
    }
  };

  React.useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      open();
    }
    console.log("useStoreDB initialized", dbName, storeState);
  }, [storeState.db, initialized]);

  return { read, write, ...storeState };
};

export default useStoreDB;
