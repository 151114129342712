import { Grid2 as Grid, Typography } from "@mui/material";
import React from "react";

// añadir route a este componente

const licensesDemo = [
  {
    id: 2054746,
    alias: "DISTRIBUIDORA METROPOLIS, C.A.  ",
    owner: {
      id: 2053772,
      dob: "1981-04-07",
      email: "metropolisdistribuidora@gmail.com",
      avatar: "",
      gender: "female",
      created: "2020-12-10T17:57:00.23585+00:00",
      updated: "2024-08-14T13:48:07.68763+00:00",
      fullname: "Lisbeth Zabaleta",
      is_legal: false,
      is_active: true,
      last_name: "Zabaleta",
      first_name: "Lisbeth",
      last_login: "2024-08-14T13:48:21.174122+00:00",
      is_completed: true,
      email_verified: true,
      main_identity_id: null,
    },
    phone: null,
    closed: false,
    number: "2558",
    account: {
      id: 20216465,
      total: 106572.84,
      avatar: "",
      created: "2020-12-18T17:59:33.366373+00:00",
      updated: "2021-03-03T15:14:48.04163+00:00",
      customer: {
        id: 2050421,
        icon: "logo_horizontal_los_guayos.jpg",
        logo: "customers/logo/logo_ORVJzuV.jpg",
        name: "Los Guayos",
        header: "header.jpg",
        created: "2020-06-23T16:42:28.690274+00:00",
        updated: "2024-06-03T18:07:06.491762+00:00",
        owner_id: 1,
        timezone: "America/Caracas",
        is_active: true,
        shortname: "losguayos",
        is_private: false,
        description: "Alcaldía del Municipio Los Guayos, Carabobo, Venezuela.",
        custom_domain: "alcaldialosguayos.org",
        order_priority: 99,
        geolocalization: "nets_geo.Municipality:84",
      },
      identity: {
        id: 2052183,
        flag: "flags/32px-Flag_of_Venezuela.svg.png",
        main: true,
        name: "Registro de Información Fiscal",
        photo: "",
        acronym: "RIF",
        country: "Venezuela",
        created: "2020-12-10T18:13:15.197376+00:00",
        expired: false,
        updated: "2024-02-14T14:48:08.368839+00:00",
        is_legal: true,
        iso_code: "VE",
        owner_id: 2053772,
        short_id: "RIF J-29588117-7",
        is_active: true,
        validated: false,
        country_id: 234,
        is_natural: false,
        issue_date: "2008-05-08",
        legal_name: "DISTRIBUIDORA METROPOLIS C.A.",
        expire_date: "2021-07-19",
        national_id: "J-29588117-7",
        validator_name: null,
        validated_by_id: null,
        validation_status: "not_requested",
      },
      owner_id: 2053772,
      customer_id: 2050421,
    },
    address:
      "CENTRO EMPRESARIAL FRANCISQUI, LOCAL 21, URB. INDUSTRIAL LA CARACARITA EDFICIO MUNICIPIO LOS GUAYOS LOS GUAYOS",
    created: "2020-12-03T04:55:52.201168+00:00",
    updated: "2024-11-14T14:26:27.729591+00:00",
    category: "FIJA",
    identity: {
      id: 2052183,
      flag: "flags/32px-Flag_of_Venezuela.svg.png",
      main: true,
      name: "Registro de Información Fiscal",
      email: "metropolisdistribuidora@gmail.com",
      files: null,
      photo: "",
      acronym: "RIF",
      country: "Venezuela",
      created: "2020-12-10T18:13:15.197376+00:00",
      expired: false,
      updated: "2024-02-14T14:48:08.368839+00:00",
      is_legal: true,
      iso_code: "VE",
      owner_id: 2053772,
      short_id: "RIF J-29588117-7",
      is_active: true,
      validated: false,
      country_id: 234,
      is_natural: false,
      issue_date: "2008-05-08",
      legal_name: "DISTRIBUIDORA METROPOLIS C.A.",
      expire_date: "2021-07-19",
      national_id: "J-29588117-7",
      validator_name: null,
      validated_by_id: null,
      document_type_id: 2,
      validation_status: "not_requested",
    },
    branch_id: 2050683,
    has_legal: false,
    is_active: true,
    is_closed: false,
    is_liquor: true,
    account_id: 20216465,
    activities: [2052395, 2053049, 2053050, 2053051, 2053118],
    issue_date: "2021-01-15T17:00:00+00:00",
    category_id: 1,
    customer_id: 2050421,
    is_temporal: false,
    company_size: "small",
    pending_amount: 0,
    real_estate_id: null,
    start_activity: "2020-11-01T16:00:00+00:00",
    periods_pending: 0,
    declarations_count: 49,
    pending_tax_amount: 0,
    pending_declarations: 0,
  },
  {
    id: 2055735,
    alias: "UNION QUIMICA,S.A.  ",
    owner: {
      id: 2055454,
      dob: "2021-11-18",
      email: "unionquimicasa@gmail.com",
      avatar: "",
      gender: "male",
      created: "2021-01-20T12:00:31.204659+00:00",
      updated: "2024-08-23T12:25:24.341795+00:00",
      fullname: "UNION QUIMICA,S.A. UNION QUIMICA,S.A.",
      is_legal: false,
      is_active: true,
      last_name: "UNION QUIMICA,S.A.",
      first_name: "UNION QUIMICA,S.A.",
      last_login: "2024-08-23T12:25:53.409516+00:00",
      is_completed: true,
      email_verified: true,
      main_identity_id: null,
    },
    phone: null,
    closed: false,
    number: "45681",
    account: {
      id: 20217225,
      total: 55904.7,
      avatar: "",
      created: "2021-02-01T12:49:46.342304+00:00",
      updated: "2021-03-02T21:09:57.539324+00:00",
      customer: {
        id: 2050421,
        icon: "logo_horizontal_los_guayos.jpg",
        logo: "customers/logo/logo_ORVJzuV.jpg",
        name: "Los Guayos",
        header: "header.jpg",
        created: "2020-06-23T16:42:28.690274+00:00",
        updated: "2024-06-03T18:07:06.491762+00:00",
        owner_id: 1,
        timezone: "America/Caracas",
        is_active: true,
        shortname: "losguayos",
        is_private: false,
        description: "Alcaldía del Municipio Los Guayos, Carabobo, Venezuela.",
        custom_domain: "alcaldialosguayos.org",
        order_priority: 99,
        geolocalization: "nets_geo.Municipality:84",
      },
      identity: {
        id: 2054213,
        flag: "flags/32px-Flag_of_Venezuela.svg.png",
        main: false,
        name: "Registro de Información Fiscal",
        photo: "",
        acronym: "RIF",
        country: "Venezuela",
        created: "2021-01-20T12:56:13.350995+00:00",
        expired: false,
        updated: "2023-08-14T13:36:41.6528+00:00",
        is_legal: true,
        iso_code: "VE",
        owner_id: 2055454,
        short_id: "RIF J-07582726-0",
        is_active: true,
        validated: false,
        country_id: 234,
        is_natural: false,
        issue_date: "2019-04-08",
        legal_name: "UNION QUIMICA,S.A.",
        expire_date: "2022-04-08",
        national_id: "J-07582726-0",
        validator_name: null,
        validated_by_id: null,
        validation_status: "not_requested",
      },
      owner_id: 2055454,
      customer_id: 2050421,
    },
    address:
      "CARRETERA NAC LOS GUAYOS VIA GUACARA SECTOR AROMO MOCHO GALPON 3359-351 SECTOR AROMO MOCHO SECTOR LAS GARCITAS",
    created: "2020-12-03T04:56:35.752679+00:00",
    updated: "2024-11-09T23:33:29.597749+00:00",
    category: "FIJA",
    identity: {
      id: 2054213,
      flag: "flags/32px-Flag_of_Venezuela.svg.png",
      main: false,
      name: "Registro de Información Fiscal",
      email: "unionquimicasa@gmail.com",
      files: [
        {
          id: 2051242,
          file: "id_cards/2023/08/14/RIF_UNION_QUIMICAS.A_ACTUAL.pdf",
          side: "front",
          created: "2023-08-14T13:37:24.449916+00:00",
          is_active: true,
        },
      ],
      photo: "",
      acronym: "RIF",
      country: "Venezuela",
      created: "2021-01-20T12:56:13.350995+00:00",
      expired: false,
      updated: "2023-08-14T13:36:41.6528+00:00",
      is_legal: true,
      iso_code: "VE",
      owner_id: 2055454,
      short_id: "RIF J-07582726-0",
      is_active: true,
      validated: false,
      country_id: 234,
      is_natural: false,
      issue_date: "2019-04-08",
      legal_name: "UNION QUIMICA,S.A.",
      expire_date: "2022-04-08",
      national_id: "J-07582726-0",
      validator_name: null,
      validated_by_id: null,
      document_type_id: 2,
      validation_status: "not_requested",
    },
    branch_id: 2052308,
    has_legal: false,
    is_active: true,
    is_closed: false,
    is_liquor: false,
    account_id: 20217225,
    activities: [2052376, 2052379, 2052393, 2053055, 2053111],
    issue_date: "2020-11-01T16:00:00+00:00",
    category_id: 1,
    customer_id: 2050421,
    is_temporal: false,
    company_size: "small",
    pending_amount: 46108.19,
    real_estate_id: null,
    start_activity: "2020-11-01T16:00:00+00:00",
    periods_pending: 0,
    declarations_count: 49,
    pending_tax_amount: 46108.19,
    pending_declarations: 0,
  },
  {
    id: 2053156,
    alias: "SUPPLY STEEL C.A  ",
    owner: {
      id: 2054609,
      dob: "1981-12-09",
      email: "supstel.ca@gmail.com",
      avatar: "",
      gender: "male",
      created: "2020-12-21T13:01:18.394255+00:00",
      updated: "2024-08-14T13:07:24.544342+00:00",
      fullname: "Ronal Alexander Pérez Carrillo",
      is_legal: false,
      is_active: true,
      last_name: "Pérez Carrillo",
      first_name: "Ronal Alexander",
      last_login: "2024-08-14T13:07:40.382996+00:00",
      is_completed: true,
      email_verified: true,
      main_identity_id: null,
    },
    phone: "04120120605",
    closed: false,
    number: "657",
    account: {
      id: 20216559,
      total: 17550.59,
      avatar: "",
      created: "2020-12-21T14:53:12.827585+00:00",
      updated: "2021-03-02T21:13:20.363172+00:00",
      customer: {
        id: 2050421,
        icon: "logo_horizontal_los_guayos.jpg",
        logo: "customers/logo/logo_ORVJzuV.jpg",
        name: "Los Guayos",
        header: "header.jpg",
        created: "2020-06-23T16:42:28.690274+00:00",
        updated: "2024-06-03T18:07:06.491762+00:00",
        owner_id: 1,
        timezone: "America/Caracas",
        is_active: true,
        shortname: "losguayos",
        is_private: false,
        description: "Alcaldía del Municipio Los Guayos, Carabobo, Venezuela.",
        custom_domain: "alcaldialosguayos.org",
        order_priority: 99,
        geolocalization: "nets_geo.Municipality:84",
      },
      identity: {
        id: 2053414,
        flag: "flags/32px-Flag_of_Venezuela.svg.png",
        main: false,
        name: "Registro de Información Fiscal",
        photo: null,
        acronym: "RIF",
        country: "Venezuela",
        created: "2020-12-21T13:25:24.2647+00:00",
        expired: false,
        updated: "2020-12-21T13:36:09.552255+00:00",
        is_legal: true,
        iso_code: "VE",
        owner_id: 2054609,
        short_id: "RIF J-30889150-9",
        is_active: true,
        validated: false,
        country_id: 234,
        is_natural: false,
        issue_date: "2002-02-19",
        legal_name: "SUPPLY STEEL, C. A",
        expire_date: "2022-05-06",
        national_id: "J-30889150-9",
        validator_name: null,
        validated_by_id: null,
        validation_status: "not_requested",
      },
      owner_id: 2054609,
      customer_id: 2050421,
    },
    address:
      "AVENIDA PRINCIPAL PARAPARAL LOCAL GALPÓN KINGMASTER NRO2-3 CALLE A 1 URBANIZACIÓN PARAPARAL",
    created: "2020-12-03T04:54:26.616509+00:00",
    updated: "2024-10-09T20:22:07.030388+00:00",
    category: "FIJA",
    identity: {
      id: 2053414,
      flag: "flags/32px-Flag_of_Venezuela.svg.png",
      main: false,
      name: "Registro de Información Fiscal",
      email: "supstel.ca@gmail.com",
      files: null,
      photo: null,
      acronym: "RIF",
      country: "Venezuela",
      created: "2020-12-21T13:25:24.2647+00:00",
      expired: false,
      updated: "2020-12-21T13:36:09.552255+00:00",
      is_legal: true,
      iso_code: "VE",
      owner_id: 2054609,
      short_id: "RIF J-30889150-9",
      is_active: true,
      validated: false,
      country_id: 234,
      is_natural: false,
      issue_date: "2002-02-19",
      legal_name: "SUPPLY STEEL, C. A",
      expire_date: "2022-05-06",
      national_id: "J-30889150-9",
      validator_name: null,
      validated_by_id: null,
      document_type_id: 2,
      validation_status: "not_requested",
    },
    branch_id: 2051628,
    has_legal: false,
    is_active: true,
    is_closed: false,
    is_liquor: false,
    account_id: 20216559,
    activities: [2052380, 2053075],
    issue_date: "2020-11-01T16:00:00+00:00",
    category_id: 1,
    customer_id: 2050421,
    is_temporal: false,
    company_size: "large",
    pending_amount: 0,
    real_estate_id: null,
    start_activity: "2020-11-01T16:00:00+00:00",
    periods_pending: 0,
    declarations_count: 47,
    pending_tax_amount: 0,
    pending_declarations: 0,
  },
];

const LicenseListItem = ({ license }) => {
  if (!license) {
    // take one from the demo
    license = licensesDemo[Math.floor(Math.random() * licensesDemo.length)];
  }
  return (
    <Grid
      container
      spacing={2}
      sx={{
        borderBottom: 1,
      }}
    >
      <Grid
        size={{
          sm: 12,
        }}
      >
        <Typography variant="h5">{license.alias}</Typography>
      </Grid>
    </Grid>
  );
};

export default LicenseListItem;
