import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import useIsMountedRef from "./useIsMountedRef";

const useWebSocket = (url) => {
  const ws = useRef(null);
  const isMounted = useIsMountedRef();
  const [wsOnline, setWsOnline] = useState(false);
  const [wsConnecting, setWsConnecting] = useState(false);
  const [wsMessages, setWsMessages] = useState([]);
  const [lastMessage, setLastMessage] = useState(null);
  const [timeWaiting, setTimeWaiting] = useState(1500);
  const [messagesQueue, setMessagesQueue] = useState([]);

  const [lastTry, setLastTry] = useState(0);

  const sendWs = (msg) => {
    setMessagesQueue((prevState) => _.concat(prevState, msg));
  };

  useEffect(() => {
    if (ws && ws.current && wsOnline) {
      if (messagesQueue.length > 0) {
        var mgs = messagesQueue;
        var processed = [];
        mgs.forEach((m, i) => {
          try {
            ws.current.send(m);
            processed.push(i);
          } catch (e) {
            console.log(e);
          }
        });
        setMessagesQueue((prevState) =>
          _.map((m, id) => {
            if (!processed.includes(id)) {
              return m;
            }
          })
        );
      }
    }
  }, [ws, wsOnline, messagesQueue]);

  const openWs = () => {
    if (!wsConnecting && isMounted) {
      setWsConnecting(true);
      setLastTry(new Date());

      setWsOnline(false);
      ws.current = new WebSocket(url);
      ws.current.onopen = (e) => {
        setWsOnline(true);
      };
      ws.current.onmessage = (e) => {
        var m = JSON.parse(e.data);
        setLastMessage(m.message);
        setWsMessages(_.concat(wsMessages, m.message));
      };
      ws.current.onclose = (f) => {
        console.log("closed", f, timeWaiting, wsOnline, wsConnecting);
        setWsOnline(false);
        localStorage.setItem("next", window.location.pathname);
        if (isMounted) {
          setTimeout(() => {
            // window.location.reload();
            openWs();
          }, 3500);
        }
      };
    }
  };

  // const tries = setInterval(() => {
  //   setLastTry(lastTry + timeWaiting);
  //   ws.current = null;
  //   setWsOnline(false);
  //   setWsConnecting(false);
  //   setTimeWaiting(timeWaiting + 1000);
  // }, [timeWaiting]);

  // useEffect(() => {
  //   const interval = setTimeout(() => {
  //     if (!wsOnline) {
  //       console.log('try ws again', timeWaiting, wsOnline, wsConnecting);
  //       setWsConnecting(false);
  //       window.location.reload();
  //     }
  //   }, timeWaiting);
  //   return () => {
  //     console.log('clear interval');

  //   };

  // }, [timeWaiting, wsOnline, wsConnecting]);

  useEffect(() => {
    if (ws && !wsOnline && url && !wsConnecting && isMounted) {
      // setWsConnecting(true);
      openWs();
    }
  }, [url, wsConnecting, wsOnline, lastTry]);

  return { ws, wsOnline, wsMessages, lastMessage, sendWs };
};

export default useWebSocket;
