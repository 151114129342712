import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
const initialState = {
  genericData: {},
  currentGenericElement: null,
  lastUpdated: null,
  dataUpdates: {},
  dataKey: null,
  remoteOptions: {},
  remoteOptionsDone: false,
  genericInitialized: false,
};
const slice = createSlice({
  name: "generics",
  initialState,
  reducers: {
    setGenericState: (state, action) => {
      Object.keys(action.payload).forEach((k) => {
        if (Object.keys(initialState).includes(k)) {
          if (k === "genericData") {
            if (action.payload[k] && action.payload[k][state.dataKey]) {
              state[k][state.dataKey] = action.payload[k][state.dataKey];
            }
          } else {
            state[k] = action.payload[k];
          }
        } else {
          console.error(
            `generics does not have key ${k}, check setEventadsState call`
          );
        }
      });

      saveState(state.dataKey, state.genericData);
    },
    updateGenericData: (state, action) => {
      state.genericData[action.payload.key] = action.payload.data;
      state.dataUpdates[action.payload.key] = Date.now();
      state.lastUpdated = Date.now();
      saveData(action.payload.key, state.genericData[action.payload.key]);
    },
    updateGenericElement: (state, action) => {
      var e = action.payload;
      var _updated = false;
      state.genericData[state.dataKey] = state.genericData[state.dataKey]?.map(
        (_e) => {
          if (_e.id === e.id) {
            _updated = true;
            return e;
          }
          return _e;
        }
      );
      // var prev = state.genericData[state.dataKey] || [];
      // var _newData = _.map(prev.concat(), (_e) => {
      //   if (_e.id === e.id) {
      //     _updated = true;
      //     return e;
      //   }
      //   return _e;
      // });

      if (!_updated) {
        state.genericData[state.dataKey]?.push(e);
      }
      // state.genericData = _newData;
      if (state.dataKey)
        saveState(state.dataKey, state.genericData[state.dataKey]);
    },
    pushRemoteOptionsData: (state, action) => {
      state.remoteOptions = {
        ...state.remoteOptions,
        ...action.payload,
      };
    },
    removeGenericElement: (state, action) => {
      var _d = [];
      _.map(state.genericData[state.dataKey].concat(), (_e) => {
        if (_e.id !== action.payload.id) {
          _d.push(_e);
        }
      });
      console.log(action.payload, _d);
      state.genericData[state.dataKey] = _d;
      saveState(state.dataKey, _d[state.dataKey]);
      if (
        state.currentGenericElement &&
        state.currentGenericElement.id === action.payload.id
      ) {
        state.currentGenericElement = null;
      }
    },
    initialize: (state, action) => {
      state.dataKey = action.payload;
      state.currentGenericElement = null;
      var dataUpdates = localStorage.getItem("dataUpdates");
      if (dataUpdates) {
        dataUpdates = JSON.parse(dataUpdates);
      } else {
        dataUpdates = {};
      }
      state.dataUpdates = dataUpdates;
      state.genericData = {};
      state.remoteOptions = {};
      state.remoteOptionsDone = false;
      state.genericInitialized = false;

      // if _lUp is null, undefined or more than 7 days old, we need to refresh
      if (_lUp) {
        var _now = Date.now();

        if (_now - _lUp > 1000 * 60 * 60 * 24 * 7) {
          _lUp = null;
        }
      }
      // if
      // if (_lUp === null) {
      // TODO: remove this when we have a way to refresh data
      localStorage.removeItem(action.payload);
      localStorage.removeItem(`${action.payload}_updated`);
      // }

      // var _l = loadState(action.payload);
      var _l = null;
      var _lUp = dataUpdates[action.payload] || null;

      var _maxUpdated = null;
      if (_l) {
        // user for each to avoid breaking the loop
        for (var i = 0; i < _l.length; i++) {
          var _i = _l[i];
          if (!_lUp) {
            _maxUpdated = _i.updated;
          } else {
            if (_i.updated > _lUp) {
              _maxUpdated = _i.updated;
            }
          }
        }
        // _.map(_l, (_i) => {
        //   if (!_lUp) {
        //     _maxUpdated = _i.updated;
        //   } else {
        //     if (_i.updated > _lUp) {
        //       _maxUpdated = _i.updated;
        //     }
        //   }
        // });
      }
      console.log("maxUpdated", _maxUpdated, _l);
      state.genericData[action.payload] = _l || [];
      state.lastUpdated = _lUp || null;
      state.remoteOptions = {};
      state.remoteOptionsDone = false;
      state.genericInitialized = true;
    },
  },
});
export const reducer = slice.reducer;

const loadState = (key) => {
  try {
    const serializedData = localStorage.getItem(key);
    if (!serializedData) return undefined;
    let d = JSON.parse(serializedData);

    return Array.isArray(d) ? d : [];
  } catch (e) {
    return undefined;
  }
};
const saveState = async (key, data) => {
  try {
    var _d = data;

    if (Array.isArray(data) && data.length > 0 && data[0].updated_epoch) {
      console.log("sort10");
      _d = _d.sort((a, b) => {
        console.log(
          a.updated_epoch,
          b.updated_epoch,
          a.updated_epoch - b.updated_epoch
        );
        return b.updated_epoch - a.updated_epoch;
      });
    }
    const serializedData = JSON.stringify(_d);
    localStorage.setItem(key, serializedData);
  } catch (e) {
    // Ignore
  }
};
const saveData = async (key, data) => {
  try {
    var _d = data;
    console.log("saveData", data);

    if (Array.isArray(data) && data.length > 0 && data[0].updated_epoch) {
      console.log("sort10");
      _d = _d.sort((a, b) => {
        console.log(
          a.updated_epoch,
          b.updated_epoch,
          a.updated_epoch - b.updated_epoch
        );
        return b.updated_epoch - a.updated_epoch;
      });
    }
    const serializedData = JSON.stringify(_d);
    localStorage.setItem(key, serializedData);
    localStorage.setItem(`${key}_updated`, Date.now());
  } catch (e) {
    // Ignore
  }
};
export const updateGenericElement = (data) => async (dispatch) => {
  dispatch(slice.actions.updateGenericElement(data));
};
export const updateGenericData = (data) => async (dispatch) => {
  dispatch(slice.actions.updateGenericData(data));
};
export const setGenericState = (data) => async (dispatch) => {
  dispatch(slice.actions.setGenericState(data));
};
export const initializedGeneric = (data) => async (dispatch) => {
  dispatch(slice.actions.initialize(data));
};
export const pushRemoteOptionsData = (data) => async (dispatch) => {
  dispatch(slice.actions.pushRemoteOptionsData(data));
};
export const removeGenericElement = (data) => async (dispatch) => {
  dispatch(slice.actions.removeGenericElement(data));
};
