import {
  AddAPhotoRounded,
  PictureAsPdfRounded,
  TableViewRounded,
} from "@mui/icons-material";

import React, { Suspense, lazy } from "react";

// we need a component that receives a name of icon and lazy load the icon and returns the component
const AddIcon = lazy(() => import("@mui/icons-material/AddRounded"));

const DeleteIcon = lazy(() => import("@mui/icons-material/DeleteRounded"));

const CertificateIcon = lazy(
  () => import("@mui/icons-material/WorkspacePremium")
);

const AssignmentIndRounded = lazy(
  () => import("@mui/icons-material/AssignmentIndRounded")
);

const AdminIcon = lazy(
  () => import("@mui/icons-material/AdminPanelSettingsRounded")
);

const ChangeState = lazy(
  () => import("@mui/icons-material/ChangeCircleRounded")
);
const Badge = lazy(() => import("@mui/icons-material/BadgeRounded"));

const Unlink = lazy(() => import("@mui/icons-material/LinkOffRounded"));

const AttachFileRounded = lazy(
  () => import("@mui/icons-material/AttachFileRounded")
);

const PriceChange = lazy(
  () => import("@mui/icons-material/PriceChangeRounded")
);
const Price = lazy(() => import("@mui/icons-material/PriceCheckRounded"));
const AccountBalanceRounded = lazy(
  () => import("@mui/icons-material/AccountBalanceRounded")
);

const DownloadForOfflineRounded = lazy(
  () => import("@mui/icons-material/DownloadForOfflineRounded")
);

const EditAttributesRounded = lazy(
  () => import("@mui/icons-material/EditAttributesRounded")
);

const CalendarIcon = lazy(
  () => import("@mui/icons-material/CalendarTodayRounded")
);
const LinkIcon = lazy(() => import("@mui/icons-material/LinkRounded"));

const EditRounded = lazy(() => import("@mui/icons-material/EditRounded"));

const MapSharp = lazy(() => import("@mui/icons-material/MapSharp"));

const ListAltRounded = lazy(() => import("@mui/icons-material/ListAltRounded"));

const WarningRounded = lazy(() => import("@mui/icons-material/WarningRounded"));

const ModeEditRounded = lazy(
  () => import("@mui/icons-material/ModeEditRounded")
);

const DynamicForm = lazy(
  () => import("@mui/icons-material/DynamicFormRounded")
);

const CardMembership = lazy(
  () => import("@mui/icons-material/CardMembershipRounded")
);

const ChangeCircleRounded = lazy(
  () => import("@mui/icons-material/ChangeCircleRounded")
);

const RefreshRounded = lazy(() => import("@mui/icons-material/RefreshRounded"));

const EyeRounded = lazy(() => import("@mui/icons-material/VisibilityRounded"));

const WaitingRounded = lazy(
  () => import("@mui/icons-material/TimelapseRounded")
);

const CheckBoxRounded = lazy(
  () => import("@mui/icons-material/CheckBoxRounded")
);

const CheckCircleRounded = lazy(
  () => import("@mui/icons-material/CheckCircleRounded")
);
const MoneyOffRounded = lazy(
  () => import("@mui/icons-material/MoneyOffRounded")
);

const UpgradeIcon = lazy(() => import("@mui/icons-material/Upgrade"));
const SyncAltIcon = lazy(() => import("@mui/icons-material/SyncAlt"));
const UploadIcon = lazy(() => import("@mui/icons-material/UploadRounded"));
const TransferIcon = lazy(
  () => import("@mui/icons-material/PresentToAllRounded")
);

const defaultIcons = {
  add: (props) => <AddIcon {...props} />,
  delete: (props) => <DeleteIcon {...props} />,
  certificate: (props) => <CertificateIcon {...props} />,
  assignment: (props) => <AssignmentIndRounded {...props} />,
  admin: (props) => <AdminIcon {...props} />,
  changeState: (props) => <ChangeState {...props} />,
  change: (props) => <ChangeCircleRounded {...props} />,
  identity: (props) => <Badge {...props} />,
  unlink: (props) => <Unlink {...props} />,
  attach: (props) => <AttachFileRounded {...props} />,
  priceChange: (props) => <PriceChange {...props} />,
  price: (props) => <Price {...props} />,
  procedures: (props) => <DynamicForm {...props} />,
  license: (props) => <CardMembership {...props} />,
  payment: (props) => <AccountBalanceRounded {...props} />,
  user: (props) => <AssignmentIndRounded {...props} />,
  file: (props) => <ModeEditRounded {...props} />,
  edit: (props) => <EditRounded {...props} />,
  map: (props) => <MapSharp {...props} />,
  report: (props) => <ListAltRounded {...props} />,
  download: (props) => <DownloadForOfflineRounded {...props} />,
  replace: (props) => <SyncAltIcon {...props} />,
  refresh: (props) => <RefreshRounded {...props} />,
  view: (props) => <EyeRounded {...props} />,
  waiting: (props) => <WaitingRounded {...props} />,
  checkbox: (props) => <CheckBoxRounded {...props} />,
  approve: (props) => <CheckCircleRounded {...props} />,
  exempt: (props) => <MoneyOffRounded {...props} />,
  upgrade: (props) => <UpgradeIcon {...props} />,
  upload: (props) => <UploadIcon {...props} />,
  pdf: (props) => <PictureAsPdfRounded {...props} />,
  xls: (props) => <TableViewRounded {...props} />,
  addImage: (props) => <AddAPhotoRounded {...props} />,
  calendar: (props) => <CalendarIcon {...props} />,
  link: (props) => <LinkIcon {...props} />,
  transfer: (props) => <TransferIcon {...props} />,
};

export const actionIcons = Object.keys(defaultIcons);

const ActionIcons = (props) => {
  const { name, ...rest } = props;

  const Icon = defaultIcons[name];
  return (
    <Suspense fallback={<WarningRounded />}>
      <Icon {...rest} />
    </Suspense>
  );
};

export default ActionIcons;
