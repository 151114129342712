import { lazy } from "react";

const EnvironmentLayout = lazy(
  () => import("src/views/arkadu/environment/EnvironmentLayout")
);
const EnvironmentIndex = lazy(
  () => import("src/views/arkadu/environment/EnvironmentIndex")
);
const EnvironmentRequestDetail = lazy(
  () => import("src/views/arkadu/environment/EnvironmentRequestDetail")
);
const EnvironmentPenaltyDetail = lazy(
  () =>
    import("src/views/arkadu/environment/penalties/EnvironmentPenaltyDetail")
);

export default [
  {
    element: <EnvironmentLayout />,
    children: [
      {
        index: true,
        element: <EnvironmentIndex />,
      },
      {
        path: "penalties/detail/:id",
        element: <EnvironmentPenaltyDetail />,
      },
      {
        path: "request/detail/:requestId",
        element: <EnvironmentRequestDetail />,
      },
      {
        path: ":tabName",
        element: <EnvironmentIndex />,
      },
    ],
  },
];
