/* eslint-disable no-use-before-define */
import {
  AccountBalanceRounded,
  AccountBalanceWalletRounded,
  AlternateEmailRounded,
  ArchiveRounded,
  AssignmentIndRounded,
  AssignmentRounded,
  AtmRounded,
  BarChartRounded,
  BuildCircleRounded,
  CommuteRounded,
  ContactSupportRounded,
  DomainRounded,
  EmailRounded,
  EnergySavingsLeafRounded,
  GradientOutlined,
  GroupWork,
  GroupWorkRounded,
  Home,
  HomeRounded,
  HowToVoteRounded,
  InboxRounded,
  InputRounded,
  LibraryBooks,
  ListAltRounded,
  LocalShippingRounded,
  LocationCityRounded,
  MapRounded,
  MenuBookRounded,
  MoneyRounded,
  MovieFilterRounded,
  NaturePeopleRounded,
  PhotoSizeSelectLargeRounded,
  SecurityRounded,
  SendRounded,
  SettingsRounded,
  ShoppingCartRounded,
  SupervisedUserCircleRounded,
  TimelapseRounded,
  TimelineTwoTone,
  TuneRounded,
  VerifiedUserRounded,
} from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Box,
  Divider,
  Drawer,
  List,
  useMediaQuery,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { matchPath, useLocation, useParams } from "react-router-dom";
import { media_url } from "src/coreConfig";
import { setCurrentApp } from "src/slices/customers";
import { checkPermission } from "src/slices/users";
import { useDispatch, useSelector } from "src/store";
import NavItem from "./NavItem";

const drawerWidth = 256;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const NAVICONS = {
  home: HomeRounded,
  layout: LocationCityRounded,
  clipboard: AssignmentRounded,
  briefcaseIcon: AccountBalanceRounded,
  shoppingCartIcon: AccountBalanceWalletRounded,
  support: ContactSupportRounded,
  civil: AssignmentIndRounded,
  map: MapRounded,
  cash: MoneyRounded,
  cartIcon: CommuteRounded,
  urban: DomainRounded,
  urbin: DomainRounded,
  adsIcon: MovieFilterRounded,
  eco: EnergySavingsLeafRounded,
  email: AlternateEmailRounded,
  bank: AtmRounded,
  localShipping: LocalShippingRounded,
  tuneIcon: TuneRounded,
  erp: GroupWorkRounded,
  archive: ArchiveRounded,
  menuBook: MenuBookRounded,
  groupWork: GroupWork,
  pollIcon: HowToVoteRounded,
  timelineTwoTone: TimelineTwoTone,
  input: InputRounded,
  timelapse: TimelapseRounded,
  security: SecurityRounded,
  barChart: BarChartRounded,
  libraryBooks: LibraryBooks,
  ads: GradientOutlined,
  photoSize: PhotoSizeSelectLargeRounded,
  shoppingCart: ShoppingCartRounded,
  developerBoard: BuildCircleRounded,
  socialResp: NaturePeopleRounded,
  audit: VerifiedUserRounded,
  emailRounded: EmailRounded,
  emailInbox: InboxRounded,
  emailSend: SendRounded,
};
const useStyles = makeStyles((theme) => ({
  item: {
    display: "block",
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.primary.main,
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
  },
  buttonLeaf: {
    color: theme.palette.primary.main,
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightRegular,
    "&.depth-0": {
      "& $title": {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  },
  icon: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
    color: "white",
  },
  title: {
    marginRight: "auto",
    color: "rgba(255, 255, 255, 0.75) !important",
  },
  active: {
    color: theme.palette.primary.main,
    "& $title": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    "& $icon": {
      color: theme.palette.secondary.dark,
    },
  },
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)",
    zIndex: 180,
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  capitalize: {
    textTransform: "capitalize",
  },
  adminMode: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    "& .MuiDrawer-paper": {
      backgroundColor: `${theme.palette.secondary.main} !important`,
    },
    color: `${theme.palette.secondary.contrastText} !important`,
    "& > .MuiButton > a": {
      root: {
        color: "white !important",
      },
    },
  },
}));

function renderNavItems({
  items,
  pathname,
  currentCustomer,
  admin,
  depth = 0,
}) {
  return (
    <List disablePadding>
      {items && items.length > 0
        ? items.reduce(
            (acc, item) =>
              reduceChildRoutes({
                acc,
                item,
                pathname,
                currentCustomer,
                depth,
                admin,
              }),
            []
          )
        : null}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  item,
  pathname,
  depth,
  currentCustomer,
  admin,
}) {
  if (!item.public && !admin) return acc;

  const key = item.title + depth;
  const base = currentCustomer ? `/${currentCustomer.shortname}` : "";
  const href = base ? `${base}${item.href}` : item.href;

  if (item.items) {
    const open = matchPath({ path: href }, pathname);

    acc.push(
      <NavItem
        depth={depth}
        icon={NAVICONS[item.icon]}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
          currentCustomer,
          admin,
        })}
      </NavItem>
    );
  } else {
    if (item.title) {
      acc.push(
        <NavItem
          depth={depth}
          href={href}
          icon={NAVICONS[item.icon]}
          info={item.info}
          key={key}
          title={item.title}
        />
      );
    }
  }

  return acc;
}

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { customerName } = useParams();
  const settings = useSelector((state) => state.settings);
  const [adminSections, setAdminSections] = useState([]);
  const { customers, currentCustomer, customerSections, currentApp } =
    useSelector((state) => state.customers);
  const { sections, adminMode, hideNavbar } = settings;
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));

  // useCallback(() => {}, [sections]);
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }

    // eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    if (currentCustomer && customerSections) {
      if (
        !currentApp ||
        !location.pathname.includes(
          `/${currentCustomer.shortname}/${currentApp.href}/`
        )
      ) {
        var cA = null;
        customerSections.forEach((_cS) => {
          if (
            location.pathname.includes(
              `/${currentCustomer.shortname}${_cS.href}`
            )
          ) {
            cA = _cS;
          }
        });
        dispatch(setCurrentApp(cA));
      }
    }
  }, [currentCustomer, customerSections, location.pathname, currentApp]);

  useEffect(() => {}, [sections, currentCustomer]);

  const UserSections = [];
  // const UserSections = [
  //   {
  //     subheader: "Perfil",
  //     items: [
  //       {
  //         title: "Mi Portafolio",
  //         icon: Folder,
  //         color: "secondary",
  //         href: "/app/reports/dashboard",
  //         items: [
  //           {
  //             title: "Basic Data",
  //             href: "/app/account/general",
  //             icon: <VerifiedUserRounded />,
  //           },
  //           {
  //             title: "Identities",
  //             href: "/app/account/identities",
  //             icon: PortraitRounded,
  //           },
  //           {
  //             title: "Address",
  //             href: "/app/account/addresses",
  //             icon: MapRounded,
  //           },
  //           {
  //             title: "Companies",
  //             href: "/app/account/companies",
  //             icon: BuildRounded,
  //           },
  //           {
  //             title: "Vehicles",
  //             href: "/app/account/vehicles",
  //             icon: EmojiTransportationRounded,
  //           },
  //           // { title: 'RealEstates', href: '/app/account/vehicles', icon: Home },
  //         ],
  //       },
  //     ],
  //   },
  // ];

  const loadAdminSections = () => {
    var _admSections = [];
    // if (
    //   checkPermission({
    //     action: "can_view_stats",
    //     module: "nets_customers",
    //     user: user,
    //     customerId: currentCustomer.id,
    //   })
    // ) {
    //   _admSections.push({
    //     title: t("Reportes"),
    //     icon: "barChart",
    //     href: `/AdminDashboard/`,
    //     target: "_bank",
    //   });
    // }
    _admSections.push({
      title: t("Configuración"),
      color: "secondary",
      icon: SettingsRounded,
      href: `/${currentCustomer.shortname}/corporateAdmin/`,
      items: [
        {
          title: t("Corporativo"),
          icon: SupervisedUserCircleRounded,
          color: "secondary",
          href: `/corporateAdmin/`,
        },
      ],
    });
    setAdminSections(_admSections);
  };

  useEffect(() => {
    if (
      user &&
      currentCustomer &&
      settings.adminMode &&
      checkPermission({
        action: "can_view_user",
        module: "nets_user",
        user: user,
        customerId: currentCustomer.id,
      })
    ) {
      loadAdminSections();
    }
  }, [currentCustomer, user, settings]);

  if (!user) {
    return null;
  }

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box p={2}>
        {/* <Box p={2}>
            <List>
              <NavItem
                depth={0}
                icon={HomeRounded}
                title={t("Home")}
                href="/app/"
              />
            </List>
          </Box> 
          <Divider />*/}
        <Box mt={2} textAlign="center">
          <NavItem
            depth={0}
            href={`/app/account`}
            // link
            icon={() => (
              <Avatar
                alt={user.fullname}
                src={media_url(user.avatar) || ""}
                style={{ marginRight: "8px" }}
              />
            )}
            key={"u-pro"}
            title={`${user.fullname}`}
            style={{ color: adminMode ? "white" : "green" }}
          />

          <NavItem
            depth={0}
            href={`/app/notify/`}
            icon={() => <EmailRounded style={{ marginRight: "5px" }} />}
            title={t("Buzón de entrada")}
            info={
              user.notifications_count
                ? () => (
                    <Badge
                      badgeContent={user.notifications_count}
                      color="secondary"
                    />
                  )
                : null
            }
          />

          <NavItem
            depth={0}
            href={`/app/organizations/`}
            icon={() => <ListAltRounded style={{ marginRight: "5px" }} />}
            title={t("Mis organizaciones")}
            key="u-org"
          />

          {UserSections.map((section) => {
            return (
              <List key={section.subheader}>
                {renderNavItems({
                  items: section.items,
                  pathname: location.pathname,
                })}
              </List>
            );
          })}
        </Box>
      </Box>
      <Divider />

      <Box p={2}>
        {currentCustomer && customerSections.length > 0 && (
          <List disablePadding>
            <List disablePadding>
              {/* <ListItem>
                  <Button href={`/${currentCustomer.shortname}`}>
                    {currentCustomer.shortname.toLocaleUpperCase()}
                  </Button>
                </ListItem> */}
              <NavItem
                depth={0}
                icon={Home}
                // icon={() => (
                //   <Avatar
                //     alt={currentCustomer.name}
                //     src={media_url(currentCustomer.icon)}
                //     style={{ paddingLeft: "-5px", marginRight: "6px" }}
                //   />
                // )}
                href={`/${currentCustomer.shortname}`}
                title={t("Inicio")}
              />
            </List>
            <List disablePadding>
              {adminSections.length > 0 &&
                renderNavItems({
                  items: adminSections,
                  currentCustomer: currentCustomer,
                  admin: adminMode,
                  pathname: location.pathname,
                })}
              {renderNavItems({
                items: customerSections,
                pathname: location.pathname,
                currentCustomer: currentCustomer,
                admin: adminMode,
              })}
            </List>
          </List>
        )}
      </Box>

      {/* <Box p={2}>
          <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
            <Typography
              variant="h6"
              color="textPrimary"
              className={classes.capitalize}
            >
              {t('NeedHelp?')}
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              component={RouterLink}
              to="#"
              className={classes.capitalize}
            >
              {t('user support')}
            </Link>
          </Box>
        </Box> */}
    </Box>
  );

  return (
    <>
      <Drawer
        anchor="left"
        classes={{ paper: classes.mobileDrawer }}
        className={adminMode ? classes.adminMode : null}
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
        sx={{
          display: {
            xs: "block",
            lg: "none",
          },
          zIndex: 1200,
        }}
      >
        {content}
      </Drawer>

      <Drawer
        anchor="left"
        classes={{ paper: classes.desktopDrawer }}
        className={adminMode ? classes.adminMode : null}
        open
        variant="persistent"
        sx={{
          display: {
            xs: "none",
            lg: "block",
          },
          zIndex: 1200,
        }}
      >
        {content}
      </Drawer>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};
export { NAVICONS };
export default NavBar;
