import {
  Button,
  Grid2 as Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

// Custom component that receives a list of items, columns to display, onComplete, and a callback function to handle the selection of an item.
// while selecting internal state is used to keep track of the selected item.
// onComplete receives the selected items as an argument.
// the list should allow to filter by searching, select all or unselect all, and select one or more items.
// onCancel is a incoming prop that is used to close the modal.
// the list should be able to display a message when there are no items to display.
// the list should be able to display a message when there are no items to display after filtering.

const MultiSelectList = ({
  items = [],
  columns = [],
  onComplete = () => {},
  onCancel = () => {},
  onSelect = () => {},
  selectedItems = [],
  title = "",
  message = "",
  conditionalInOrder = false,
  messageEmpty = "",
  submitLabel = "",
}) => {
  const { t } = useTranslation();
  const [listState, setListState] = React.useState({
    open: false,
    items: items,
    selectedItems: [],
    selectedIndexes: [],
    columns: [],
    columnsToDisplay: [],
    search: "",
    selectAll: false,
    message: "",
    messageEmpty: "",
    title: "",
    loading: false,
    error: false,
    errorMessage: "",
  });

  React.useEffect(() => {
    setListState({
      ...listState,
      items: items,
      selectedItems: selectedItems,
      columns: columns,
      columnsToDisplay: columns,
      message: message,
      messageEmpty: messageEmpty,
      title: title,
    });
  }, [items, selectedItems, columns, message, messageEmpty, title]);

  const handleSelect = (item) => {
    if (onSelect) {
      onSelect(item);
    }
    var index = listState.items.findIndex((i) => i.id === item.id);
    // append the index to the selectedIndexes and make sure it is unique
    var _selectedIndexes = [...listState.selectedIndexes, index];
    _selectedIndexes = _.uniq(_selectedIndexes);

    if (conditionalInOrder) {
      // select all previous items
      var _selectedItems = listState.items.filter((i, k) => k <= index);
      _selectedIndexes = [];
      for (var i = 0; i <= index; i++) {
        _selectedIndexes.push(i);
      }
      setListState({
        ...listState,
        selectedItems: _selectedItems,
        selectedIndexes: _selectedIndexes,
      });
      return;
    }

    setListState({
      ...listState,
      selectedItems: [...listState.selectedItems, item],
      selectedIndexes: _selectedIndexes,
    });
  };

  const handleUnselect = (item) => {
    if (onSelect) {
      onSelect(item);
    }
    var index = listState.items.findIndex((i) => i.id === item.id);
    // remove the index from the selectedIndexes
    var _selectedIndexes = listState.selectedIndexes.filter((i) => i !== index);

    if (conditionalInOrder) {
      // remove all items that are after the current item
      _selectedIndexes = _selectedIndexes.filter((i) => i <= index);

      var _selectedItems = [];
      for (var i = 0; i < _selectedIndexes.length; i++) {
        _selectedItems.push(listState.items[_selectedIndexes[i]]);
      }
      setListState({
        ...listState,
        selectedItems: _selectedItems,
        selectedIndexes: _selectedIndexes,
      });
      return;
    }
    setListState({
      ...listState,
      selectedItems: listState.selectedItems.filter((i) => i.id !== item.id),
    });
  };

  const handleSelectAll = () => {
    setListState({
      ...listState,
      selectedItems: listState.items,
      selectedIndexes: listState.items.map((i, k) => k),
    });
  };

  const handleUnselectAll = () => {
    setListState({
      ...listState,
      selectedIndexes: [],
      selectedItems: [],
    });
  };

  const handleSearch = (e) => {
    setListState({
      ...listState,
      search: e.target.value,
      columnsToDisplay: listState.columns.filter((c) =>
        c.label.toLowerCase().includes(e.target.value.toLowerCase())
      ),
    });
  };

  const handleComplete = () => {
    if (onComplete) {
      onComplete(listState.selectedItems);
    }
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  useEffect(() => {
    setListState({
      ...listState,
      items: items,
    });
  }, [items]);

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <input
                type="checkbox"
                checked={
                  listState.selectedItems.length === listState.items.length
                }
                onChange={(e) =>
                  e.target.checked ? handleSelectAll() : handleUnselectAll()
                }
              />
            </TableCell>
            <TableCell>
              <>
                {listState.selectedItems.length > 0 && (
                  <Typography variant="caption">
                    {listState.selectedItems.length} {t("Seleccionados")}
                  </Typography>
                )}
                {listState.selectedItems.length > 0 && (
                  <Button
                    variant="contained"
                    color="success"
                    onClick={handleComplete}
                  >
                    {t("Listo")}
                  </Button>
                )}
              </>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listState.items.length > 0 ? (
            listState.items.map((item, k) => (
              <TableRow key={item.id}>
                <TableCell>
                  <input
                    type="checkbox"
                    checked={listState.selectedIndexes.includes(k)}
                    onChange={(e) =>
                      e.target.checked
                        ? handleSelect(item)
                        : handleUnselect(item)
                    }
                  />
                </TableCell>
                <TableCell>{item.label}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={2}>
                {listState.search.length > 0
                  ? listState.messageEmpty
                  : listState.message}
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell colSpan={2}>
              <Grid
                container
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleCancel}
                >
                  {t("Cancelar")}
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  onClick={
                    listState.selectedItems.length === 0 ? null : handleComplete
                  }
                >
                  {submitLabel ? submitLabel : t("Listo")}
                </Button>
              </Grid>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

const itemsProps = PropTypes.shape({
  id: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
});

MultiSelectList.propTypes = {
  items: PropTypes.arrayOf(itemsProps),
  columns: PropTypes.array.isRequired,
  onComplete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedItems: PropTypes.array,
  title: PropTypes.string,
  message: PropTypes.string,
  messageEmpty: PropTypes.string,
  conditionalInOrder: PropTypes.bool,
  submitLabel: PropTypes.string,
};

export default MultiSelectList;
