import { lazy } from "react";

const StampLayout = lazy(() => import("src/views/arkadu/stamp/StampLayout"));
const StampIndex = lazy(() => import("src/views/arkadu/stamp/StampIndex"));
const StampOrderDetail = lazy(
  () => import("src/views/arkadu/stamp/StampOrderDetail")
);
const StampDetailAdmin = lazy(
  () => import("src/views/arkadu/stamp/StampDetailAdmin")
);
const StampDetail = lazy(() => import("src/views/arkadu/stamp/StampDetail"));

export default [
  {
    element: <StampLayout />,
    children: [
      {
        index: true,
        element: <StampIndex />,
      },
      {
        path: "order/:id/",
        element: <StampOrderDetail />,
      },
      {
        path: "edit/:id/",
        element: <StampDetailAdmin />,
      },
      {
        path: "detail/:id/",
        element: <StampDetail />,
      },
      {
        path: ":tabName",
        element: <StampIndex />,
      },
    ],
  },
];
