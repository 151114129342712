import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
  accounts: [],
  currentAccount: null,
  shopWS: null,
  shopOnline: false,
  shopMessages: [],
  currentCashFund: null,
  currentTeller: null,
  currentRegister: null,
  currentFund: null,
  sectionTitle: "",
  reloadRequest: false,
  taxesFiscalYears: [],
};

const slice = createSlice({
  name: "shop",
  initialState,
  reducers: {
    setOnLine: (state, action) => {
      state.shopOnline = action.payload;
    },
    newMessage: (state, action) => {
      state.shopMessages = _.concat(state.shopMessages, action.payload);
    },
    setShopWs: (state, action) => {
      state.shopWS = action.payload;
    },
    setCurrenCashFund: (state, action) => {
      state.currentCashFund = action.payload;
    },
    setShopState: (state, action) => {
      Object.keys(action.payload).map((k) => {
        state[k] = action.payload[k];
      });
    },
  },
});

export const reducer = slice.reducer;

export const connectToShopWS = (data) => async (dispatch) => {};
export const setCurrentCashFund = (data) => async (dispatch) => {
  dispatch(slice.actions.setCurrenCashFund(data));
};
export const setShopState = (data) => async (dispatch) => {
  dispatch(slice.actions.setShopState(data));
};
export default slice;
