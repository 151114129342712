import { lazy } from "react";

const NotifyCustomEmailForm = lazy(
  () => import("src/views/arkadu/notify/admin/NotifyCustomEmailForm")
);
const NotifyAdminIndex = lazy(
  () => import("src/views/arkadu/notify/admin/NotifyAdminIndex")
);

export default [
  {
    index: true,
    element: <NotifyAdminIndex />,
  },
  {
    path: "email/new/",
    element: <NotifyCustomEmailForm />,
  },
];
