import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import Page from "src/components/Page";
import { useSelector } from "src/store";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(3),
    paddingTop: 80,
    paddingBottom: 80,
  },
  image: {
    maxWidth: "100%",
    width: 560,
    maxHeight: 300,
    height: "auto",
  },
}));

const ErrorView = ({ message, ...rest }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { currentCustomer } = useSelector((state) => state.customers);
  const { title } = rest;
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("xl"));

  return (
    <Page className={classes.root} title="404: Not found">
      <Container maxWidth="lg">
        <Typography
          align="center"
          variant={mobileDevice ? "h4" : "h1"}
          color="textPrimary"
        >
          {title || t("Error")}
        </Typography>
        <Typography align="center" variant="subtitle2" color="textSecondary">
          {message ? message : t("oops, Something was wrong!.")}
        </Typography>
        <Box mt={6} display="flex" justifyContent="center">
          <img
            alt="Forbidden"
            className={classes.image}
            src="/static/images/undraw_server_down_s4lk.svg"
          />
        </Box>
        <Box mt={6} display="flex" justifyContent="center">
          <Button
            color="secondary"
            component={RouterLink}
            to={
              window["APP_HOME"] || currentCustomer
                ? `/${currentCustomer?.shortname}/`
                : "/"
            }
            variant="outlined"
          >
            {t("back to home")}
          </Button>
        </Box>
      </Container>
    </Page>
  );
};

export default ErrorView;
