import { lazy } from "react";
const SimpleRegistrationForm = lazy(
  () => import("src/views/arkadu/user/SimpleRegistrationForm")
);
const UserLayout = lazy(() => import("src/views/arkadu/user/UserLayout"));
const UserAdmin = lazy(() => import("src/views/arkadu/user/admin/UserAdmin"));
export default [
  {
    element: <UserLayout />,
    children: [
      {
        path: "admin/registration/",
        element: <SimpleRegistrationForm />,
      },
      {
        path: "admin/:userId",
        element: <UserAdmin />,
      },
      {
        path: "admin/:userId/:tabName",
        element: <UserAdmin />,
      },
    ],
  },
];
