import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

export const initialStateUC = {
  currentService: null,
  currentClient: null,
  urbanCleaningPermissions: {
    dashboard: false,
    addService: false,
    addServiceValue: false,
    addCustomer: false,
  },
  subscriptions: null,
  serviceTypes: null,
  servicePeriods: null,
  currentServiceType: null,
  serviceCommunities: null,
  serviceCustomers: null,
  filterAddressBy: null,
};

const slice = createSlice({
  name: "urban_cleaning",
  initialState: initialStateUC,
  reducers: {
    setUrbanCleaningPermissions: (state, action) => {
      state.urbanCleaningPermissions = action.payload;
    },
    setCoverageAddresses: (state, action) => {
      state.filterAddressBy = action.payload;
      console.log(action.payload);
    },
    setServiceTypes: (state, action) => {
      state.serviceTypes = action.payload;
      if (state.currentServiceType) {
        state.currentServiceType = _.find(action.payload, {
          id: state.currentServiceType.id,
        });
      }
    },
    setCurrentServiceType: (state, action) => {
      state.currentServiceType = action.payload;
    },
    setUrbanCleaningItem: (state, action) => {
      const { name, data } = action.payload;
      state[name] = data;
    },
    setSubscriptions: (state, action) => {
      state.subscriptions = action.payload;
    },
    setUrbanCleaningState: (state, action) => {
      Object.keys(action.payload).forEach((k) => {
        state[k] = action.payload[k];
      });
    },
  },
});

export const reducer = slice.reducer;

export const setUrbanCleaningPermissions = (data) => async (dispatch) => {
  dispatch(slice.actions.setUrbanCleaningPermissions(data));
};
export const setServiceTypes = (data) => async (dispatch) => {
  dispatch(slice.actions.setServiceTypes(data));
};
export const setSubscriptions = (data) => async (dispatch) => {
  dispatch(slice.actions.setSubscriptions(data));
};
export const setUrbanCleaningItem = (data) => async (dispatch) => {
  dispatch(slice.actions.setUrbanCleaningItem(data));
};

export const setCurrentServiceType = (data) => async (dispatch) => {
  dispatch(slice.actions.setCurrentServiceType(data));
};
export const setUrbanCleaningState = (data) => async (dispatch) => {
  dispatch(slice.actions.setUrbanCleaningState(data));
};
export const setCoverageAddresses =
  ({ customer }) =>
  async (dispatch) => {
    if (customer.geolocalization) {
      var cA = [];
      var geolocalization = customer.geolocalization.split(":");

      switch (geolocalization[0]) {
        case "nets_geo.Municipality":
          dispatch(
            slice.actions.setCoverageAddresses({
              municipality_id: parseInt(geolocalization[1]),
            })
          );
      }
    }
  };
