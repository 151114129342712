import { lazy } from "react";
const PollDetail = lazy(() => import("src/views/arkadu/poll/PollDetail"));
const PollAnswer = lazy(() => import("src/views/arkadu/poll/PollAnswer"));
const PollIndex = lazy(() => import("src/views/arkadu/poll/PollIndex"));
const BaseLayoutId = lazy(() => import("src/views/arkadu/BaseLayoutId"));
const PollLayout = lazy(() => import("src/views/arkadu/poll/PollLayout"));
export default [
  {
    element: <PollLayout />,
    children: [
      {
        index: true,
        element: <PollIndex />,
      },
      {
        path: "detail/:id",
        element: (
          <BaseLayoutId>
            <PollDetail />
          </BaseLayoutId>
        ),
      },
      {
        path: "answer/:id",
        element: (
          <BaseLayoutId>
            <PollAnswer />
          </BaseLayoutId>
        ),
      },
      {
        path: "poll",
        element: <PollIndex />,
      },
    ],
  },
];
