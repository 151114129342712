import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentSocialResponsibility: null,
  socialRespList: [],
  socialRespCategories: [],
  socialRespFilters: {
    order_by: "-created",
  },
};

const slice = createSlice({
  name: "socialResponsibility",
  initialState,
  reducers: {
    setSocialRespState: (state, action) => {
      Object.keys(action.payload).map((k) => {
        state[k] = action.payload[k];
      });
    },
  },
});

export const reducer = slice.reducer;

export const setSocialResponsibilityState = (data) => async (dispatch) => {
  dispatch(slice.actions.setSocialRespState(data));
};
export default slice;
