import { createSlice } from "@reduxjs/toolkit";
import { THEMES } from "src/constants";

var bg = window["configSite"]?.siteBg || "/static/images/bg.jpg";

const initialState = {
  direction: "ltr",
  responsiveFontSizes: true,
  settingsLoaded: false,
  theme: THEMES.LIGHT,
  adminMode: false,
  adminModeFor: null,
  siteConfigLoaded: false,
  siteConfig: null,
  isLoading: false,
  loadingMessage: "Cargando...",
  hideNavbar: false,
  groupStatColors: {},
  statsDefaults: {
    dateRange: "current_year",
  },
  csrf: null,
  open: false,
  config: {
    updated: null,
    modules: null,
    maintenance: false,
    login_bg: bg,
  },
  sections: [
    {
      subheader: "Reports",
      items: [
        {
          title: "Inicio",
          icon: "home",
          //   icon: NAVICONS['home'],
          href: "/app/",
        },
      ],
    },
  ],
};
const slice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setSettings: (state, action) => {
      const { settings } = action.payload;
      Object.keys(settings).map((k) => {
        state[k] = settings[k];
      });
      state.settingsLoaded = true;
      localStorage.setItem("settings", JSON.stringify(state));
    },
    setOpen: (state, action) => {
      const { open } = action.payload;
      state.open = open;
    },
    setLoaded: (state, action) => {
      const { loaded } = action.payload;

      state.settingsLoaded = loaded;
    },
    setCsrf: (state, action) => {
      const { csrf } = action.payload;
      state.csrf = csrf;
    },
    setAdminModeFor: (state, action) => {
      state.adminModeFor = action.payload;
    },
    setAdminMode: (state, action) => {
      state.adminMode = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setLoadingMessage: (state, action) => {
      state.loadingMessage = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export const setIsLoading = (data) => async (dispatch) => {
  dispatch(slice.actions.setIsLoading(data));
};
export const setLoadingMessage = (data) => async (dispatch) => {
  dispatch(slice.actions.setLoadingMessage(data));
};
export const loadSiteConfig = () => async (dispatch) => {
  var _localConfig = localStorage.getItem("config");
  var defaultConfig = {
    version: 0,
  };
  if (_localConfig) {
    try {
      defaultConfig = JSON.parse(_localConfig);
      console.log("loaded local config", defaultConfig);
      if (
        defaultConfig.version <= 2123 &&
        defaultConfig.domain === "aseo.alcaldialosguayos.org"
      ) {
        console.log("clearing local storage");
        // delete all local storage and cookies
        localStorage.clear();
      }
    } catch (e) {
      console.log("error parsing local config", e);
    }
  }
  dispatch(
    slice.actions.setSettings({
      settings: {
        siteConfig: defaultConfig,
        siteConfigLoaded: true,
      },
    })
  );
};
export const loadSettings = () => async (dispatch) => {
  const settings = localStorage.getItem("settings");
  if (settings) {
    dispatch(slice.actions.setSettings({ settings: JSON.parse(settings) }));
    dispatch(slice.actions.setLoaded({ loaded: true }));
  } else {
    localStorage.setItem("settings", JSON.stringify(initialState));
    dispatch(slice.actions.setLoaded({ loaded: true }));
  }
};

export const saveSettings = (data) => async (dispatch) => {
  dispatch(slice.actions.setSettings({ settings: data }));
};

export const setOpen = (data) => async (dispatch) => {
  dispatch(slice.actions.setOpen({ open: data }));
};

export const setAdminModeFor = (data) => async (dispatch) => {
  const settings = localStorage.getItem("settings");
  var s = {};
  if (settings) {
    s = JSON.parse(settings);
    s.adminModeFor = data;
    dispatch(slice.actions.setAdminModeFor(data));
    localStorage.setItem("settings", JSON.stringify(s));
  } else {
    s = { adminModeFor: data };
    localStorage.setItem("settings", JSON.stringify(s));
  }
};
