import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  iaOpen: false,
  loadingIA: false,
  iaData: [],
  messages: [],
};

const slice = createSlice({
  name: "ia",
  initialState,

  reducers: {
    setAIState: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      });
    },
  },
});

export const reducer = slice.reducer;

export const setAIState = (data) => async (dispatch) => {
  dispatch(slice.actions.setAIState(data));
};
