import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { setIsLoading, setLoadingMessage } from "src/slices/settings";
import { useDispatch } from "src/store";
import axiosInstance from "src/utils/axios";

const useAsyncRequest = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isAsyncLoading, setAsyncIsLoading] = useState(false);
  const [isDataError, setIsDataError] = useState(false);
  const [res, setRes] = useState(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleLoadingState = (isLoading, label) => {
    setAsyncIsLoading(isLoading);
    dispatch(
      setLoadingMessage(isLoading ? label || t("Obteniendo datos") + "..." : "")
    );
    dispatch(setIsLoading(isLoading));
  };

  const handleSnackbar = (message, variant = "info", sb) => {
    if (sb) closeSnackbar(sb);
    if (message) enqueueSnackbar(message, { variant });
  };

  const handleError = async (error, label, onBackground, sb) => {
    setIsDataError(true);
    console.error(error);
    if (!onBackground) handleLoadingState(false);
    if (sb) closeSnackbar(sb);

    if (error.response && error.response.data instanceof Blob) {
      const reader = new FileReader();
      reader.onload = () => {
        try {
          const json = JSON.parse(reader.result);
          handleSnackbar(json.message || `${label} : error`, "error");
        } catch (e) {
          handleSnackbar(`${label} : error`, "error");
        }
      };
      reader.readAsText(error.response.data);
    } else {
      handleSnackbar(
        error.response?.data?.message || error.message || `${label} : error`,
        "error"
      );
    }
    return error;
  };

  const loadData = async (
    url,
    data,
    options,
    label,
    customMessage,
    onBackground
  ) => {
    if (!onBackground && isAsyncLoading) return;

    if (!onBackground) handleLoadingState(true, label);
    const sb =
      label && !onBackground
        ? enqueueSnackbar(customMessage || `${t("Obteniendo")} ${label}`, {
            variant: "info",
          })
        : null;

    try {
      const response = await axiosInstance.post(url, data, options);
      if (!onBackground) handleLoadingState(false);
      handleSnackbar(
        label ? `${label} ${t("cargado exitosamente.")}` : null,
        "success",
        sb
      );
      return response;
    } catch (error) {
      return handleError(error, label, onBackground, sb);
    }
  };

  const requestData = async (
    url,
    data,
    options,
    label,
    customMessage,
    onBackground
  ) => {
    const response = await loadData(
      url,
      data,
      options,
      label,
      customMessage,
      onBackground
    );
    setRes(
      response.data?.res === 1
        ? { ...response.data, status: response.status }
        : response
    );
  };

  const api = async (url, data, options, label, onBackground) => {
    return loadData(url, data, options, label, null, onBackground);
  };

  return {
    res,
    requestData,
    isLoading: isAsyncLoading,
    api,
  };
};

export default useAsyncRequest;
