import { createSlice } from "@reduxjs/toolkit";
import { env } from "process";

export const initialState = {
  urbanProducts: null,
  currentUrbanRequest: null,
  environmentRequests: null,
  currentEnvironmentPenalty: null,
  environmentPenaltiesTypes: [],
  environmentPenalties: [],
  environmentPenaltiesCount: 0,
  environmentRequestsCount: 0,
  searchResults: null,
  isSearching: false,
  isLoading: false,
};

const slice = createSlice({
  name: "environment",
  initialState: initialState,
  reducers: {
    setEnvironmentState: (state, action) => {
      Object.keys(action.payload).forEach((k) => {
        state[k] = action.payload[k];
      });
    },
  },
});

export const reducer = slice.reducer;

export const setEnvironmentState = (data) => async (dispatch) => {
  dispatch(slice.actions.setEnvironmentState(data));
};
