import { lazy } from "react";

const DevLayout = lazy(() => import("src/views/arkadu/dev/DevLayout"));
const DevIndex = lazy(() => import("src/views/arkadu/dev/DevIndex"));
const DevRequestDetail = lazy(
  () => import("src/views/arkadu/dev/parts/DevRequestDetail")
);

export default [
  {
    element: <DevLayout />,
    children: [
      {
        index: true,
        element: <DevIndex />,
      },
      {
        path: "request/:requestId",
        element: <DevRequestDetail />,
      },
      {
        path: ":tabName",
        element: <DevIndex />,
      },
      {
        path: "request/",
        element: <DevRequestDetail />,
      },
    ],
  },
];
