import { lazy } from "react";

const AuditDetail = lazy(() => import("src/views/arkadu/audit/AuditDetail"));
const AuditSummonDetail = lazy(
  () => import("src/views/arkadu/audit/AuditSummonDetail")
);
const AuditIndex = lazy(() => import("src/views/arkadu/audit/AuditIndex"));

const audit_routes_v6 = [
  {
    index: true,
    element: <AuditIndex />,
  },
  {
    path: "detail/:id",
    element: <AuditDetail />,
  },
  {
    path: "summon/:id",
    element: <AuditSummonDetail />,
  },
  {
    path: ":tabName",
    element: <AuditIndex />,
  },
];

export default audit_routes_v6;
