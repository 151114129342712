import { Outlet, useLocation, useNavigate } from "react-router-dom";

import {
  AddBoxRounded,
  AddRounded,
  AdminPanelSettingsRounded,
  SendTwoTone,
  SettingsApplicationsRounded,
  TimelapseRounded,
} from "@mui/icons-material";
import { Grid2 as Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ActionIcons from "src/components/ActionIcons";
import Dialogs from "src/components/Dialogs";
import FormBuilder from "src/components/formBuilder/FormBuilder";
import LottiePlayer from "src/components/LottieAnimation";
import PinAuthorizedView from "src/components/PinAuthorizedView";
import arkadu from "src/coreConfig";
import useAsyncRequest from "src/hooks/useAsyncRequest";
import { useCanDo } from "src/hooks/useCando";
import { useUserAccount } from "src/hooks/useUserAccount";
import { setShopState } from "src/slices/shop";
import { useDispatch, useSelector } from "src/store";
import BasePage from "src/views/BasePage";
import AccountCard from "./AccountCard";
import DialogAddUserProduct from "./dialogs/user_account/DialogAddUserProduct";

const AccountContext = React.createContext();

const viewStates = {
  addProduct: "addProduct",
  transferFunds: "transferFunds",
  showPendingDebits: "showPendingDebits",
  showAccountHistory: "showAccountHistory",
  adjustment: "adjustment",
};
const AccountLayout = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const { currentCustomer, onlineBankServices } = useSelector(
    (state) => state.customers
  );
  const { currentAccount, sectionTitle, reloadRequest } = useSelector(
    (state) => state.shop
  );
  const { userAccount, accountError, isError, isLoading, reloadAccount } =
    useUserAccount(id, currentCustomer.id);
  const { api } = useAsyncRequest();
  const { canDo } = useCanDo("shopping_cart");
  const { adminMode } = useSelector((state) => state.settings);
  const { enqueueSnackbar } = useSnackbar();
  const [accountViewStates, setAccountViewStates] = React.useState({
    state: null,
    message: "",
    enabledServices: [],
  });
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const handleAddProduct = async (values) => {
    setAccountViewStates({ state: null, message: "" });
    var res = api(
      arkadu.urls.v2.account.handle,
      {
        customer_id: currentCustomer.id,
        mode: adminMode ? "admin" : "user",
        instance: "user_product",
        action: "add",
        user_account_id: currentAccount.id,
        product_id: values.product_id.value,
        quantity: values.quantity,
        comment: values.comment,
      },
      {},
      t("Agregar producto")
    );
    reloadAccount();
  };

  useEffect(() => {
    if (onlineBankServices && currentCustomer) {
      var _services = onlineBankServices.customers[currentCustomer.id];
      var _enabledServices = [];
      if (_services) {
        _services.forEach((service) => {
          if (service.enabled) {
            // search for the service in the list of services
            var _service = onlineBankServices.services.find(
              (s) => s.id === service.service_id
            );
            if (_service) {
              _enabledServices.push({
                ...service,
                service: _service,
              });
            }
          }
        });
        setAccountViewStates({
          ...accountViewStates,
          enabledServices: _enabledServices,
        });
      }
    }
  }, [onlineBankServices, currentCustomer]);

  useEffect(() => {
    if (reloadRequest) {
      reloadAccount();
    }
    dispatch(setShopState({ reloadRequest: false }));
  }, [reloadRequest]);

  if (!currentAccount) {
    return <LottiePlayer path="/static/lotties/arkadu_linear_loading.json" />;
  }
  console.log("enabledServices", accountViewStates.enabledServices);
  return (
    <AccountContext.Provider
      value={{ currentAccount, reloadAccount, isLoading }}
    >
      <BasePage
        title={sectionTitle || t("Detalle de cuenta")}
        actionsMenu={[
          {
            label: t("Agregar fondos"),
            onClick: () => {
              // navigate(
              //   `/${currentCustomer.shortname}/accounts/pay/${currentAccount.id}`
              // );
            },
            variant: "contained",
            color: "secondary",
            icon: <AddRounded />,
            keepOnMobile: true,
            items: [
              currentCustomer.accounts.filter(
                (a) => a.is_active && a.is_public && a.transfers_enabled
              ).length > 0 && {
                label: t("Transferencia bancaria"),
                onClick: () => {
                  navigate(
                    `/${currentCustomer.shortname}/accounts/addFundForm/${currentAccount.id}/`
                  );
                },
                icon: <ActionIcons name="transfer" />,
              },
              ...accountViewStates.enabledServices?.map((service) => {
                return {
                  label: (
                    <span>
                      {service.service.name}{" "}
                      <span
                        style={{
                          fontSize: "0.8em",
                          color: "gray",
                          fontStyle: "italic",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          display: "block",
                          whiteSpace: "nowrap",
                          maxWidth: "200px",
                        }}
                      >
                        {service.service.description}
                      </span>
                    </span>
                  ),
                  onClick: () => {
                    navigate(
                      `/${currentCustomer.shortname}/accounts/pay/${currentAccount.id}/${service.service.id}`
                    );
                  },
                  icon: (
                    <img
                      width="24"
                      loading="lazy"
                      src={`https://alcaldialosguayos.org/djmedia/${service.service.logo}`}
                    />
                  ),
                };
              }),
            ],
          },
          {
            label: t("Historial de pagos"),
            onClick: () => {
              navigate(
                `/${currentCustomer.shortname}/accounts/funds/${currentAccount.id}`
              );
            },
            icon: <TimelapseRounded />,
            keepOnMobile: true,
          },
          canDo("can_view_useraccount") && {
            label: t("Admin"),
            icon: <AdminPanelSettingsRounded />,
            color: "success",
            items: [
              canDo("can_add_userproduct") && {
                label: t("Agregar Producto"),
                onClick: () =>
                  setAccountViewStates({
                    ...accountViewStates,
                    state: viewStates.addProduct,
                  }),
                icon: <AddBoxRounded color="info" />,
                color: "info",
              },
              canDo("can_add_accounting_adjustment") && {
                label: t("Realizar Ajuste"),
                color: "info",
                onClick: () => {
                  setAccountViewStates({
                    ...accountViewStates,
                    state: viewStates.adjustment,
                  });
                },
                icon: <SettingsApplicationsRounded color="info" />,
              },
              canDo("can_transfer_addfund") && {
                label: t("Transferir Fondos"),
                color: "error",
                onClick: () => {
                  navigate(
                    `/${currentCustomer.shortname}/accounts/${currentAccount.id}/transfer`
                  );
                },
                icon: <SendTwoTone color="error" />,
              },
            ],
          },
        ]}
      >
        <Dialogs
          value={accountViewStates.state}
          onClose={() =>
            setAccountViewStates({ ...accountViewStates, state: null })
          }
          dialogs={[
            {
              value: viewStates.addProduct,
              title: t("Agregar Producto"),
              color: "secondary",
              body: (
                <DialogAddUserProduct
                  userAccount={currentAccount}
                  open={accountViewStates.state === viewStates.addProduct}
                  onClose={() =>
                    setAccountViewStates({ ...accountViewStates, state: null })
                  }
                />
              ),
            },
            {
              value: viewStates.adjustment,
              title: t("Ajuste contable"),
              body: (
                <PinAuthorizedView>
                  <FormBuilder
                    onSubmit={async (values) => {
                      var _res = await api(arkadu.urls.v2.account.handle, {
                        customer_id: currentCustomer.id,
                        mode: adminMode ? "admin" : "user",
                        instance: "account_adjustment",
                        action: "add",
                        account_id: currentAccount.id,
                        amount: values.amount,
                        justification: values.justification,
                        operation: values.operation.value,
                        bank_id: values.bank_id?.value,
                        not_fund: values.not_fund,
                        apply_at: values.apply_at,
                      });
                      if (_res.data.res === 1) {
                        enqueueSnackbar(t("Ajuste realizado"), {
                          variant: "success",
                        });
                        setAccountViewStates({
                          ...accountViewStates,
                          state: null,
                        });
                        reloadAccount();
                      }
                    }}
                    fieldSet={[
                      {
                        id: "amount",
                        label: t("Monto"),
                        type: "number",
                      },
                      {
                        id: "justification",
                        label: t("Justificaci�n"),
                        type: "textarea",
                      },
                      {
                        id: "operation",
                        label: t("Operaci�n"),
                        type: "select",
                        options: [
                          { value: "credit", label: t("Cr�dito") },
                          { value: "debit", label: t("D�bito") },
                        ],
                        initialValue: { value: "credit", label: t("Cr�dito") },
                      },
                      {
                        id: "not_fund",
                        label: t("Sin fondo en banco"),
                        type: "boolean",
                        conditionalBy: (values) => {
                          return values.operation.value === "credit";
                        },
                      },
                      {
                        id: "bank_id",
                        label: t("Banco"),
                        type: "select",
                        options: currentCustomer.accounts.map((i) => ({
                          value: i.id,
                          label: i.name,
                        })),
                        optional: true,
                        conditionalBy: (values) => {
                          return (
                            values.operation.value === "credit" &&
                            !values.not_fund
                          );
                        },
                      },
                      {
                        id: "apply_now",
                        label: t("Aplicar ahora"),
                        type: "boolean",
                        conditionalBy: (values) => {
                          return values.operation.value === "credit";
                        },
                      },
                      {
                        id: "apply_at",
                        label: t("Aplicar en"),
                        type: "date",
                        optional: true,
                        initialValue: new Date(),
                        conditionalBy: (values) => {
                          return (
                            values.operation.value === "credit" &&
                            !values.apply_now
                          );
                        },
                      },
                    ]}
                  />
                </PinAuthorizedView>
              ),
              actions: [],
            },
          ]}
        />
        {!location.pathname.includes("accounts/pay/") &&
          !location.pathname.includes("addFundForm") && (
            <Grid container sx={{ display: "flex", justifyContent: "center" }}>
              <AccountCard />
            </Grid>
          )}

        <Outlet />
      </BasePage>
    </AccountContext.Provider>
  );
};

export default AccountLayout;

export { AccountContext };
