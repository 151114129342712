import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  urbanProducts: null,
  currentUrbanRequest: null,
  urbanRequests: null,
  urbanRequestsCount: 0,
  searchResults: null,
  isSearching: false,
  isLoading: false,
};

const slice = createSlice({
  name: "urbin",
  initialState: initialState,
  reducers: {
    setUrbinState: (state, action) => {
      Object.keys(action.payload).forEach((k) => {
        state[k] = action.payload[k];
      });
    },
  },
});

export const reducer = slice.reducer;

export const setUrbinState = (data) => async (dispatch) => {
  dispatch(slice.actions.setUrbinState(data));
};
