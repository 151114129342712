import { keyframes } from "@emotion/react";
import { CircularProgress, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useParams } from "react-router";
import { setIsLoading } from "src/slices/settings";
import { useDispatch, useSelector } from "src/store";
import NotFoundView from "src/views/errors/NotFoundView";
import NavBar from "./NavBar";
import TopBar from "./TopBar";

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-50%);
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
      `;
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 64,
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 276,
    },
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto",
  },
  loadingOverlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 9999,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  // Referenced keyframes rule "fadein" is not defined.
  // we need to define it in the same file

  loadingMessage: {
    color: "white",
    position: "absolute",
    zIndex: 9999,
    top: "50%",
    transform: "translateY(-50%)",
    animation: `${fadeIn} 0.5s ease-in-out`,
  },
}));

const DashboardLayout = () => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const { t } = useTranslation();
  const { customerName } = useParams();
  const dispatch = useDispatch();
  const initialized = useRef(false);

  const { customers, currentCustomer, customerError } = useSelector(
    (state) => state.customers
  );
  const { adminMode, isLoading, loadingMessage } = useSelector(
    (state) => state.settings
  );

  useEffect(() => {
    if (initialized.current === false) {
      initialized.current = true;
      dispatch(setIsLoading(false));
      return;
    }
  }, []);

  if (customerName && customerError) {
    return <NotFoundView message={customerError} />;
  }

  return (
    <div className={classes.root}>
      {isLoading && (
        <div className={classes.loadingOverlay}>
          <CircularProgress
            sx={{
              color: "white",
              position: "absolute",
              zIndex: 9999,
              width: "100px",
              height: "100px",
            }}
          />
          <Typography variant="h4" className={classes.loadingMessage}>
            {loadingMessage}
          </Typography>
        </div>
      )}
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default DashboardLayout;
