import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import arkadu from "src/coreConfig";
import axios from "src/utils/axios";

const initialState = {
  bankServices: null,
  currentService: null,
  serviceProducts: null,
  loadingServices: false,
  accessExpired: false,
  loadingProducts: false,
  bankServiceUserAccounts: null,
  transferHistory: [],
  transferResult: null,
  transferInProgress: null,
  transferType: null,
  loginRequired: false,
  currentTransfer: null,
  bankOnline: false,
  bankErrors: [],
  environments: null,
};

const slice = createSlice({
  name: "bank",
  initialState,
  reducers: {
    clearBank: (state, action) => {
      state = initialState;
    },
    setBankServices: (state, action) => {
      const { services, transfers } = action.payload;
      state.bankServices = services;
      state.transferHistory = transfers;
      if (services && services.length === 1) {
        state.currentService = services[0];
      }
    },
    setCurrentService: (state, action) => {
      state.currentService = action.payload;
    },
    setServiceProducts: (state, action) => {
      const { client_products, useraccounts } = action.payload;
      state.serviceProducts = client_products;
      state.bankServiceUserAccounts = useraccounts;
    },
    transferResult: (state, action) => {
      state.transferResult = action.payload;
    },
    setTransferInProgress: (state, action) => {
      state.transferInProgress = action.payload;
      if (!action.payload) {
        localStorage.setItem("trInProgress", JSON.stringify(action.payload));
      } else {
        localStorage.removeItem("trInProgress");
      }
    },
    setTransferType: (state, action) => {
      state.transferType = action.payload;
    },
    setLoginRequired: (state, action) => {
      state.loginRequired = action.payload;
    },
    setBankError: (state, action) => {
      state.bankErrors = _.concat(state.bankError, action.payload);
    },
    setCurrentTransfer: (state, action) => {
      state.currentTransfer = action.payload;
    },
    setBankOnline: (state, action) => {
      state.bankOnline = action.payload;
    },
    setEnvironments: (state, action) => {
      state.environments = action.payload;
    },
    setLoadingServices: (state, action) => {
      state.loadingServices = action.payload;
    },
    setLoadingProducts: (state, action) => {
      state.loadingProducts = action.payload;
    },
    setAccessExpired: (state, action) => {
      state.accessExpired = action.payload;
    },
  },
});

export const getBankServices = (data) => async (dispatch) => {
  dispatch(slice.actions.setLoadingServices(true));
  try {
    var response = await axios.post(arkadu.urls.v2.bank.service.list, data);
    dispatch(slice.actions.setBankServices(response.data.data));
  } catch (err) {
    dispatch(slice.actions.setBankServices({ data: [], transfers: [] }));
  }
  dispatch(slice.actions.setLoadingServices(false));
};

export const setAccessExpired = (data) => async (dispatch) => {
  dispatch(slice.actions.setAccessExpired(data));
};

export const getServiceEnvironments = (data) => async (dispatch) => {
  var res = await axios.post(arkadu.urls.v2.bank.service.getEnvironments, data);
  dispatch(slice.actions.setEnvironments(res.data.data));
};

export const addServiceEnvironment = (data) => async (dispatch) => {
  var res = await axios.post(arkadu.urls.v2.bank.service.addEnvironment, data);
  if (res.data.res === 1) {
    dispatch(slice.actions.setEnvironments(res.data.data));
  } else {
    dispatch(slice.actions.setBankError(res.data.message));
  }
};
export const updateServiceEnvironment = (data) => async (dispatch) => {
  var res = await axios.post(
    arkadu.urls.v2.bank.service.updateEnvironment,
    data
  );
  if (res.data.res === 1) {
    dispatch(slice.actions.setEnvironments(res.data.data));
  } else {
    dispatch(slice.actions.setBankError(res.data.message));
  }
};

export const setCurrentService = (data) => async (dispatch) => {
  dispatch(slice.actions.setCurrentService(data));
};
export const getServiceProducts = (data) => async (dispatch) => {
  console.log("get services products");
  dispatch(slice.actions.setLoadingProducts(true));
  console.log(data.url);
  try {
    var response = await axios.post(data.url, data);
    console.log(response);
    if (response.data.res === 1) {
      dispatch(slice.actions.setServiceProducts(response.data.data));
    } else {
      dispatch(slice.actions.setBankError(response.data.message));
      if (
        response.data.status_code === 403 ||
        response.data.status_code === 401
      ) {
        dispatch(slice.actions.setAccessExpired(true));
      }
      dispatch(
        slice.actions.setServiceProducts({
          client_products: [],
          useraccounts: [],
        })
      );
    }
  } catch (err) {
    // dispatch(slice.actions.setBankError(response.data.message));
    dispatch(
      slice.actions.setServiceProducts({
        client_products: [],
        useraccounts: [],
      })
    );
  }
  dispatch(slice.actions.setLoadingProducts(false));
};
export const setBankServices = (data) => async (dispatch) => {
  dispatch(slice.actions.setBankServices(data));
};

export const setTransferInProgress = (data) => async (dispatch) => {
  dispatch(slice.actions.setTransferInProgress(data));
};

export const processTransfer = (data) => async (dispatch) => {
  try {
    var response = await axios.post(arkadu.urls.v2.bank.banesco_transfer, data);

    if (response.data.res === 1) {
      dispatch(slice.actions.transferResult(response.data.data));
      dispatch(slice.actions.setTransferInProgress(null));
    } else {
      if (response.data.message === "login_required") {
        dispatch(slice.actions.setLoginRequired(true));
      } else {
        dispatch(slice.actions.setBankError(response.data.message));
      }
    }
  } catch (err) {
    console.log(err);
  }
};

export const setBankError = (data) => async (dispatch) => {
  dispatch(slice.actions.setBankError(data));
};

export const setTransferType = (data) => async (dispatch) => {
  dispatch(slice.actions.setTransferType(data));
};

export const getTransferDetail = (data) => async (dispatch) => {
  var response = await axios.post(arkadu.urls.bank.transfer.detail, data);
  dispatch(slice.actions.transferResult(response.data.data));
};

export const setServiceProducts = (data) => async (dispatch) => {
  dispatch(slice.actions.setServiceProducts(data));
};
export const setCurrentTransfer = (data) => async (dispatch) => {
  dispatch(slice.actions.setCurrentTransfer(data));
};

export const clearBank = () => async (dispatch) => {
  dispatch(slice.actions.clearBank);
  var response = await axios.post(arkadu.urls.v2.bank.banesco_clear_transfer);
};

export const setBankOnline = (data) => async (dispatch) => {
  dispatch(slice.actions.setBankOnline(data));
};

export const checkTrInProgress = () => async (dispatch) => {
  var trIP = await localStorage.getItem("trInProgress");
  if (trIP) {
    dispatch(setTransferInProgress(JSON.parse(trIP)));
  }
};
export const reducer = slice.reducer;

export default slice;
