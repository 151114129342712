import { ExpandMoreRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  FormControl,
  Grid2 as Grid,
  Grow,
  InputLabel,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Select,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {},
  action: {
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
  appLogo: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    [theme.breakpoints.down("md")]: {
      width: theme.spacing(6),
      height: theme.spacing(6),
    },
  },
  baseHeaderActions: {
    "& .MuiButton-label": {
      fontSize: "0.70rem",
    },
  },
}));

const GroupButtonMenu = ({
  label = "Menu",
  items = null,
  className = null,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();
  const [menuState, setMenuState] = React.useState({
    currentTargetElement: null,
    currentActionMenu: null,
    currentElementId: null,
  });

  const handleActionClick = (e, actionMenu) => {
    setMenuState({
      ...menuState,
      currentActionMenu: actionMenu,
      currentTargetElement: e.currentTarget,
      currentElementId: e.currentTarget.getAttribute("id"),
    });
  };

  const renderButtonItem = (item, key) => {
    if (!item) return;
    return (
      <Button
        key={`${item.label}-actm-${key}`}
        id={`${item.label}-actm-${key}`}
        aria-controls={`${item.label}-actm-${key}-menu`}
        aria-haspopup={item.items ? "true" : undefined}
        aria-expanded={
          menuState.currentActionMenu === item ? "true" : undefined
        }
        color={item.color}
        endIcon={
          item.items && item.items.length > 0 ? <ExpandMoreRounded /> : null
        }
        startIcon={item.icon}
        variant={item.variant}
        disabled={item.disabled}
        onClick={(e) => {
          if (item.items && item.items.length > 0) {
            handleActionClick(
              e,
              menuState.currentActionMenu === item ? null : item
            );
          } else {
            if (item.onClick) {
              item.onClick(e);
            }
          }
        }}
      >
        {item.label}
      </Button>
    );
  };

  const renderMenuItem = (item, key) => {
    if (!item) return;
    var iconColor = `info.main`;
    if (item.color) {
      iconColor = `${item.color}.main`;
    }
    return (
      <MenuItem
        key={`${item.label}-actm-${key}`}
        dense
        value={item}
        onClick={(e) => {
          handleActionClick(e, item);
        }}
      >
        <ListItemIcon
          color={item.color || "info"}
          sx={{
            "& .MuiSvgIcon-root": {
              // color: option.color || "info",
              color: iconColor,
            },
          }}
        >
          {item.icon}
        </ListItemIcon>
        {item.label}
      </MenuItem>
    );
  };

  if (!items) return null;
  if (isMobile) {
    // view as mobile
    return (
      <>
        <Grid
          container
          spacing={1}
          id="base-header-actions"
          className={classes.baseHeaderActions}
        >
          <ButtonGroup size="small">
            {items
              .filter((i) => {
                if (!i) return false;
                return i.keepOnMobile;
              })
              .map((m, k) => {
                return renderButtonItem(m, k);
              })}
          </ButtonGroup>
        </Grid>

        <FormControl fullWidth sx={{ m: 1, minWidth: 120 }}>
          <InputLabel htmlFor={`${label}-select-label`}>
            {label ? label : t("Menu")}
          </InputLabel>
          <Select
            labelId={`${label}-select-label`}
            id={`${label}-select`}
            value={"select-action"}
            label={label}
            onChange={(e) => {
              setMenuState({
                ...menuState,
                currentActionMenu: e.target.value,
              });
              if (e.target.value.onClick) {
                e.target.value.onClick(e);
              }
            }}
          >
            <MenuItem value={"select-action"}>{t("Menu")}</MenuItem>
            {items
              .filter((i) => {
                if (!i) return false;
                return !i.keepOnMobile;
              })
              .map((m, k) => {
                if (!m) return;
                if (m.items) {
                  return [
                    <ListSubheader key={`${m.label}-actm`}>
                      {m.label}
                    </ListSubheader>,
                    ...m.items.map((item, key) => {
                      return renderMenuItem(item, key);
                    }),
                  ];
                }
                return renderMenuItem(m, k);
              })}
          </Select>
        </FormControl>
      </>
    );
  }
  return (
    <Box mt={2}>
      <Grid
        container
        spacing={1}
        id="base-header-actions"
        className={classes.baseHeaderActions}
      >
        <ButtonGroup
          variant="outlined"
          size="small"
          orientation={isMobile ? "vertical" : "horizontal"}
        >
          {items.map((m, k) => {
            if (!m) return;
            return renderButtonItem(m, k);
          })}
        </ButtonGroup>
      </Grid>
      {menuState.currentTargetElement && (
        <Popper
          sx={{
            zIndex: 1000,
          }}
          open={true}
          anchorEl={
            menuState.currentTargetElement
              ? menuState.currentTargetElement
              : null
          }
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "left bottom" : "left top",
              }}
            >
              <Paper>
                <ClickAwayListener
                  onClickAway={() => {
                    setMenuState({
                      ...menuState,
                      currentTargetElement: null,
                      currentActionMenu: null,
                    });
                    // handleClose
                  }}
                >
                  <MenuList
                    id="split-button-menu"
                    autoFocusItem
                    variant="menu"
                    dense
                  >
                    {menuState.currentActionMenu?.items.map((option, index) => {
                      if (!option) return;

                      var iconColor = `info.main`;
                      if (option.color) {
                        iconColor = `${option.color}.main`;
                      }
                      return (
                        <MenuItem
                          key={`${option.label}-actm-${index}`}
                          disabled={option.onClick ? false : true}
                          // selected={index === 1}
                          onClick={option.onClick}
                        >
                          {option.icon && option.icon !== "" && (
                            // add styles to children icon
                            <ListItemIcon
                              color={option.color || "info"}
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  // color: option.color || "info",
                                  color: iconColor,
                                },
                              }}
                            >
                              {option.icon}
                            </ListItemIcon>
                          )}
                          <ListItemText primary={option.label} />
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </Box>
  );
};

const ButtonMenuItem = ({ item }) => {
  return (
    <MenuItem
      key={`${item.label}-actm`}
      dense
      value={item}
      onClick={item.onClick}
    >
      {item.icon} {item.label}
    </MenuItem>
  );
};

const menuItemProps = {
  label: PropTypes.string,
  icon: PropTypes.element,
  onClick: PropTypes.func,
  keepOnMobile: PropTypes.bool,
  disabled: PropTypes.bool,
  color: PropTypes.oneOf([
    "default",
    "inherit",
    "primary",
    "secondary",
    "error",
    "info",
    "success",
    "warning",
  ]),
  variant: PropTypes.oneOf(["text", "outlined", "contained"]),
};
ButtonMenuItem.propTypes = {
  item: PropTypes.shape(menuItemProps),
};

GroupButtonMenu.propTypes = {
  label: PropTypes.string,
  items: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        ...menuItemProps,
        items: PropTypes.arrayOf(ButtonMenuItem.propTypes.item),
      })
    ),
    PropTypes.any,
    PropTypes.bool,
  ]),
  className: PropTypes.string,
};

export default GroupButtonMenu;

export { ButtonMenuItem };
