import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import arkadu from "src/coreConfig";
import { setShopState } from "src/slices/shop";
import { apiGet } from "src/utils/axios";
import useSWR from "swr";

// export function useUserAccount(id, customer_id) {
//   const { data, error, mutate } = useSWR(
//     `${arkadu.urls.v2.account.detail}?id=${id}&customer_id=${customer_id}`,
//     apiGet
//   );
//   const dispatch = useDispatch();
//   const updateAccount = () => {
//     var _account = data.data;

//     if (!_account.debits) {
//       dispatch(setShopState({ currentAccount: _account }));

//       return;
//     }

//     var _reversed = [..._account.debits].reverse();
//     var _sum = 0;

//     for (var i in _reversed) {
//       _sum += _reversed[i].state !== "invalid" ? _reversed[i].amount : 0;
//       _reversed[i] = { ..._reversed[i], temp_balance: _sum };
//     }

//     var _preData = { ..._account };
//     _preData.debits = _reversed.reverse();

//     dispatch(setShopState({ currentAccount: _preData }));
//   };

//   useEffect(() => {
//     if (data && data.res === 1) {
//       updateAccount();
//     }
//   }, [data]);
//   const reloadAccount = () => {
//     mutate();
//   };
//   return {
//     userAccount: data && data.data,
//     accountError: error,
//     isError: error,
//     isLoading: !error && !data,
//     reloadAccount: reloadAccount,
//   };
// }

// use a custom hook to get the debits with temp_balance
function useDebitsWithTempBalance(debits) {
  const [userAccountState, setUserAccountState] = useState({
    debitsWithTempBalance: [],
    debitsInactive: [],
    debitsInactiveSum: 0,
    pendingTransactions: [],
  });

  useEffect(() => {
    if (debits) {
      // reverse the debits array and calculate the temp_balance
      const reversed = [...debits].reverse();
      let sum = 0;
      let pending = 0;
      let debitsInactive = [];
      let pendingTransactions = [];
      const debitsWithTempBalance = reversed.map((debit) => {
        sum += debit.state !== "invalid" && debit.active ? debit.amount : 0;
        if (
          !debit.active &&
          debit.state !== "invalid" &&
          debit.operation === "debit"
        ) {
          pending += debit.amount;
          debitsInactive.push(debit);
        }
        if (debit.state === "not_funds") {
          pendingTransactions.push(debit);
        }

        return { ...debit, temp_balance: sum };
      });
      setUserAccountState({
        debitsWithTempBalance: debitsWithTempBalance.reverse(),
        debitsInactive: debitsInactive.reverse(),
        debitsInactiveSum: pending,
        pendingTransactions: pendingTransactions.reverse(),
      });
    }
  }, [debits]);

  return userAccountState;
}

export function useUserAccount(id, customer_id) {
  const { data, error, mutate } = useSWR(
    `${arkadu.urls.v2.account.detail}?id=${id}&customer_id=${customer_id}`,
    apiGet
  );
  const dispatch = useDispatch();

  // get the debits with temp_balance using the custom hook
  const {
    debitsWithTempBalance,
    debitsInactiveSum,
    debitsInactive,
    pendingTransactions,
  } = useDebitsWithTempBalance(data && data.data && data.data.debits);

  // update the account with the debits with temp_balance
  useEffect(() => {
    if (data && data.res === 1) {
      const account = {
        ...data.data,
        debits: debitsWithTempBalance,
        debitsInactive: debitsInactive,
        debitsInactiveSum: debitsInactiveSum,
        pendingTransactions: pendingTransactions,
      };
      dispatch(
        setShopState({
          currentAccount: {
            ...account,
            pendingBalance:
              (data &&
                data.data.deposits
                  ?.filter((a) => a.state === "pending")
                  .reduce((a, b) => a + b.amount, 0)) ||
              0,
            m: 1,
          },
        })
      );
    }
  }, [data, debitsWithTempBalance, dispatch]);

  const reloadAccount = () => {
    mutate();
  };

  return {
    userAccount: data && {
      ...data.data,
      pendingBalance:
        (data &&
          data.data.deposits
            ?.filter((a) => a.state === "pending")
            .reduce((a, b) => a + b.amount, 0)) ||
        0,
      m: 1,
    },
    accountError: error,
    isError: error,
    isLoading: !error && !data,
    reloadAccount: reloadAccount,
  };
}

export { useUserAccount as default, useDebitsWithTempBalance };
