import _ from "lodash";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import arkadu from "src/coreConfig";
import useUser from "src/hooks/useUser";
import { clearCurrentCustomer, setCustomerByName } from "src/slices/customers";
import { resetGlobals } from "src/slices/global";
import { saveSettings } from "src/slices/settings";
import {
  clearTaxesError,
  setCurrentLicense,
  setUpdatedTaxes,
} from "src/slices/taxes";
import { logout } from "src/slices/users";
import { useDispatch, useSelector } from "src/store";
import NotAuthorizedView from "src/views/errors/NotAuthorizedView";
import LoadingScreen from "./LoadingScreen";
import LottiePlayer from "./LottieAnimation";

const AuthGuard = ({ children }) => {
  const { user, isAuthenticated, loaded, selectedIdentity } = useSelector(
    (state) => state.users
  );
  const { adminMode } = useSelector((state) => state.settings);
  const dispatch = useDispatch();
  const {
    currentCustomer,
    customerSections,
    customersList,
    customerDoesNotExist,
  } = useSelector((state) => state.customers);

  const { t } = useTranslation();
  const { motorError } = useSelector((state) => state.motor);
  const { taxesError } = useSelector((state) => state.taxes);
  const { customerName } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  let navigate = useNavigate();

  const [viewState, setViewState] = useState({
    lastCustomer: null,
    checkingPerms: true,
    membership: false,
    moduleEnabled: null,
  });

  const {} = useUser();

  useEffect(() => {
    var _fullPath = location.pathname;
    // remove currentCustomer.shortname from path
    if (
      currentCustomer &&
      _fullPath.startsWith(`/${currentCustomer.shortname}`)
    ) {
      _fullPath = _fullPath.replace(`/${currentCustomer.shortname}`, "");
    }
    var _pathStart = _fullPath.split("/")[1];
    var _clearPath = location.pathname.replace(/\//g, "");

    console.log("location.pathname", location.pathname, _pathStart, _clearPath);

    var _path = _clearPath;
    var enabled_paths = [
      "monitorappointments",
      "app",
      "AdminDashboard",
      "user",
      "admin",
    ];
    if (enabled_paths.includes(_pathStart)) {
      setViewState((prev) => ({
        ...prev,
        moduleEnabled: true,
      }));
      return;
    }

    if (currentCustomer && customerSections && customerSections.length > 0) {
      if (currentCustomer) {
        _path = _clearPath.replace(currentCustomer.shortname, "");
      }
      if (_clearPath === currentCustomer.shortname) {
        setViewState((prev) => ({
          ...prev,
          moduleEnabled: true,
        }));
        return;
      }

      _.forEach(customerSections, (_s) => {
        if (
          currentCustomer &&
          (location.pathname.includes(
            `/${currentCustomer.shortname}${_s.href}`
          ) ||
            location.pathname.includes(`/${currentCustomer.shortname}/admin/`))
        ) {
          setViewState((prev) => ({
            ...prev,
            moduleEnabled: true,
          }));
          return false;
        }
      });
      // setViewState((prev) => ({
      //   ...prev,
      //   moduleEnabled: false,
      // }));
    }
  }, [currentCustomer, customerSections]);

  useEffect(() => {
    var hostname = window.location.hostname;

    if (hostname !== "arkadu.com" && !currentCustomer && customersList) {
      var _customer = _.find(
        customersList,
        (_c) => _c.custom_domain === hostname
      );

      if (_customer) {
        dispatch(setCustomerByName({ shortname: _customer.shortname }));
      }
    }
  }, [location, customersList, currentCustomer]);

  useEffect(() => {
    if (!currentCustomer && customerName) {
      dispatch(setCustomerByName({ shortname: customerName }));
    }
  }, [customerName, currentCustomer]);

  useEffect(() => {
    if (location.pathname.startsWith("/app")) {
      dispatch(saveSettings({ adminMode: false }));
      dispatch(clearCurrentCustomer({ licenses: null }));
      dispatch(setUpdatedTaxes({ licenses: null }));
      dispatch(setCurrentLicense(null));
      dispatch(resetGlobals());
    }

    if (location.pathname.endsWith("/login")) {
      handleAuthRedirect();
    }
    if (location.pathname === "/logout") {
      dispatch(logout());
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (motorError) {
      enqueueSnackbar(motorError, { variant: "error" });
    }
  }, [motorError]);
  useEffect(() => {
    if (taxesError) {
      enqueueSnackbar(taxesError, { variant: "error" });
      dispatch(clearTaxesError());
    }
  }, [taxesError]);

  const checkUserMembership = useCallback(() => {
    if (!currentCustomer || !user) {
      return;
    }

    if (!user.member_set) {
      return false;
    }
    var membership = user.member_set.find(
      (m) => m.customer_id === currentCustomer.id
    );
    if (membership) {
      setViewState((prev) => ({
        ...prev,
        membership: true,
        checkingPerms: false,
      }));
      return true;
    }
    setViewState((prev) => ({
      ...prev,
      membership: false,
      checkingPerms: false,
    }));
    dispatch(saveSettings({ adminMode: false }));

    return false;
  }, [currentCustomer, user, adminMode]);

  useEffect(() => {
    if (currentCustomer && currentCustomer.id !== viewState.lastCustomer) {
      setViewState((prev) => ({
        ...prev,
        lastCustomer: currentCustomer.id,
      }));
    } else {
      setViewState((prev) => ({
        ...prev,
        lastCustomer: null,
      }));
    }
  }, [currentCustomer]);

  const handleAuthRedirect = () => {
    var url = `https://${arkadu.hostname}/auth`;
    if (customer_id) {
      url += `?customer_id=${customer_id}&client_id=${arkadu.client_id}`;
    }
    window.location.replace(url, "_self");
  };

  useEffect(() => {
    if (!isAuthenticated && loaded) {
      // handleAuthRedirect();
    }
  }, [isAuthenticated, loaded]);

  useEffect(() => {
    if (!isAuthenticated && loaded) {
      if (!window.location.pathname.endsWith("login")) {
        localStorage.setItem("next", window.location.pathname);
        var loginUrl = customerName ? `/${customerName}/login` : "/login";
        navigate(loginUrl);
      }
      // navigate("/login");
    }
  }, [isAuthenticated, loaded]);

  useEffect(() => {
    if (!location.pathname.includes("/admin")) {
      setViewState((prev) => ({
        ...prev,
        checkingPerms: false,
      }));
      return;
    }

    if (user && currentCustomer && location.pathname.includes("/admin")) {
      checkUserMembership();
    }
  }, [currentCustomer, user, location.pathname]);

  if (!currentCustomer && customerDoesNotExist && customerName) {
    return <NotAuthorizedView message={t("El cliente no existe")} />;
  }
  if (customerName && !currentCustomer) {
    return <LottiePlayer path="/static/lotties/arkadu_city.json" />;
  }

  if (viewState.moduleEnabled === null) {
    return <LoadingScreen message={t("comprobando m�dulo")} />;
  }

  if (currentCustomer && !viewState.moduleEnabled) {
    return (
      <NotAuthorizedView
        message={t("El cliente no tiene habilitado este m�dulo")}
      />
    );
  }

  if (viewState.checkingPerms || !loaded) {
    return <LoadingScreen message={t("verificando permisos")} />;
  }

  if (!isAuthenticated && loaded) {
    return <Navigate to={customerName ? `/${customerName}/login` : "/login"} />;
  }

  if (!selectedIdentity && location.pathname !== "/app/identity") {
    return <Navigate to="/app/identity" />;
  }

  // if (
  //   user &&
  //   !user.is_completed &&
  //   location.pathname !== "/app/account/registration"
  // ) {
  //   return <Navigate to="/app/account/registration" />;
  // }

  if (location.pathname.includes("/admin") && !viewState.membership) {
    if (!location.pathname.includes("/corporate/admin/")) {
      var newPath = location.pathname.replace("/admin", "");
      navigate(newPath);
      return <NotAuthorizedView />;
    }
  }

  return (
    <>
      <Outlet />
    </>
  );
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
