import { Box, Button, Link, Portal, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    color: theme.palette.common.white,
    maxWidth: 400,
    position: "fixed",
    bottom: 0,
    left: 0,
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    outline: "none",
    zIndex: 2000,
  },
  action: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
}));

const CookiesNotification = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    Cookies.set("consent", "true", {
      expires: 365 * 10,
      sameSite: "strict",
    });
    setOpen(false);
  };

  useEffect(() => {
    const consent = Cookies.get("consent");

    if (!consent) {
      setOpen(true);
    }
  }, []);

  if (!open) {
    return null;
  }

  return (
    <Portal>
      <div className={classes.root}>
        <Typography variant="body1" color="inherit" sx={{
          fontSize: '0.7rem',
          textAlign: 'justify',

        }}>
          Usamos Cookies para garantizar su seguridad y proveer servicios
          personalizados. Puede leer sobre nuestra pol�tica en{" "}
          <Link
            component="a"
            color="inherit"
            underline="always"
            href="https://arkadu.com/privacy-policy"
            target="_blank"
          >
            Pol�tica de Privacidad
          </Link>
          .
        </Typography>
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button
            onClick={handleClose}
            variant="contained"
            className={classes.action}
            size="small"
            sx={{
              fontSize: '0.7rem',
              textAlign: 'justify',
              padding: '0.5rem 1rem',

            }}
          >
            De acuerdo
          </Button>
        </Box>
      </div>
    </Portal>
  );
};

export default CookiesNotification;
