import { lazy } from "react";
import TaxAdminLayout from "src/views/arkadu/taxes/admin/AdminLayout";

const TaxesIndex = lazy(() => import("src/views/arkadu/taxes/TaxesIndex"));
const TaxesClassifier = lazy(
  () => import("src/views/arkadu/taxes/TaxesClassifier")
);
const PenaltyDetail = lazy(
  () => import("src/views/arkadu/taxes/penalty/PenaltyDetail")
);
const LiquorLicenseDetail = lazy(
  () => import("src/views/arkadu/taxes/liquor/LiquorLicenseDetail")
);
const TaxesFixMigrating = lazy(
  () => import("src/views/arkadu/taxes/TaxesFixMigrating")
);
const PeriodReport = lazy(
  () => import("src/views/arkadu/taxes/admin/period/PeriodReport")
);
const DashboardAlternativeView = lazy(
  () => import("src/views/reports/DashboardAlternativeView")
);
const TaxesDashboard = lazy(
  () => import("src/views/arkadu/taxes/admin/AdminLayout")
);
const TaxesDeclarationDetail = lazy(
  () => import("src/views/arkadu/taxes/DeclarationDetail")
);
const TaxesRetentionDetail = lazy(
  () => import("src/views/arkadu/taxes/TaxesRetentionDetail")
);
const LicenseRevision = lazy(
  () => import("src/views/arkadu/taxes/license/parts/LicenseRevision")
);
const LicenseDetailView = lazy(
  () => import("src/views/arkadu/taxes/license/parts/LicenseDetailView")
);
const LicenseTaxAmendmentDetail = lazy(
  () => import("src/views/arkadu/taxes/license/parts/LicenseTaxAmendmentDetail")
);
const LicenseForm = lazy(
  () => import("src/views/arkadu/taxes/license/forms/LicenseForm")
);

const taxes_routes_v6 = [
  { index: true, element: <TaxesIndex /> },
  { path: ":tabName", element: <TaxesIndex /> },
  { path: "classifier", element: <TaxesClassifier /> },
  { path: "penalty/detail/:id", element: <PenaltyDetail /> },
  { path: "liquor/license/:id", element: <LiquorLicenseDetail /> },
  { path: "FixMigrating", element: <TaxesFixMigrating /> },
  { path: "dashboard", element: <TaxesDashboard /> },
  { path: "dashboard/periods", element: <PeriodReport /> },
  { path: "declaration/:declarationId", element: <TaxesDeclarationDetail /> },
  { path: "retention/:retentionId", element: <TaxesRetentionDetail /> },
  { path: "license/add", element: <LicenseForm /> },
  {
    path: "license/:licenseId",
    children: [
      { index: true, element: <LicenseDetailView /> },
      { path: ":tabName", element: <LicenseDetailView /> },
    ],
  },
  { path: "revision/:licenseId", element: <LicenseRevision /> },
  { path: "amendment/:id", element: <LicenseTaxAmendmentDetail /> },
  { path: "classifier", element: <TaxesClassifier /> },
  {
    element: <TaxAdminLayout />,
    children: [
      { path: "dashboard", element: <TaxesDashboard /> },
      { path: "dashboard/periods", element: <PeriodReport /> },
    ],
  },
];

export default taxes_routes_v6;

// import { lazy } from "react";
// import TaxAdminLayout from "src/views/arkadu/taxes/admin/AdminLayout";
// export default [
//   {
//     exact: true,
//     path: ["/:customerName/taxes/license/:licenseId/amendment/:id"],
//     component: lazy(
//       () =>
//         import("src/views/arkadu/taxes/license/parts/LicenseTaxAmendmentDetail")
//     ),
//   },
//   {
//     exact: true,
//     path: [
//       "/:customerName/taxes/license/:licenseId/:tabName",
//       "/:customerName/taxes/license/:licenseId/",
//     ],
//     component: lazy(
//       () => import("src/views/arkadu/taxes/license/parts/LicenseDetailView")
//     ),
//   },
//   {
//     exact: true,
//     path: "/:customerName/taxes/license/revision/:licenseId/",
//     component: lazy(
//       () => import("src/views/arkadu/taxes/license/parts/LicenseRevision")
//     ),
//   },
//   {
//     exact: true,
//     path: "/:customerName/taxes/declaration/:declarationId/",
//     lazy: () => import("src/views/arkadu/taxes/DeclarationDetail"),
//   },
//   {
//     exact: true,
//     path: "/:customerName/taxes/retention/:retentionId/",
//     component: lazy(
//       () => import("src/views/arkadu/taxes/TaxesRetentionDetail")
//     ),
//   },
//   {
//     exact: true,
//     path: "/:customerName/taxes/classifier/",
//     lazy: () => import("src/views/arkadu/taxes/TaxesClassifier"),
//   },
//   {
//     exact: true,
//     path: ["/:customerName/taxes/penalty/detail/:id"],
//     component: lazy(
//       () => import("src/views/arkadu/taxes/penalty/PenaltyDetail")
//     ),
//   },
//   {
//     exact: true,
//     path: "/:customerName/taxes/liquor/license/:id",
//     component: lazy(
//       () => import("src/views/arkadu/taxes/liquor/LiquorLicenseDetail.js")
//     ),
//   },
//   {
//     path: "/:customerName/taxes/FixMigrating/",
//     layout: TaxAdminLayout,
//     routes: [
//       {
//         exact: true,
//         path: "/:customerName/taxes/FixMigrating/",
//         component: lazy(
//           () => import("src/views/arkadu/taxes/TaxesFixMigrating")
//         ),
//       },
//     ],
//   },
//   {
//     exact: true,
//     path: ["/:customerName/taxes/:tabName/", "/:customerName/taxes/"],
//     lazy: () => import("src/views/arkadu/taxes/TaxesIndex"),
//   },
//   {
//     path: "/:customerName/taxes/dashboard/",
//     layout: TaxAdminLayout,
//     routes: [
//       {
//         exact: true,
//         path: "/:customerName/taxes/dashboard/periods/",
//         component: lazy(
//           () => import("src/views/arkadu/taxes/admin/period/PeriodReport")
//         ),
//       },
//       {
//         exact: true,
//         path: "/:customerName/taxes/dashboard/",
//         component: lazy(
//           () => import("src/views/reports/DashboardAlternativeView")
//         ),
//       },
//     ],
//   },
// ];
