import i18n from "i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-chained-backend";
// import HttpApi from "i18next-http-backend";
// import I18NextLocalStorageBackend from "i18next-localstorage-backend";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(I18nextBrowserLanguageDetector)
  .use(Backend)
  .init({
    fallbackLng: "es",
    debug: false,
    lng: "es",
    ns: ["translation"],
    fallbackNS: "translation",
    react: {
      bindI18n: "languageChanged",
      useSuspense: true,
    },
    func: {
      list: ["i18next.t", "i18n.t", "t"],
      extensions: [".js", ".jsx"],
    },
    saveMissing: false,
    // backend: {
    //   backends: [I18NextLocalStorageBackend, HttpApi],
    //   backendOptions: [
    //     {
    //       prefix: "i18next_res_",
    //       expirationTime: 24 * 60,
    //       store: window.localStorage,
    //     },
    //     {
    //       loadPath: arkadu.urls.trans.load_path,
    //       addPath: arkadu.urls.trans.add_path,
    //       crossDomain: true,
    //       customHeaders: {
    //         Authorization: "Bearer AKSJkjashdlkjheqq",
    //       },
    //       queryStringParams: {
    //         customer_id: 2050420,
    //         client_id: "NJ7xcnPEO5sdzagicmyQvZJKqdBlIiselNqaUrN7",
    //       },
    //     },
    //   ],
    // },
    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
