import { lazy } from "react";

const AppointmentsCallMonitor = lazy(
  () =>
    import("src/views/arkadu/corporate/appointments/AppointmentsCallMonitor")
);

const AppointmentsSearch = lazy(
  () => import("src/views/arkadu/corporate/appointments/AppointmentsSearch")
);

const AppointmentsAdminView = lazy(
  () => import("src/views/arkadu/corporate/appointments/AppointmentsAdminView")
);

const CorporateProcedureAdmin = lazy(
  () => import("src/views/arkadu/corporate/procedure/CorporateProcedureAdmin")
);

const ProcedureDetailView = lazy(
  () => import("src/views/arkadu/corporate/procedure/ProcedureDetailView")
);

const ProcedurePreview = lazy(
  () => import("src/views/arkadu/corporate/procedure/ProcedurePreview")
);

const ProcedureIndex = lazy(
  () => import("src/views/arkadu/corporate/procedure/index")
);

const CorporateScheduleDetail = lazy(
  () => import("src/views/arkadu/corporate/schedules/CorporateScheduleDetail")
);

const CorporateDepartmentDetail = lazy(
  () =>
    import("src/views/arkadu/corporate/departments/CorporateDepartmentDetail")
);
const CorporateIndex = lazy(() => import("src/views/arkadu/corporate/index"));
export default [
  {
    index: true,
    element: <CorporateIndex />,
  },
  {
    path: ":tabName",
    element: <CorporateIndex />,
  },
  {
    path: "monitor/calls/",
    element: <AppointmentsCallMonitor />,
  },
  {
    path: "appointment/search/",
    element: <AppointmentsSearch />,
  },
  {
    path: "appointment/",
    element: <AppointmentsAdminView />,
  },
  {
    path: "procedure/admin/:id",
    element: <CorporateProcedureAdmin />,
  },
  {
    path: "procedure/:id",
    element: <ProcedureDetailView />,
  },
  {
    path: "procedure/preview/:id",
    element: <ProcedurePreview />,
  },
  {
    path: "procedure/",
    element: <ProcedureIndex />,
  },
  {
    path: "schedule/:id",
    element: <CorporateScheduleDetail />,
  },
  {
    path: "department/:id",
    element: <CorporateDepartmentDetail />,
  },
  {
    path: "department/:id/:tabName",
    element: <CorporateDepartmentDetail />,
  },
];
