import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  currentPoll: null,
};

const slice = createSlice({
  name: "polls",
  initialState: initialState,
  reducers: {
    setPollState: (state, action) => {
      Object.keys(action.payload).forEach((k) => {
        state[k] = action.payload[k];
      });
    },
  },
});

export const reducer = slice.reducer;

export const setPollState = (data) => async (dispatch) => {
  dispatch(slice.actions.setPollState(data));
};
