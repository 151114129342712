import { lazy } from "react";

const CadastreIndex = lazy(() => import("src/views/arkadu/cadastre/index"));
const RealEstateView = lazy(
  () => import("src/views/arkadu/cadastre/RealEstateView")
);

export default [
  {
    index: true,
    element: <CadastreIndex />,
  },
  {
    path: "realestate/detail/:realEstateId/",
    element: <RealEstateView />,
  },
  {
    path: "realestate/:actionName/",
    element: <CadastreIndex />,
  },
  {
    path: "realestate/:actionName/:id/",
    element: <CadastreIndex />,
  },
  {
    path: ":tabName",
    element: <CadastreIndex />,
  },
];
