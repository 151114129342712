import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import arkadu, { server } from "src/coreConfig";
import { default as axios, default as axiosInstance } from "src/utils/axios";
import wait from "src/utils/wait";

let db;
const initialState = {
  currentDocument: null,
  accessRequested: false,
  accessMessage: null,
  currentUserTab: "general",
  currentUserAction: null,
  customerRealEstates: [],
  identitySelected: null,
  isLoading: false,
  isAuthenticated: false,
  loaded: false,
  logout: false,
  user: null,
  selectedIdentity: null,
  identities: [],
  email: null,
  code: null,
  currentCompany: null,
  loginCustomer: null,
  notifyMessage: null,
  authorized: false,
  roles: [],
  members: [],
  role_users: [],
  selectedMember: null,
  ipLocations: {},
  identityTypes: [],
  identitySelection: false,
};
const slice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setEmail: (state, action) => {
      const { email } = action.payload;
      state.email = email;
    },
    setCode: (state, action) => {
      const { code } = action.payload;
      state.code = code;
    },
    setAccessToken: (state, action) => {
      const { accessToken } = action.payload;
      state.accessToken = accessToken;
      setSession(accessToken);
    },
    setUser: (state, action) => {
      const { user } = action.payload;
      state.user = user;
      state.loaded = true;
      state.isAuthenticated = true;
      // check Last Authorization
    },
    setAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setIdentities: (state, action) => {
      state.user.identities = action.payload;
    },
    setIdentitySelected: (state, action) => {
      state.identitySelected = action.payload;
    },
    setCurrentCompany: (state, action) => {
      const { company_id } = action.payload;

      state.currentCompany = _.find(
        state.user.companies,
        (_company) => _company.id === parseInt(company_id)
      );
    },
    refreshCurrentCompany: (state, action) => {
      state.currentCompany = _.find(
        state.user.companies,
        (_company) => _company.id === state.currentCompany.id
      );
    },
    updateUserData: (state, action) => {
      const data = action.payload;
      state.user = _.merge({}, state.user, data);
      state.loaded = true;
    },
    getDocument: (state, action) => {
      const { document } = action.payload;
    },
    setIsLoading: (state, action) => {
      const { isLoading } = action.payload;
      state.isLoading = isLoading;
    },
    setLoaded: (state, action) => {
      const { loaded } = action.payload;
      state.loaded = loaded;
    },
    clearUser: (state, action) => {
      state.logout = true;
      state.user = null;
      state.isAuthenticated = false;
      state.email = null;
      state.code = null;
      state.loaded = true;
      localStorage.removeItem("accessToken");
    },
    setNotifyMessage: (state, action) => {
      const { notify } = action.payload;
      state.notifyMessage = notify;
    },
    setAccessRequested: (state, action) => {
      const { res, message } = action.payload;
      state.accessRequested = res;
      state.accessMessage = message;
    },
    setCurrentUserTab: (state, action) => {
      const { tabName } = action.payload;
      state.currentUserTab = tabName ? tabName : "general";
    },
    setCurrentUserAction: (state, action) => {
      const { actionName } = action.payload;
      state.currentUserAction = actionName ? actionName : "new";
    },
    setUserItem: (state, action) => {
      const { itemName, data } = action.payload;
      state[itemName] = data;
    },
    setUserState: (state, action) => {
      if (action.payload) {
        Object.keys(action.payload).map((key) => {
          state[key] = action.payload[key];
        });
      }
    },
    setLogout: (state, action) => {
      state.logout = action.payload;
    },
    setAuthorized: (state, action) => {
      state.authorized = action.payload;
      if (action.payload) {
        localStorage.setItem("authorized", Date.now());
      }
    },
  },
});
export const reducer = slice.reducer;

export const setEmail = (data) => async (dispatch) => {
  dispatch(slice.actions.setEmail({ email: data }));
};

export const setUser = (data) => async (dispatch) => {
  dispatch(slice.actions.setUser(data));
};
export const setIdentities = (data) => async (dispatch) => {
  dispatch(slice.actions.setIdentities(data));
};

export const setCode = (data) => async (dispatch) => {
  dispatch(slice.actions.setCode({ code: data }));
};
export const setCurrentCompany = (data) => async (dispatch) => {
  dispatch(slice.actions.setCurrentCompany(data));
};

export const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    axios.defaults.withCredentials = true;
    // axios.defaults.xsrfCookieName = "csrftoken";
    axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
    axios.defaults.xsrfCookieName = "csrftoken";
  } else {
    localStorage.removeItem("accessToken");
    delete axios.defaults.headers.common.Authorization;
    axios.defaults.withCredentials = false;
    axios.defaults.xsrfCookieName = null;
  }
};

// export const setUserSession = (user) => async (dispatch) => {
//   setSession(user.accessToken);
// }
export const updateUserData = (data) => async (dispatch) => {
  dispatch(slice.actions.updateUserData(data));
};
export const logout = (data) => async (dispatch) => {
  localStorage.removeItem("accessToken");
  // remove indexDB
  await indexedDB.deleteDatabase("arkadu");
  delete axios.defaults.headers.common.Authorization;
  setSession(null);
  dispatch(slice.actions.clearUser());
};

// sTATES
export const setCurrentUserTab = (data) => async (dispatch) => {
  dispatch(slice.actions.setCurrentUserTab({ tabName: data }));
};
export const setCurrentUserAction = (data) => async (dispatch) => {
  dispatch(slice.actions.setCurrentUserAction({ actionName: data }));
};

export const setIdentitySelected = (data) => async (dispatch) => {
  dispatch(slice.actions.setIdentitySelected(data));
};

// CREATES
export const newCompany = (data) => async (dispatch) => {
  var response = await axios.post(arkadu.urls.v2.user.company, data);
  dispatch(slice.actions.updateUserData(response.data.data));
};

export const addCompanyBranch = (data) => async (dispatch) => {
  dispatch(slice.actions.setIsLoading({ isLoading: true }));
  const response = await axios.post(arkadu.urls.v2.user.company, data);
  dispatch(slice.actions.updateUserData(response.data.data));
  dispatch(slice.actions.refreshCurrentCompany());
  dispatch(slice.actions.setIsLoading({ isLoading: false }));
};

export const addNewVehicle = (data) => async (dispatch) => {
  var response = await axios.post(arkadu.urls.v2.motor.vehicle, data);

  try {
    dispatch(slice.actions.updateUserData(response.data.data));
  } catch (err) {
    dispatch(
      slice.actions.setNotifyMessage({
        notify: { message: err, variant: "error" },
      })
    );
  }
};

//  DELETES
export const deleteUserIdentity = (data) => async (dispatch) => {
  dispatch(slice.actions.setIsLoading({ isLoading: true }));
  const response = await axios.post(arkadu.urls.v2.user.identity.base, data);
  dispatch(slice.actions.setUser({ user: response.data.data }));
  dispatch(slice.actions.setIsLoading({ isLoading: false }));
};
export const deleteUserCompany = (data) => async (dispatch) => {
  dispatch(slice.actions.setIsLoading({ isLoading: true }));
  const response = await axios.post(arkadu.urls.v2.user.company, data);
  dispatch(slice.actions.setUser({ user: response.data.data }));
  dispatch(slice.actions.setIsLoading({ isLoading: false }));
};
export const deleteUserCompanyBranch = (data) => async (dispatch) => {
  dispatch(slice.actions.setIsLoading({ isLoading: true }));
  const response = await axios.post(arkadu.urls.v2.user.company, data);

  dispatch(slice.actions.setUser({ user: response.data.data }));
  dispatch(slice.actions.setIsLoading({ isLoading: false }));
};

export const setUserItem = (data) => async (dispatch) => {
  dispatch(slice.actions.setUserItem(data));
};

export const setUserState = (data) => async (dispatch) => {
  dispatch(slice.actions.setUserState(data));
};

export const getProfile = (data) => async (dispatch) => {
  console.log("getProfile", arkadu.urls.user.get_profile);
  try {
    const response = await axios.get(arkadu.urls.user.get_profile);
    console.log("getProfile", response);
    if (response.status === 403) {
      dispatch(slice.actions.clearUser({ loaded: true }));
      return;
    }
    if (response.data && response.data.user) {
      dispatch(slice.actions.setUser(response.data));

      return response.data.user;
    }
  } catch (err) {
    console.log(`getProfile error`, err);
    dispatch(
      slice.actions.setNotifyMessage({
        notify: {
          message: err.data ? err.data.message : "load user profile failed",
          variant: "error",
        },
      })
    );
    return null;
  } finally {
    dispatch(slice.actions.setLoaded({ loaded: true }));
    return null;
  }
};

export const setAuthorized = (data) => async (dispatch) => {
  dispatch(slice.actions.setUserItem({ authorized: data }));
};

export const login = (data) => async (dispatch) => {
  const { email, code, csrf } = data;
  const response = await axios.post(arkadu.urls.user.login, {
    email: email,
    code: code,
    csrf: csrf,
    origin: "mobile",
    client_id: arkadu.client_id,
    client_secret: arkadu.client_secret,
    crossDomain: true,
    headers: { "Content-Type": "application/json" },
  });
  if (response && response.data) {
    if (response.data.res === 1) {
      setSession(response.data.access_token);
      dispatch(
        slice.actions.setUserItem({
          user: response.data.user,
          loaded: true,
          isAuthenticated: true,
          accessToken: response.data.access_token,
          accessRequested: 0,
          accessMessage: null,
        })
      );
      await wait(200);
    } else {
      console.log("login error", response);
      dispatch(
        slice.actions.setNotifyMessage({
          notify: { message: response.data.message, variant: "error" },
        })
      );
    }
  }

  // if (response.data.res === 0) {
  //   dispatch({
  //     type: 'DEVICE_ERROR',
  //     payload: {
  //       message: response.data.message,
  //       res: response
  //     }
  //   });
  // } else {
  //   const { access_token, user } = response.data;
  //   {
  //     /* console.log(response.data) */
  //   }
  //   setSession(access_token);
  //   dispatch({
  //     type: 'LOGIN',
  //     payload: {
  //       user
  //     }
  //   });
  // }
};

export const requestAccess = (email, _token) => async (dispatch) => {
  const response = await axios.post(arkadu.urls.user.access_request, {
    email: email,
    token: _token,
    origin: "mobile",
    client_id: arkadu.client_id,
    client_secret: arkadu.client_secret,
    crossDomain: true,
    headers: { "Content-Type": "application/json" },
  });
  const { res, message } = response.data;
  dispatch(slice.actions.setAccessRequested({ res: res, message: message }));
  dispatch(slice.actions.setEmail({ email: email }));
};

export const checkPermission = ({ action, module, customerId, user }) => {
  if (!user) {
    return false;
  }

  if (!user.is_superuser) {
    return true;
  } else {
    var isSuperUser = false;
    if (user.member_set && user.member_set.length > 0) {
      user.member_set.map((m) => {
        if (m.customer_id === customerId && m.is_superuser) {
          isSuperUser = true;
        }
      });
    }

    if (isSuperUser === true) {
      return true;
    } else if (user.perms && user.perms.length > 0) {
      var grantAccess = false;
      user.perms.map((p) => {
        if (p[0] === action && p[1] === module && p[2] === customerId) {
          grantAccess = true;
        }
      });

      if (!grantAccess) {
        axiosInstance.post(`${server}/api/v2/user/user/logCheckPermission/`, {
          action: action,
          module: module,
          customerId: customerId,
          granted: false,
        });
      }
      return grantAccess;
    }
  }
};

export const getInstancePermissions = (name, module, user, customerId) => {
  return {
    view: checkPermission({
      action: `can_view_${name}`,
      module: module,
      user: user,
      customerId: customerId,
    }),
    change: checkPermission({
      action: `can_change_${name}`,
      module: module,
      user: user,
      customerId: customerId,
    }),
    add: checkPermission({
      action: `can_add_${name}`,
      module: module,
      user: user,
      customerId: customerId,
    }),
    delete: checkPermission({
      action: `can_delete_${name}`,
      module: module,
      user: user,
      customerId: customerId,
    }),
  };
};

export const getArkaduSessionFromToken = (token) => async (dispatch) => {
  var res = await axiosInstance.get(arkadu.urls.user.get_profile, {});
};
