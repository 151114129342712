import { lazy } from "react";

const PlanningLayout = lazy(
  () => import("src/views/arkadu/planning/PlanningLayout")
);
const PlanningIndex = lazy(
  () => import("src/views/arkadu/planning/PlanningIndex")
);
const PlanningBudgetDetail = lazy(
  () => import("src/views/arkadu/planning/PlanningBudgetDetail")
);

export default [
  {
    element: <PlanningLayout />,
    children: [
      {
        index: true,
        element: <PlanningIndex />,
      },
      {
        path: "budget/:id/:tabName",
        element: <PlanningBudgetDetail />,
      },
      {
        path: "budget/:id/",
        element: <PlanningBudgetDetail />,
      },
      {
        path: ":tabName",
        element: <PlanningIndex />,
      },
    ],
  },
];
