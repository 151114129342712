import axios from "axios";

const axiosInstance = axios.create();

axiosInstance.defaults.withCredentials = true;
axiosInstance.defaults.xsrfHeaderName = "X-CSRFToken";
axiosInstance.defaults.xsrfCookieName = "csrftoken";

// if (accessToken) {
//   axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
//   axios.defaults.withCredentials = true;
// }
axiosInstance.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem("accessToken");

  if (accessToken) {
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  }
  config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : "";
  return config;
});
axiosInstance.interceptors.response.use(
  (response) => {
    if (response.status === 302) {
      return Promise.reject({ status: 302 });
    } else {
      return response;
    }
  },
  (error) => {
    if (error.response) {
      if (error.response) {
        return Promise.reject(error.response);
      } else if (error.response.status) {
        return Promise.reject(error.response.statusText);
      } else {
        Promise.reject("Something went wrong");
      }
    }
    // Promise.reject(
    //   (error.response && error.response.data) || (error.response && error.response.status) 'Something went wrong',
    //   error.response.status,
    // );
  }
);

const handleRes = async (res) => {
  try {
    if (res.data.res === 1) {
      return res.data;
    } else {
      // console.log(res);
      const error = new Error(
        (res.data && res.data.message) || "Api fetch Error"
      );
      error.info = await res.json();
      error.state = res.status;
      throw error;
    }
  } catch (e) {
    // console.log(e);
    const error = new Error(`Error api handleRes ${e}`);
    throw error;
    // No res data. key error
  }
};

const apiPost = async (url, data, config) => {
  try {
    const res = await axiosInstance.post(url, data, config);
    return handleRes(res);
  } catch (e) {
    console.log(e);
    throw e;
  }
};

const apiGet = async (url, config) => {
  try {
    const res = await axiosInstance.get(url, config);
    return handleRes(res);
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export default axiosInstance;
export { apiGet, apiPost, axiosInstance as axios };
