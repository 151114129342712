import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pages: [],
  posts: [],
  banners: [],
  loading: false,
  error: null,
  loaded: false,
  snippets: [],
};

const slice = createSlice({
  name: "blog",
  initialState,
  reducers: {
    setBlogItems: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      });
    },
  },
});

export const { setBlogItems } = slice.actions;

export const reducer = slice.reducer;

export default slice;
