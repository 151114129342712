import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import configSite from "src/coreConfig";
import axiosInstance from "src/utils/axios";

export const initialStateUC = {
  currentService: null,
  serviceFees: null,
  servicesPermissions: {
    dashboard: false,
    addService: false,
    addServiceValue: false,
    addCustomer: false,
  },
  monthlyRetentions: [],
  subscriptions: null,
  serviceTypes: null,
  serviceActivities: [],
  clientsReports: [],
  serviceSectors: [],
  currentServiceType: null,
  currentServiceInvoice: null,
  serviceCommunities: null,
  serviceCustomers: null,
  filterAddressBy: null,
  currentServiceClient: null,
};

const slice = createSlice({
  name: "services",
  initialState: initialStateUC,
  reducers: {
    setServicesPermissions: (state, action) => {
      state.servicesPermissions = action.payload;
    },
    setCoverageAddresses: (state, action) => {
      state.filterAddressBy = action.payload;
      console.log(action.payload);
    },
    setServiceTypes: (state, action) => {
      state.serviceTypes = action.payload;
      if (state.currentServiceType) {
        state.currentServiceType = _.find(action.payload, {
          id: state.currentServiceType.id,
        });
      }
    },
    setCurrentServiceType: (state, action) => {
      state.currentServiceType = action.payload;
    },
    setServicesItem: (state, action) => {
      const { name, data } = action.payload;
      state[name] = data;
    },
    setServicesState: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      });
    },
    setSubscriptions: (state, action) => {
      state.subscriptions = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export const setServicesPermissions = (data) => async (dispatch) => {
  dispatch(slice.actions.setServicesPermissions(data));
};
export const setServiceTypes = (data) => async (dispatch) => {
  dispatch(slice.actions.setServiceTypes(data));
};
export const setSubscriptions = (data) => async (dispatch) => {
  dispatch(slice.actions.setSubscriptions(data));
};
export const setServicesItem = (data) => async (dispatch) => {
  dispatch(slice.actions.setServicesItem(data));
};
export const setServicesState = (data) => async (dispatch) => {
  dispatch(slice.actions.setServicesState(data));
};

export const setCurrentServiceType = (data) => async (dispatch) => {
  dispatch(slice.actions.setCurrentServiceType(data));
};

export const getServiceActivities = (customer) => async (dispatch) => {
  const res = await axiosInstance.post(
    `${configSite.server}api/v2/service/serviceActivity/list/`,
    {
      customer_id: customer.id,
    }
  );
  if (res.data.res === 1) {
    dispatch(
      slice.actions.setServicesItem({
        name: "serviceActivities",
        data: res.data.data,
      })
    );
  }
};

export const setCoverageAddresses =
  ({ customer }) =>
    async (dispatch) => {
      if (customer.geolocalization) {
        var cA = [];
        var geolocalization = customer.geolocalization.split(":");

        switch (geolocalization[0]) {
          case "nets_geo.Municipality":
            dispatch(
              slice.actions.setCoverageAddresses({
                municipality_id: parseInt(geolocalization[1]),
              })
            );
        }
      }
    };
