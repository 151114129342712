import { lazy } from "react";
import AccountLayout from "src/views/arkadu/accounts/AcountLayout";
const CashFundDetail = lazy(
  () => import("src/views/arkadu/accounts/parts/CashFundDetail")
);
const TellerRegister = lazy(
  () => import("src/views/arkadu/accounts/parts/TellerRegister")
);
const Teller = lazy(() => import("src/views/arkadu/accounts/parts/Teller"));
const BankReportDetail = lazy(
  () => import("src/views/arkadu/accounts/payment_account/BankReportDetail")
);
const SCCashRegisterReports = lazy(
  () => import("src/views/arkadu/accounts/cash/SCCashRegisterReports")
);
const AddFundMonitorIncomeReportList = lazy(
  () =>
    import("src/views/arkadu/accounts/parts/AddFundMonitorIncomeReportList.tsx")
);
const AddFundMonitorIncomeReport = lazy(
  () => import("src/views/arkadu/accounts/parts/AddFundMonitorIncomeReport")
);

const AddFundAdmin = lazy(
  () => import("src/views/arkadu/accounts/AddFundAdmin")
);
const AccountsIndex = lazy(() => import("src/views/arkadu/accounts/index"));
const AddFundMethods = lazy(
  () => import("src/views/arkadu/accounts/AddFundMethods")
);
const BotonBanesco = lazy(
  () => import("src/views/arkadu/accounts/gateways/BotonBanesco.js")
);
const BdvPagos = lazy(
  () => import("src/views/arkadu/accounts/gateways/BdvPagos.js")
);
const X100BancoResult = lazy(
  () => import("src/views/arkadu/accounts/gateways/X100BancoResult.js")
);
const X100Banco = lazy(
  () => import("src/views/arkadu/accounts/gateways/X100Banco.js")
);
const AddFund = lazy(() => import("src/views/arkadu/accounts/AddFund"));
const UserProductDetail = lazy(
  () => import("src/views/arkadu/accounts/user_product/UserProductDetail")
);
const AccountingAdjustment = lazy(
  () => import("src/views/arkadu/accounts/AccountingAdjustment")
);
const AccountFunds = lazy(
  () => import("src/views/arkadu/accounts/AccountFunds")
);
const AccountTransferDetail = lazy(
  () => import("src/views/arkadu/accounts/AccountTransferDetail")
);
const AccountTransferAdmin = lazy(
  () => import("src/views/arkadu/accounts/AccountTransferAdmin")
);
const AddFundDetail = lazy(
  () => import("src/views/arkadu/accounts/parts/AddFundDetail")
);
const AddFundForm = lazy(() => import("src/views/forms/accounts/AddFundForm"));
const BanescoApi = lazy(
  () => import("src/views/arkadu/accounts/gateways/BanescoApi")
);
const AccountDetail = lazy(
  () => import("src/views/arkadu/accounts/AccountDetail")
);

export default [
  {
    index: true,
    element: <AccountsIndex />,
  },
  {
    element: <AccountLayout />,
    children: [
      {
        path: "addFundForm/:id/",
        element: <AddFundForm />,
      },
      {
        path: ":id/",
        element: <AccountDetail />,
      },
      {
        path: "pay/:id",
        element: <AddFundMethods />,
      },
      {
        path: "funds/:id",
        element: <AccountFunds />,
      },
    ],
  },
  {
    path: "cashFundDetail/:cashFundId/",
    component: <CashFundDetail />,
  },
  {
    path: "teller/:tellerId/",
    element: <Teller />,
  },
  {
    path: "register/:registerId/",
    element: <TellerRegister />,
  },
  {
    path: "adm/bankFundReport/detail/:id/",
    element: <BankReportDetail />,
  },
  {
    path: "adm/cash/reports/",
    element: <SCCashRegisterReports />,
  },
  {
    path: "adm/monitor/incomeReport/list/",
    element: <AddFundMonitorIncomeReportList />,
  },
  {
    path: "adm/monitor/incomeReport/",
    element: <AddFundMonitorIncomeReport />,
  },
  {
    path: "adm/:tabName/",
    element: <AddFundAdmin />,
  },
  {
    path: "adm/",
    element: <AddFundAdmin />,
  },
  {
    path: "adm/:tabName/",
    element: <AccountsIndex />,
  },

  {
    path: "banesco/:id/:transactionId",
    element: <BotonBanesco />,
  },
  {
    path: "banesco/:id/",
    element: <BotonBanesco />,
  },
  {
    path: "bdv/:id",
    element: <BdvPagos />,
  },
  {
    path: "x100banco/results/:userAccountId/:id",
    element: <X100BancoResult />,
  },
  {
    path: "x100banco/:id",
    element: <X100Banco />,
  },
  {
    path: "addFund/:id",
    element: <AddFund />,
  },
  {
    path: "userProduct/:id",
    element: <UserProductDetail />,
  },
  {
    path: "adjustment/:id",
    element: <AccountingAdjustment />,
  },

  {
    path: "transferAdmin/detail/:id",
    element: <AccountTransferDetail />,
  },
  {
    path: ":id/transfer",
    element: <AccountTransferAdmin />,
  },
  {
    path: "addFundDetail/:id",
    element: <AddFundDetail />,
  },
  {
    path: "addFund/:id/banesco/",
    element: <BanescoApi />,
  },
];
