import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import arkadu, { static_url } from "src/coreConfig";
import axios from "src/utils/axios";
import useSWR from "swr";

const sections = {
  nets_corporate: {
    title: "Tr�mites",
    icon: "clipboard",
    module_name: "nets_corporate",
    href: `/corporate/`,
    public: true,
  },
  nets_taxes: {
    title: "Hacienda",
    module_name: "nets_taxes",
    icon: "briefcaseIcon",
    href: `/taxes/`,
    public: true,
    // items: [
    //   {
    //     title: "Licencias",
    //     icon: "briefcaseIcon",
    //     href: `/taxes/licenses/`,
    //     public: true,
    //   },
    //   {
    //     title: "Reportes",
    //     icon: "briefcaseIcon",
    //     href: `/taxes/dashboard/`,
    //     public: true,
    //   },
    //   {
    //     title: "Periodos",
    //     icon: "briefcaseIcon",
    //     href: `/taxes/dashboard/periods/`,
    //     public: false,
    //   },
    //   {
    //     title: "Clasificador",
    //     icon: "briefcaseIcon",
    //     href: `/taxes/classifier/`,
    //     public: true,
    //   },
    // ],
  },
  nets_stamp: {
    title: "Timbres fiscales",
    module_name: "nets_stamp",
    icon: "photoSize",
    href: `/stamp/`,
    public: true,
  },
  nets_suppliers: {
    title: "Compras",
    module_name: "nets_suppliers",
    icon: "shoppingCart",
    href: `/suppliers/`,
    public: false,
  },
  nets_eventads: {
    title: "Publicidad y Eventos",
    icon: "ads",
    href: "/eventads/",
    public: true,
  },
  nets_customers: {
    title: "Cuenta Cliente",
    icon: "briefcaseIcon",
    href: "/customer/",
    public: false,
  },
  nets_planning: {
    title: "Planificaci�n",
    icon: "briefcaseIcon",
    href: "/planning/",
    public: false,
  },
  shopping_cart: {
    title: "pagos",
    module_name: "shopping_cart",
    icon: "shoppingCartIcon",
    href: `/accounts/`,
    public: true,
  },
  nets_translate: {
    title: "Traducciones",
    module_name: "nets_translate",
    icon: "LanguageRounded",
    href: `/accounts/`,
  },
  nets_cadastre: {
    title: "Catastro",
    module_name: "nets_cadastre",
    icon: "map",
    href: `/cadastre/`,
    public: true,
  },
  nets_support: {
    title: "Soporte",
    icon: "support",
    href: `/support/`,
    public: true,
  },
  nets_civil: {
    title: "registro civil",
    icon: "civil",
    href: `/civil/`,
    public: true,
  },
  nets_notify: {
    title: "Mensajer�a",
    icon: "email",
    href: `/notify/`,
    public: false,
  },
  nets_motor: {
    title: "Veh�culos",
    icon: "cartIcon",
    href: `/motor/`,
    public: true,
  },
  nets_urbin: {
    title: "Control Urbano",
    icon: "urbin",
    href: `/urbin/`,
    public: true,
  },
  nets_environment: {
    title: "Ambiente",
    icon: "eco",
    href: `/environment/`,
    public: true,
  },
  nets_ads: {
    title: "publicidad y propaganda",
    icon: "adsIcon",
    href: `/advertise/`,
    public: true,
  },
  nets_eco: {
    title: "Ambiente",
    icon: "eco",
    href: `/environment/`,
    public: true,
  },
  nets_bank: {
    title: "Banca",
    icon: "bank",
    href: `/bank/`,
    public: true,
  },
  urban_cleaning: {
    title: "Aseo",
    module_name: "urban_cleaning",
    icon: "localShipping",
    href: `/urban_cleaning/`,
    public: true,
  },
  nets_service: {
    title: "Servicios",
    module_name: "nets_service",
    icon: "libraryBooks",
    href: `/service/`,
    public: true,
  },
  nets_global: {
    title: "Global",
    module_name: "nets_global",
    icon: "tuneIcon",
    href: "/global/",
    public: false,
  },
  nets_poll: {
    title: "Encuestas",
    module_name: "nets_poll",
    icon: "pollIcon",
    href: "/poll/",
    public: true,
  },
  nets_dev: {
    title: "Desarrollo",
    module_name: "nets_dev",
    icon: "developerBoard",
    href: "/system/dev/",
    public: false,
  },
  nets_erp: {
    title: "ERP",
    module_name: "nets_erp",
    icon: "erp",
    href: "/erp/",
    public: false,
    items: [
      {
        title: "Bienes",
        icon: "archive",
        href: `/erp/inventory/`,
        public: false,
      },
      {
        title: "Contabilidad",
        icon: "menuBook",
        href: `/erp/accounting/`,
        public: false,
      },
      {
        title: "RRHH",
        icon: "groupWork",
        href: `/erp/staff/`,
        public: false,
      },
      {
        title: "Proyectos",
        icon: "timelapse",
        href: `/erp/project/`,
        public: false,
      },
      {
        title: "Seguridad",
        icon: "security",
        href: `/erp/security/`,
        public: false,
      },
    ],
  },
  nets_socialresp: {
    title: "Responsabilidad Social",
    module_name: "nets_socialresp",
    icon: "socialResp",
    href: "/socialresp/",
    public: true,
  },
  nets_audit: {
    title: "Fiscalizaci�n",
    module_name: "nets_audit",
    icon: "audit",
    href: "/audit/",
    public: true,
  },
};

const initialState = {
  customers: [],
  customersList: [],
  banks: [],
  onlineBankServices: {},
  countries: [],
  isModalOpen: false,
  selectedCustomerId: null,
  selectedRange: null,
  currentCustomer: null,
  currentCustomerClassifier: null,
  lastAdmin: null,
  customerError: null,
  categories: [],
  customerPermissions: {
    viewCustomer: false,
    changeCustomer: false,
    addMember: false,
    changeMember: false,
    viewMemberLogs: false,
    changeMemberRoles: false,
    addDepartment: false,
    addStaff: false,
    addSchedule: false,
  },
  currentApp: null,
  customerProcedures: [],
  customersLoaded: false,
  customerSections: [],
  currencyCode: "",
  stats: null,
  periods: null,
  periodsStats: null,
  retentionActivities: [],
  customerDoesNotExist: false,
};

const slice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    getCustomers: (state, action) => {
      const { data } = action.payload;

      state.customers = data;
      var _customerList = [];
      for (var _c in data) {
        for (var _c1 in data[_c].customers) {
          _customerList.push(data[_c].customers[_c1]);
        }
      }
      state.customersList = _customerList;
      // state.countries = data.countries ? data.countries : [];
      state.customersLoaded = true;
    },
    setCustomers: (state, action) => {
      const { data } = action.payload;

      state.customers = data;
      var _customerList = [];
      for (var _c in data) {
        for (var _c1 in data[_c].customers) {
          _customerList.push(data[_c].customers[_c1]);
        }
      }
      state.customersList = _customerList;
      // state.countries = data.countries ? data.countries : [];
      state.customersLoaded = true;
    },
    setCustomerCountries: (state, action) => {
      state.countries = action.payload;
    },
    clearCurrentCustomer: (state, action) => {
      state.currentCustomer = null;
    },
    setCustomerError: (state, action) => {
      state.customerError = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    updateCustomer: (state, action) => {
      const customer = action.payload;
      state.customers = _.map(state.customers, (_c) => {
        if (_c.id === customer.id) {
          return customer;
        }
        return _c;
      });
      state.currentCustomer =
        state.currentCustomer.id === customer.id
          ? customer
          : state.currentCustomer;
      localStorage.setItem("customers", JSON.stringify(state.customers));
    },
    // createCustomer(state, action) {
    //   const { currentCustomer } = action.payload;

    //   state.customers = [...state.customers, currentCustomer];
    // },
    selectCustomer(state, action) {
      const { customerId = null } = action.payload;

      state.isModalOpen = true;
      state.selectedCustomerId = customerId;
    },
    setCustomerPeriods(state, action) {
      state.periods = action.payload;
    },
    setCurrentCustomer(state, action) {
      const { currentCustomer } = action.payload;
      var m = currentCustomer;
      m.arkadu_modules = _.sortBy(m.arkadu_modules, ["order"]).reverse();
      state.currentCustomer = currentCustomer;

      state.customerSections = currentCustomer.arkadu_modules
        ? _.map(currentCustomer.arkadu_modules, (_m) => {
            return { ..._m, ...sections[_m.name] };
          })
        : [];

      if (currentCustomer && currentCustomer.departments) {
        state.customerProcedures = _.reject(
          currentCustomer.departments,
          (_d) => _d.procedures.length < 1
        );
      } else {
        state.customerProcedures = [];
      }
      state.customerProcedures = _.map(currentCustomer.departments, (_d) => {
        if (_d.procedures.length > 0) {
          return _d;
        }
      });
    },
    updateCurrentCustomerPeriod: (state, action) => {
      const period = action.payload;
      state.currentCustomer = {
        ...state.currentCustomer,
        periods: _.map(state.currentCustomer.periods, (_p) => {
          if (_p.id === period.id) {
            return period;
          }
          return _p;
        }),
      };
    },
    openModal(state) {
      state.isModalOpen = true;
    },
    closeModal(state) {
      state.isModalOpen = false;
      state.selectedCustomerId = null;
      state.selectedRange = null;
    },
    setStats: (state, action) => {
      const { data, periods } = action.payload;
      state.stats = data;
      state.periods = periods;
    },
    setCurrentApp: (state, action) => {
      state.currentApp = action.payload;
    },
    setCustomerByName: (state, action) => {
      const { shortname } = action.payload;
      var m = _.find(
        state.customers,
        (_customer) =>
          _customer.shortname.toLowerCase() === shortname.toLowerCase()
      );
      if (!m) {
        return state;
      }
      m.arkadu_modules = _.sortByOrder(m.arkadu_modules, ["order"], ["desc"]);
      state.customerSections = m.arkadu_modules
        ? _.map(m.arkadu_modules, (_m) => {
            return { ...sections[_m.name], ..._m };
          })
        : [];
      state.currentCustomer = m;
      if (m && m.departments) {
        state.customerProcedures = _.reject(
          m.departments,
          (_d) => _d.procedures.length < 1
        );
      } else {
        state.customerProcedures = [];
      }
    },
    setCurrentCustomerClassifier: (state, action) => {
      state.currentCustomerClassifier = action.payload;
    },
    setCustomerItem: (state, action) => {
      const { itemName, data } = action.payload;
      state[itemName] = data;
    },
    setLastAdmin: (state, action) => {
      state.lastAdmin = action.payload;
    },
    setRetentionActivities: (state, action) => {
      state.retentionActivities = action.payload;
    },
    setCustomerById: (state, action) => {
      if (state.customersList) {
        state.currentCustomer = _.find(state.customersList, {
          id: parseInt(action.payload),
        });
      }
    },
  },
});

export const reducer = slice.reducer;

export const loadCustomers = () => async (dispatch) => {
  var localCustomer = localStorage.getItem("customers");

  if (localCustomer) {
    dispatch(slice.actions.setCustomers({ data: JSON.parse(localCustomer) }));
  }
};

export const setCustomers = (data) => async (dispatch) => {
  // console.log(data);
  console.log("setCustomers data" + data);
  dispatch(slice.actions.setCustomers(data));
};
export const getCustomers = () => async (dispatch) => {
  const { data, error, isValidating } = useSWR(arkadu.urls.v2.customer.list);
  if (data) {
    console.log("getCustomers data" + data);
    dispatch(slice.actions.getCustomers(data));
  }
  // var response = await axios.get(arkadu.urls.v2.customer.list);

  // dispatch(slice.actions.getCustomers(response.data));

  // var storageCustomers = localStorage.getItem('customers');

  // if (storageCustomers) {
  //   dispatch(
  //     slice.actions.getCustomers({
  //       data: { customers: JSON.parse(storageCustomers) }
  //     })
  //   );
  // } else {
  //   try {
  //     const response = await axios.get(static_url('/src/customer.json'));
  //     console.log(response.data);
  //     localStorage.setItem('customers', response.data);
  //     dispatch(
  //       slice.actions.getCustomers({
  //         data: { customers: JSON.parse(response.data) }
  //       })
  //     );
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }
};

export const fetchCustomerFromServer = (data) => async (dispatch) => {
  try {
    var c = await axios.post(arkadu.urls.v2.customer.detail, data);
    var lastRequest = new Date();
    var cus = c.data.data;
    if (cus) {
      cus["lastRequest"] = `${lastRequest}`;
      localStorage.setItem(data.shortname, JSON.stringify(cus));
      dispatch(slice.actions.setCurrentCustomer({ currentCustomer: cus }));
    }
  } catch (err) {
    console.log(err);
    if (err.data) {
      dispatch(slice.actions.setCustomerError(err.data.message));
    }
  }
};

export const fetchCustomerJson = (data) => async (dispatch) => {
  dispatch(fetchCustomerFromServer(data));
  // var n = new Date();
  // var customerStorage = localStorage.getItem(data.shortname);
  // if (customerStorage) {
  //   try {
  //     var cusJson = JSON.parse(customerStorage);
  //     if (cusJson.lastRequest && n - cusJson.lastRequest > 60 * 1000 * 5) {
  //       dispatch(fetchCustomerFromServer(data));
  //     } else {
  //       dispatch(slice.actions.setCurrentCustomer({ currentCustomer: cusJson }));
  //     }
  //   } catch (e) {

  //     dispatch(fetchCustomerFromServer(data));
  //   }
  // } else {
  //   dispatch(fetchCustomerFromServer(data));
  // }

  var customerClassifierStorage = localStorage.getItem(
    `${data.shortname}_classifier`
  );
  if (
    customerClassifierStorage &&
    typeof customerClassifierStorage !== "undefined"
  ) {
    try {
      dispatch(
        slice.actions.setCurrentCustomerClassifier(
          JSON.parse(customerClassifierStorage)
        )
      );
    } catch (e) {
      console.log("error parsing customerClassifierStorage");
    }
  }
  // try {
  //   var jsonCustomer = await fetch(static_url(`src/customer_${data.shortname}.json`)).then(res => res.json()).then(resJson => resJson);

  //   localStorage.setItem(data.shortname, JSON.stringify(jsonCustomer));
  //   dispatch(slice.actions.setCurrentCustomer({ currentCustomer: jsonCustomer }));
  // } catch (err) {
  //   console.log('error getting customer json file', err);
  // }
  try {
    var jsonCustomerClassifier = await fetch(
      static_url(`src/customer_${data.shortname}_classifier.json`)
    )
      .then((res) => res.json())
      .then((resJson) => resJson);
    localStorage.setItem(
      `${data.shortname}_classifier`,
      JSON.stringify(jsonCustomerClassifier)
    );
    dispatch(
      slice.actions.setCurrentCustomerClassifier(jsonCustomerClassifier)
    );
  } catch (err) {
    console.log("error getting customer classifier json file");
  }
};

export const getCustomerStats = (data) => async (dispatch) => {
  var c = await axios.post(arkadu.urls.v2.customer.stats, data);
};
export const getCategories = (data) => async (dispatch) => {
  var response = await axios.get(arkadu.urls.v2.customer.categories);
  if (response.data.res === 1) {
    dispatch(slice.actions.setCategories(response.data.data));
  }
};
export const getCustomerCountries = (data) => async (dispatch) => {
  var url = arkadu.urls.site.geo_data;
  const response = await axios.get(url);
  dispatch(slice.actions.setCustomerCountries(response.data.data));
};

export const setCurrentCustomer = (data) => async (dispatch) => {
  fetchCustomerJson(data);

  dispatch(slice.actions.setCurrentCustomer({ currentCustomer: data }));
};
export const setCustomerById = (id) => async (dispatch) => {
  console.log(id);
  dispatch(slice.actions.setCustomerById(id));
};

// export const createCustomer = (data) => async (dispatch) => {
//   const response = await axios.post('/api/calendar/customers/new', data);

//   dispatch(slice.actions.createCustomer(response.data));
// };

export const updateCustomer = (data) => async (dispatch) => {
  dispatch(slice.actions.updateCustomer(data));
};
export const selectCustomer = (customerId) => async (dispatch) => {
  dispatch(slice.actions.selectCustomer({ customerId }));
};

export const updateCurrentCustomerPeriod = (data) => async (dispatch) => {
  dispatch(slice.actions.updateCurrentCustomerPeriod(data));
};

export const getStats = (data) => async (dispatch) => {
  try {
    var response = await axios.post(arkadu.urls.v2.customer.stats, data);
    console.log(response.data);
    dispatch(slice.actions.setStats(response.data));
  } catch (err) {
    console.log(err);
  }
};

export const setCustomerItem = (data) => async (dispatch) => {
  dispatch(slice.actions.setCustomerItem(data));
};

// export const updateCustomer = (customerId, update) => async (dispatch) => {
//   const response = await axios.post('/api/calendar/customers/update', {
//     customerId,
//     update
//   });

//   dispatch(slice.actions.updateCustomer(response.data));
// };

// export const deleteCustomer = (customerId) => async (dispatch) => {
//   await axios.post('/api/calendar/customers/remove', {
//     customerId
//   });

//   dispatch(slice.actions.deleteCustomer({ customerId }));
// };

// export const selectRange = (start, end) => (dispatch) => {
//   dispatch(slice.actions.selectRange({
//     start: start.getTime(),
//     end: end.getTime()
//   }));
// };

export const setCustomerByName = (data) => async (dispatch) => {
  var s = localStorage.getItem("customers");

  if (s) {
    var customers = JSON.parse(s);
    var customer = null;
    customers.map((cg) => {
      var match = cg.customers.find((c) => c.shortname === data.shortname);
      if (match) {
        customer = match;
      }
    });
    if (customer) {
      dispatch(slice.actions.setCurrentCustomer({ currentCustomer: customer }));
    } else {
      dispatch(
        slice.actions.setCustomerItem({
          itemName: "customerDoesNotExist",
          data: true,
        })
      );
    }
  }
  var b = localStorage.getItem("banks");
  if (b) {
    try {
      var banks = JSON.parse(b);
      dispatch(
        slice.actions.setCustomerItem({ itemName: "banks", data: banks })
      );
    } catch (e) {
      console.log("error parsing banks");
    }
  }
  var bankServices = localStorage.getItem("online_bank_services");
  if (bankServices) {
    try {
      var services = JSON.parse(bankServices);
      dispatch(
        slice.actions.setCustomerItem({
          itemName: "onlineBankServices",
          data: services,
        })
      );
    } catch (e) {
      console.log("error parsing bank services");
    }
  }
  // dispatch(fetchCustomerJson(data));
  // try {
  //   var fromLocalStorage = localStorage.getItem(data.shortname);
  //   if (fromLocalStorage) {
  //     var curCus = JSON.parse(fromLocalStorage);
  //     var n = new Date().getTime();
  //     var lastRequest = n - curCus.lastRequest / 1000;
  //     if (lastRequest > 600) {
  //       var response = await axios.post(arkadu.urls.v2.customer.detail, data);
  //       curCus = response.data.data;
  //       curCus.lastRequest = new Date().getTime();
  //       localStorage.setItem(data.shortname, JSON.stringify(curCus));
  //     }
  //     dispatch(
  //       slice.actions.setCurrentCustomer({
  //         currentCustomer: curCus
  //       })
  //     );
  //   } else {
  //     var response = await axios.post(arkadu.urls.v2.customer.detail, data);
  //     var curCus = response.data.data;
  //     curCus.lastRequest = new Date().getTime();
  //     localStorage.setItem(data.shortname, JSON.stringify(curCus));
  //     dispatch(
  //       slice.actions.setCurrentCustomer({
  //         currentCustomer: curCus
  //       })
  //     );
  //   }
  // } catch (err) {
  //   console.log(err);
  // }

  // var shortname = data.toLowerCase();
  // dispatch(slice.actions.setCustomerByName({ shortname: shortname }));
};
export const openModal = () => async (dispatch) => {
  dispatch(slice.actions.openModal());
};
export const clearCurrentCustomer = () => async (dispatch) => {
  dispatch(slice.actions.clearCurrentCustomer());
};

export const setCurrentApp = (data) => async (dispatch) => {
  dispatch(slice.actions.setCurrentApp(data));
};

export const closeModal = () => async (dispatch) => {
  dispatch(slice.actions.closeModal());
};

export const setLastAdmin = (data) => async (dispatch) => {
  dispatch(slice.actions.setLastAdmin(data));
};

export const setRetentionActivities = (data) => async (dispatch) => {
  dispatch(slice.actions.setRetentionActivities(data));
};
export const setCustomerPeriods = (data) => async (dispatch) => {
  dispatch(slice.actions.setCustomerPeriods(data));
};
export default slice;
