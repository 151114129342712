import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import arkadu from "src/coreConfig";
import axios from "src/utils/axios";

const initialState = {
  appointments: [],
  departments: [],
  currentCall: null,
  calls: [],
  selectedCallId: null,
  appointmentsError: null,
  loaded: false,
  appointmentsLoaded: false,
  socketOpen: false,
  nextToCall: null,
};

const slice = createSlice({
  name: "appointments",
  initialState,
  reducers: {
    addCall(state, action) {
      const { appointment } = action.payload;
      var a = { ...appointment };
      var ac = _.find(state.calls, { id: appointment.id });

      if (ac) {
        ac = { ...ac };
        ac.lastCall = Date.now();
        ac.calls += 1;
        a = ac;
      } else {
        a.lastCall = Date.now();
        a.calls = 1;
      }

      state.calls = _.unionWith([a], state.calls, (a, b) => a.id === b.id);
      state.currentCall = a;
    },
    removeCall(state, action) {
      const { callId } = action.payload;
      state.calls = _.reject(state.calls, { id: callId });
    },
    updateCall(state, action) {
      const { call } = action.payload;

      state.calls = _.map(state.calls, (_call) => {
        if (_call.id === call.id) {
          return call;
        }
        return _call;
      });
      state.currentCall = call;
    },
    reCall(state, action) {
      const { currentCall } = state;
      if (currentCall) {
        var a = { ...currentCall };
        a.calls += 1;
        a.lastCall = Date.now();
        state.currentCall = a;
      }
    },
    refreshAppointment(state, action) {
      const { appointment } = action.payload;

      state.appointments = _.map(state.appointments, (_a) => {
        if (_a.id === appointment.id) {
          return appointment;
        }
        return _a;
      });
      state.nextToCall = _.find(
        state.appointments,
        (_a) => !_a.calls && _a.state === "valid"
      );
    },
    clearCurrentCall(state, action) {
      state.currentCall = null;
    },
    getAppointments(state, action) {
      const { data } = action.payload;
      state.nextToCall = _.find(
        data.appointments,
        (_a) => !_a.calls && _a.state === "valid"
      );
      state.appointments = data.appointments;
      state.departments = data.departments;
      state.loaded = true;
      state.appointmentsLoaded = true;
    },
    setAppointmentsError(state, payload) {
      const { error } = payload;
      state.appointmentsError = error;
    },
    notifySocketState(state, action) {
      const { socketOpen } = action.payload;
      state.socketOpen = socketOpen;
    },
  },
});

export const reducer = slice.reducer;

export const addCall = (data) => async (dispatch) => {
  dispatch(slice.actions.addCall({ appointment: data.appointment }));
  if (data.postData) {
    axios.post(arkadu.urls.v2.corporate.user_appointment, data.postData);
  }
};

export const notifySocketState = (data) => async (dispatch) => {
  dispatch(slice.actions.notifySocketState(data));
};

export const refreshAppointment = (data) => async (dispatch) => {
  dispatch(slice.actions.refreshAppointment(data));
};
export const updateAppointment = (data) => async (dispatch) => {
  var response = await axios.post(
    arkadu.urls.v2.corporate.user_appointment,
    data
  );
  dispatch(slice.actions.getAppointments(response.data));
};

export const clearCurrentCall = () => async (dispatch) => {
  dispatch(slice.actions.clearCurrentCall());
};
export const reCall = () => async (dispatch) => {
  dispatch(slice.actions.reCall());
};

export const getAppointments = (data) => async (dispatch) => {
  var response = await axios.post(
    arkadu.urls.v2.corporate.user_appointment,
    data
  );

  if (response && response.data && response.data.res === 1) {
    dispatch(slice.actions.getAppointments({ data: response.data.data }));
  } else {
    dispatch(
      slice.actions.setAppointmentsError({ error: response.data.message })
    );
  }
};

export const updateCall = (data) => async (dispatch) => {
  dispatch(slice.actions.updateCall(data));
};
export default slice;
