import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  stampCategories: null,
  stamps: [],
  stampUses: [],
  loaded: false,
};

const slice = createSlice({
  name: "stamp",
  initialState: initialState,
  reducers: {
    setStampState: (state, action) => {
      Object.keys(action.payload).forEach((k) => {
        state[k] = action.payload[k];
      });
    },
  },
});

export const reducer = slice.reducer;

export const setStampState = (data) => async (dispatch) => {
  dispatch(slice.actions.setStampState(data));
};
