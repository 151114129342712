import { alpha } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    alignItems: "center",
    borderRadius: 2,
    display: "inline-flex",
    flexGrow: 0,
    whiteSpace: "nowrap",
    cursor: "default",
    flexShrink: 0,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightMedium,
    height: 20,
    justifyContent: "center",
    letterSpacing: 0.5,
    minWidth: 20,
    padding: theme.spacing(0.5, 1),
    textTransform: "uppercase",
  },
  small: {
    height: 16,
    minWidth: 16,
    padding: theme.spacing(0.25, 0.75),
    fontSize: theme.typography.pxToRem(10),
  },
  extraSmall: {
    height: 12,
    minWidth: 12,
    padding: theme.spacing(0.25, 0.5),
    fontSize: theme.typography.pxToRem(9),
  },
  medium: {
    height: 24,
    minWidth: 24,
    padding: theme.spacing(0.5, 1.5),
    fontSize: theme.typography.pxToRem(11),
  },
  large: {
    height: 32,
    minWidth: 32,
    padding: theme.spacing(0.75, 2),
    fontSize: theme.typography.pxToRem(14),
  },
  primary: {
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
  },
  secondary: {
    color: theme.palette.secondary.main,
    backgroundColor: alpha(theme.palette.secondary.main, 0.08),
  },
  error: {
    color: theme.palette.error.main,
    backgroundColor: alpha(theme.palette.error.main, 0.08),
  },
  success: {
    color: theme.palette.success.main,
    backgroundColor: alpha(theme.palette.success.main, 0.08),
  },
  warning: {
    color: theme.palette.warning.main,
    backgroundColor: alpha(theme.palette.warning.main, 0.08),
  },
  info: {
    color: theme.palette.info.main,
    backgroundColor: alpha(theme.palette.info.main, 0.08),
  },
}));

const Label = ({
  className = "",
  color = "secondary",
  size = "normal",
  children,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <span
      className={clsx(
        classes.root,
        size !== "normal" && classes[size] ? classes[size] : {},
        {
          [classes[color]]: color,
        },
        className
      )}
      {...rest}
    >
      {children}
    </span>
  );
};

Label.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  size: PropTypes.oneOf(["small", "extraSmall", "medium", "large", "normal"]),
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "error",
    "warning",
    "success",
    "info",
  ]),
};

export default Label;
