import { createSlice } from "@reduxjs/toolkit";
import arkadu from "src/coreConfig";
import axios from "src/utils/axios";
import { handleDownloadFile } from "src/utils/dataRenders";

const initialState = {
  currentDocument: null,
  isLoading: false,
  loaded: false,
};
const slice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    getDocument: (state, action) => {
      const { document } = action.payload;
    },
    setIsLoading: (state, action) => {
      const { isLoading } = action.payload;
      state.isLoading = isLoading;
    },
    setLoaded: (state, action) => {
      const { loaded } = action.payload;
      state.loaded = loaded;
    },
  },
});
export const reducer = slice.reducer;

export const downloadDocument = (data) => async (dispatch) => {
  dispatch(slice.actions.setIsLoading({ isLoading: true }));
  var formData = new FormData();
  formData.append("action", "download_document");
  Object.keys(data).map((k) => {
    formData.append(`${k}`, data[k]);
  });
  var response = await axios.post(arkadu.urls.v2.user.document.list, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    responseType: "blob",
  });
  handleDownloadFile(response);

  // const url = window.URL.createObjectURL(new Blob([response.data]));
  // const link = document.createElement('a');
  // link.href = url;
  // link.setAttribute('download', `RE-DOC-${data.document_id}.${data.extension}`);
  // document.body.appendChild(link);
  // link.click();
  dispatch(slice.actions.setIsLoading({ isLoading: false }));
};
