import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { server } from "src/coreConfig";
import { setTaxesElement } from "src/slices/taxes";
import { useDispatch, useSelector } from "src/store";
import axiosInstance from "src/utils/axios";
import { handleDownloadFile } from "src/utils/dataRenders";
import useAsyncRequest from "./useAsyncRequest";

const urls = {
  taxes: {
    config: `${server}/api/v2/taxes/config/`,
    activity: {
      classifier: `${server}/api/v2/taxes/activity/classifier/`,
      incomes: `${server}/api/v2/taxes/activity/incomes/`,
      index: `${server}/api/v2/taxes/activity/`,
    },
    declaration: {
      attachVat: `${server}/api/v2/taxes/declaration/attachVat/`,
      createAdmin: `${server}/api/v2/taxes/declaration/createAdmin/`,
      createDefinitive: `${server}/api/v2/taxes/declaration/createDefinitive/`,
      getFileTypes: `${server}/api/v2/taxes/declaration/getFileTypes/`,
      addFile: `${server}/api/v2/taxes/declaration/addFile/`,
      update: `${server}/api/v2/taxes/declaration/update/`,
      declaration: `${server}/api/v2/taxes/declaration/`,
      delete: `${server}/api/v2/taxes/declaration/delete/`,
      deleteFee: `${server}/api/v2/taxes/declaration/deleteFee/`,
      deleteFile: `${server}/api/v2/taxes/declaration/deleteFile/`,
      deleteVat: `${server}/api/v2/taxes/declaration/deleteVat/`,
      detail: `${server}/api/v2/taxes/declaration/detail/`,
      downloadVat: `${server}/api/v2/taxes/declaration/downloadVat/`,
      downloadFile: `${server}/api/v2/taxes/declaration/downloadFile/`,
      income: `${server}/api/v2/taxes/declaration/income/`,
      list: `${server}/api/v2/taxes/declaration/list/`,
      state: `${server}/api/v2/taxes/declaration/state/`,
    },
    download_report: `${server}/api/v2/taxes/report/licenseXls/`,
    generateReport: `${server}/api/v2/taxes/report/generate/`,
    deleteReport: `${server}/api/v2/taxes/report/delete/`,
    downloadReport: `${server}/api/v2/taxes/report/download/`,
    reportList: `${server}/api/v2/taxes/report/list/`,
    handle: `${server}/api/v2/taxes/handle/`,
    last_period: `${server}/api/v2/taxes/period/last/`,
    license: {
      add: `${server}/api/v2/taxes/license/add/`,
      addActivity: `${server}/api/v2/taxes/license/addActivity/`,
      addNote: `${server}/api/v2/taxes/license/addNote/`,
      addNoteFile: `${server}/api/v2/taxes/license/addNoteFile/`,
      assign: `${server}/api/v2/taxes/license/assign/`,
      change: `${server}/api/v2/taxes/license/change/`,
      changeActivity: `${server}/api/v2/taxes/license/changeActivity/`,
      changeNote: `${server}/api/v2/taxes/license/changeNote/`,
      notes: `${server}/api/v2/taxes/license/notes/`,
      detail: `${server}/api/v2/taxes/license/detail/`,
      downloadCertificate: `${server}/api/v2/cert/certificate/license/`,
      downloadList: `${server}/api/v2/taxes/license/downloadList/`,
      declarations: `${server}/api/v2/taxes/license/declarations/`,
      downloadNoteFile: `${server}/api/v2/taxes/license/downloadNoteFile/`,
      downloadPending: `${server}/api/v2/taxes/license/download/pending/`,
      feeRequest: `${server}/api/v2/taxes/license/feeRequest/`,
      feeRequestDelete: `${server}/api/v2/taxes/license/feeRequest/delete/`,
      feeRevision: `${server}/api/v2/taxes/license/feeRevision/`,
      feeRevisionDelete: `${server}/api/v2/taxes/license/feeRevision/delete/`,
      list: `${server}/api/v2/taxes/license/list/`,
      pendingPeriods: `${server}/api/v2/taxes/license/pendingPeriods/`,
      pendingDefinitive: `${server}/api/v2/taxes/license/pendingDefinitive/`,
      resetDeclarations: `${server}/api/v2/taxes/license/resetDeclarations/`,
      revision: `${server}/api/v2/taxes/license/revision/`,
      search: `${server}/api/v2/taxes/license/search/`,
      sync: `${server}/api/v2/taxes/license/sync/`,
      transfer: `${server}/api/v2/taxes/license/transfer/`,
      transferToEmail: `${server}/api/v2/taxes/license/transferToEmail/`,
      exempt: `${server}/api/v2/taxes/license/exempt/`,
      unassign: `${server}/api/v2/taxes/license/unassign/`,
      getProcedures: `${server}/api/v2/taxes/license/getProcedures/`,
      legal: {
        detail: `${server}/api/v2/taxes/license/legal/detail/`,
      },
      closed: {
        detail: `${server}/api/v2/taxes/license/closed/detail/`,
      },
    },
    penalty: {
      add: `${server}/api/v2/taxes/penalty/add/`,
      attach: `${server}/api/v2/taxes/penalty/attach/`,
      change: `${server}/api/v2/taxes/penalty/change/`,
      delete: `${server}/api/v2/taxes/penalty/delete/`,
      detail: `${server}/api/v2/taxes/penalty/detail/`,
      downloadAttach: `${server}/api/v2/taxes/penalty/downloadAttach/`,
      list: `${server}/api/v2/taxes/penalty/list/`,
    },
    period: {
      activities: `${server}/api/v2/taxes/period/activities/`,
      addFee: `${server}/api/v2/taxes/period/addFee/`,
      declarations: `${server}/api/v2/taxes/period/declarations/`,
      deleteFee: `${server}/api/v2/taxes/period/deleteFee/`,
      main: `${server}/api/v2/taxes/period/`,
      pendingLicenses: `${server}/api/v2/taxes/period/`,
      stats: `${server}/api/v2/taxes/stats/`,
    },
    retention: {
      activities: `${server}/api/v2/taxes/retention/activities/`,
      add: `${server}/api/v2/taxes/retention/add/`,
      add_statement: `${server}/api/v2/taxes/retention/statement/new/`,
      change_state: `${server}/api/v2/taxes/retention/statement/changeState/`,
      delete: `${server}/api/v2/taxes/retention/delete/`,
      loadCsv: `${server}/api/v2/taxes/retention/statement/loadCsv/`,
      statement_detail: `${server}/api/v2/taxes/retention/statement/detail/`,
      statement_list: `${server}/api/v2/taxes/retention/statement/`,
      deleteFee: `${server}/api/v2/taxes/retention/statement/deleteFee/`,
    },
  },
};

const useTaxes = () => {
  const { currentCustomer } = useSelector((state) => state.customers);
  const { currentLicense } = useSelector((state) => state.taxes);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { admin } = useSelector((state) => state.settings);
  const { api } = useAsyncRequest();
  const { t } = useTranslation();

  const getLicenseDetail = async (id) => {
    try {
      const response = await api(
        urls.taxes.license.detail,
        {
          id: id,
          customer_id: currentCustomer.id,
          mode: admin ? "admin" : "user",
          action: "retrieve",
        },
        {},
        null
      );
      if (response.data.res === 1) {
        var dependOfLicenseStates = {};
        if (currentLicense && currentLicense.id !== id) {
          dependOfLicenseStates = {
            declarations: [],
            pendingPeriods: [],
            pendingDefinitives: [],
            currentLicenseProcedures: [],
            licenseNotes: [],
          };
        }
        dispatch(
          setTaxesElement({
            currentLicense: response.data.data,
            ...dependOfLicenseStates,
          })
        );
        loadLicenseDeclarations(id, 1, 10);
        setTimeout(() => {
          loadPendingPeriods(id);
        }, 500);
        setTimeout(() => {
          getLicenseNotes(id, 1, 10);
        }, 1000);
      }
    } catch (error) {
      console.log(error);

      return null;
    }
  };

  const loadLicenseDeclarations = async (id, page, paginatedBy) => {
    try {
      const response = await api(
        urls.taxes.license.declarations,
        {
          id: id,
          page,
          page_size: paginatedBy,
          customer_id: currentCustomer.id,
          mode: admin ? "admin" : "user",
        },
        {},
        null,
        true
      );
      if (response.data.res === 1) {
        dispatch(
          setTaxesElement({
            declarations: response.data.data,
          })
        );
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const loadPendingPeriods = async (id) => {
    try {
      const response = await api(
        urls.taxes.license.pendingPeriods,
        {
          id: id,
          customer_id: currentCustomer.id,
          mode: admin ? "admin" : "user",
        },
        {},
        null,
        true
      );
      if (response.data.res === 1) {
        var pendingPeriods = response.data.data.filter(
          (period) => period.type === 1
        );
        var definitivePeriods = response.data.data.filter(
          (period) => period.type === 2
        );
        dispatch(
          setTaxesElement({
            pendingPeriods: pendingPeriods,
            pendingDefinitives: definitivePeriods,
          })
        );
      }
    } catch (error) {
      return null;
    }
  };

  const getCurrentLicenseProcedures = async () => {
    try {
      const response = await api(
        urls.taxes.license.getProcedures,
        {
          id: currentLicense.id,
          customer_id: currentCustomer.id,
          mode: admin ? "admin" : "user",
        },
        {},
        t("Procedimientos de licencia"),
        true
      );
      if (response.data.res === 1) {
        dispatch(
          setTaxesElement({
            currentLicenseProcedures: response.data.data,
          })
        );
      }
    } catch (error) {
      return null;
    }
  };

  const getLicenseNotes = async (id, page, paginatedBy) => {
    try {
      const response = await api(
        urls.taxes.license.notes,
        {
          id: id,
          customer_id: currentCustomer.id,
          page,
          page_size: paginatedBy,
          mode: admin ? "admin" : "user",
        },
        {},
        null,
        true
      );
      if (response.data.res === 1) {
        dispatch(
          setTaxesElement({
            licenseNotes: response.data.data,
          })
        );
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };
  const getTaxesConfig = async () => {
    try {
      var version = 1;
      var path = `/djstatic/${currentCustomer.shortname}/taxes_config.json`;

      var currentData = await localStorage.getItem(
        `${currentCustomer.id}_taxes_config_v`
      );
      if (currentData !== null) {
        version = parseInt(currentData) + 1;
      }
      path = `${path}?v=${version}`;
      axiosInstance
        .get(path)
        .then((res) => {
          if (typeof res.data === "string") {
            try {
              res.data = JSON.parse(res.data);
            } catch (e) {
              console.log(e, "error parsing json");
            }
          }
          console.log(res.data);
          dispatch(
            setTaxesElement({
              currentCustomerClassifier: res.data.data.activities || [],
              liquorActivities: res.data.data.liquorActivities || [],
              licenseProcedures: res.data.data.procedures || [],
              licenseCategories: res.data.data.licenseCategories || [],
              declarationFileTypes: res.data.data.declarationsFileTypes || [],
            })
          );
          localStorage.setItem(
            `${currentCustomer.id}_taxes_config_v`,
            res.data.version || 1
          );
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar(t("Error al cargar configuración de impuestos"), {
            variant: "error",
          });
        })
        .finally(() => {});
      // const response = await api(
      //   urls.taxes.config,
      //   {
      //     customer_id: currentCustomer.id,
      //     mode: admin ? "admin" : "user",
      //   },
      //   {},
      //   t("Configuración del módulo de hacienda")
      // );
      // if (response.data.res === 1) {
      //   dispatch(
      //     setTaxesElement({
      //       currentCustomerClassifier: response.data.data.activities,
      //       liquorActivities: response.data.data.liquorActivities,
      //       licenseProcedures: response.data.data.procedures,
      //       licenseCategories: response.data.data.licenseCategories,
      //       declarationFileTypes: response.data.data.declarationsFileTypes,
      //     })
      //   );
      // }
    } catch (error) {
      console.error(error);
      return null;
    }
  };
  const addLicenseNote = async (data) => {
    try {
      const response = await api(
        urls.taxes.license.addNote,
        {
          ...data,
          customer_id: currentCustomer.id,
          mode: admin ? "admin" : "user",
        },
        {},
        t("Agregando nota")
      );
      if (response.data.res === 1) {
        getLicenseNotes(currentLicense.id, 1, 10);
        return response.data.data;
      } else {
        enqueueSnackbar(response.data.message, { variant: "error" });
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  const changeLicenseNote = async (data) => {
    try {
      const response = await api(
        urls.taxes.license.changeNote,
        {
          ...data,
          customer_id: currentCustomer.id,
          mode: admin ? "admin" : "user",
        },
        {},
        t("Editando nota")
      );
      if (response.data.res === 1) {
        getLicenseNotes(currentLicense.id, 1, 10);
        return response.data.data;
      } else {
        enqueueSnackbar(response.data.message, { variant: "error" });
        return null;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const attachNoteFile = async (data) => {
    try {
      var formData = new FormData();
      formData.append("file", data.file);
      formData.append("id", data.id);
      formData.append("customer_id", currentCustomer.id);
      formData.append("mode", admin ? "admin" : "user");

      const response = await api(
        urls.taxes.license.addNoteFile,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
        t("Adjuntando archivo")
      );
      if (response.data.res === 1) {
        getLicenseNotes(currentLicense.id, 1, 10);
        return response.data.data;
      } else {
        enqueueSnackbar(response.data.message, { variant: "error" });
        return null;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const downloadNoteFile = async (id) => {
    try {
      const response = await api(
        urls.taxes.license.downloadNoteFile,
        {
          id: id,
          customer_id: currentCustomer.id,
          mode: admin ? "admin" : "user",
        },
        {
          responseType: "blob",
        },
        t("Descargando archivo")
      );
      handleDownloadFile(response);
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const createLicense = async (data) => {
    try {
      const response = await api(
        urls.taxes.license.add,
        {
          ...data,
          customer_id: currentCustomer.id,
          mode: admin ? "admin" : "user",
        },
        {},
        t("Creando licencia")
      );
      if (response.data.res === 1) {
        return response.data.data;
      } else {
        enqueueSnackbar(response.data.message, { variant: "error" });
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  return {
    getLicenseDetail,
    getTaxesConfig,
    getCurrentLicenseProcedures,
    createLicense,
    loadLicenseDeclarations,
    loadPendingPeriods,
    getLicenseNotes,
    notes: {
      add: addLicenseNote,
      change: changeLicenseNote,
      attachFile: attachNoteFile,
      downloadFile: downloadNoteFile,
    },
  };
};

export default useTaxes;
