import { AppBar, Box, colors, Divider, Link, Toolbar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import Logo from "src/components/Logo";
import { useSelector } from "src/store";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.default,
    //backgroundColor: colors.common.white
  },
  toolbar: {
    height: 64,
  },
  logo: {
    marginRight: theme.spacing(2),
    height: "30px !important",
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  adminMode: {
    backgroundColor: `${theme.palette.adminBar.main} !important`,
    color: colors.common.white,
  },
}));

const TopBar = ({ className, ...rest }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { adminMode } = useSelector((state) => state.settings);
  const { currentCustomer } = useSelector((state) => state.customers);
  let appBarClasses = clsx(classes.root, className);
  if (adminMode) {
    appBarClasses = clsx(appBarClasses, classes.adminMode);
  }
  return (
    <AppBar className={appBarClasses} {...rest}>
      <Toolbar className={classes.toolbar}>
        <RouterLink
          to={currentCustomer ? `/${currentCustomer.shortname}` : `/`}
        >
          <Logo className={classes.logo} />{" "}
        </RouterLink>
        <Box flexGrow={1} />
        {/* <AndroidApp /> */}
        <Link
          className={classes.link}
          color={adminMode ? "secondary" : "inherit"}
          component={RouterLink}
          to="/app"
          underline="none"
          variant="body2"
        >
          {t("Panel")}{" "}
        </Link>{" "}
        <Divider className={classes.divider} />{" "}
      </Toolbar>{" "}
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};

export default TopBar;
