import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentAd: null,
  currentAdRequest: null,
  isLoading: false,
  loaded: false,
  adsCompany: null,
  adsStore: null,
};
const slice = createSlice({
  name: "eventads",
  initialState,
  reducers: {
    setCurrentAd: (state, action) => {
      state.currentAd = action.payload;
    },
    setLoaded: (state, action) => {
      const { loaded } = action.payload;
      state.loaded = loaded;
    },
    setAdsCompany: (state, action) => {
      state.adsCompany = action.payload;
    },
    setAdRequest: (state, action) => {
      state.currentAdRequest = action.payload;
    },
    setEventadsState: (state, action) => {
      Object.keys(action.payload).map((k) => {
        if (Object.keys(initialState).includes(k)) {
          state[k] = action.payload[k];
        } else {
          console.error(
            `eventads does not have key ${k}, check setEventadsState call`
          );
        }
      });
    },
  },
});
export const reducer = slice.reducer;

export const setCurrentAd = (data) => async (dispatch) => {
  dispatch(slice.actions.setCurrentAd(data));
};
export const setAdsCompany = (data) => async (dispatch) => {
  dispatch(slice.actions.setAdsCompany(data));
};
export const setCurrentAdRequest = (data) => async (dispatch) => {
  dispatch(slice.actions.setAdRequest(data));
};
export const setEventadsState = (data) => async (dispatch) => {
  dispatch(slice.actions.setEventadsState(data));
};
