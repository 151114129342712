import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Navigate, Outlet, useNavigate, useParams } from "react-router";
import { setCustomerByName } from "src/slices/customers";
import {
  getArkaduSessionFromToken,
  loadUserFromStorage,
} from "src/slices/users";
import { useDispatch, useSelector } from "src/store";
import LoadingScreen from "./LoadingScreen";

const GuestGuard = ({ children }) => {
  const { isAuthenticated, loaded, notifyMessage } = useSelector(
    (state) => state.users
  );
  const { customers, currentCustomer } = useSelector(
    (state) => state.customers
  );
  let navigate = useNavigate();

  const { customerName } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!loaded) {
      dispatch(loadUserFromStorage());
    }
  }, [loaded]);

  useEffect(() => {
    if (
      (!currentCustomer && customerName && customers) ||
      (customerName &&
        currentCustomer &&
        currentCustomer.shortname !== customerName)
    ) {
      dispatch(setCustomerByName({ shortname: customerName }));
    }
  }, [customerName, customers, currentCustomer]);

  useEffect(() => {
    if (notifyMessage) {
      enqueueSnackbar(notifyMessage.message, {
        variant: notifyMessage.variant,
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom",
        },
      });
    }
  }, [notifyMessage]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getArkaduSessionFromToken());
      var path = customerName ? `/${customerName}/` : "/app/";
      var next_url = localStorage.getItem("next");
      console.log(next_url);
      localStorage.removeItem("next");
      navigate(next_url || path);
    }
  }, [isAuthenticated]);

  if (!loaded) {
    return <LoadingScreen message="loading" />;
  }
  if (isAuthenticated) {
    return <Navigate to={customerName ? `/${customerName}/` : "/app/"} />;
  }

  return (
    <>
      <Outlet />
    </>
  );
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;
