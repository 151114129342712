import { combineReducers } from "@reduxjs/toolkit";
import { reducer as aiReducer } from "src/slices/ai";
import { reducer as appointmentsReducer } from "src/slices/appointments";
import { reducer as auditReducer } from "src/slices/audit";
import { reducer as bankReducer } from "src/slices/bank";
import { reducer as blogReducer } from "src/slices/blog";
import { reducer as cadasterReducer } from "src/slices/cadaster";
import { reducer as calendarReducer } from "src/slices/calendar";
import { reducer as certificateReducer } from "src/slices/certificate";
import { reducer as customersReducer } from "src/slices/customers";
import { reducer as documentsReducer } from "src/slices/documents";
import { reducer as environmentReducer } from "src/slices/environment";
import { reducer as eventAdsReducer } from "src/slices/eventads";
import { reducer as genericReducer } from "src/slices/generics";
import { reducer as geoReducer } from "src/slices/geo";
import { reducer as globalReducer } from "src/slices/global";
import { reducer as helpReducer } from "src/slices/help";
import { reducer as motorReducer } from "src/slices/motor";
import { reducer as devReducer } from "src/slices/nets_dev";
import { reducer as notificationReducer } from "src/slices/notification";
import { reducer as planningReducer } from "src/slices/planning";
import { reducer as pollReducer } from "src/slices/polls";
import { reducer as premiumReducer } from "src/slices/premium";
import { reducer as proceduresReducer } from "src/slices/procedures";
import { reducer as serviceReducer } from "src/slices/services";
import { reducer as settingsReducer } from "src/slices/settings";
import { reducer as shopReducer } from "src/slices/shop";
import { reducer as socialResponsibilityReducer } from "src/slices/social_responsibility";
import { reducer as stampReducer } from "src/slices/stamp";
import { reducer as suppliersReducer } from "src/slices/suppliers";
import { reducer as supportReducer } from "src/slices/support";
import { reducer as taxesReducer } from "src/slices/taxes";
import { reducer as urbanCleaningReducer } from "src/slices/urban_cleaning";
import { reducer as urbinReducer } from "src/slices/urbin";
import { reducer as usersReducer } from "src/slices/users";

const rootReducer = combineReducers({
  appointments: appointmentsReducer,
  procedures: proceduresReducer,
  calendar: calendarReducer,
  documents: documentsReducer,
  users: usersReducer,
  taxes: taxesReducer,
  customers: customersReducer,
  settings: settingsReducer,
  support: supportReducer,
  cadaster: cadasterReducer,
  motor: motorReducer,
  geo: geoReducer,
  help: helpReducer,
  certificate: certificateReducer,
  notifications: notificationReducer,
  shop: shopReducer,
  bank: bankReducer,
  urban_cleaning: urbanCleaningReducer,
  global: globalReducer,
  services: serviceReducer,
  eventads: eventAdsReducer,
  generics: genericReducer,
  urbin: urbinReducer,
  polls: pollReducer,
  stamp: stampReducer,
  suppliers: suppliersReducer,
  planning: planningReducer,
  dev: devReducer,
  premium: premiumReducer,
  environment: environmentReducer,
  blog: blogReducer,
  socialResponsibility: socialResponsibilityReducer,
  audit: auditReducer,
  ai: aiReducer,
});

export default rootReducer;
