import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Link,
  Slide,
  Toolbar,
  Typography,
  useScrollTrigger,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router";
import LoadingScreen from "src/components/LoadingScreen";
import Logo from "src/components/Logo";
import useBlog from "src/hooks/useBlog";
import { getCustomers, setCustomerById } from "src/slices/customers";
import { useDispatch, useSelector } from "src/store";

const BlogLayout = ({ children }) => {
  const { t } = useTranslation();
  var configSite = window["configSite"];
  var customerId = window["customer_id"];
  const { currentCustomer, customers } = useSelector(
    (state) => state.customers
  );
  const { loaded, loading } = useSelector((state) => state.blog);
  const dispatch = useDispatch();
  const { getMainBlog } = useBlog();
  const initialized = React.useRef(false);

  function HideOnScroll({ children }) {
    const trigger = useScrollTrigger();
    console.log("HideOnScroll", trigger);
    return (
      <Slide appear={false} direction="down" in={!trigger}>
        {children}
      </Slide>
    );
  }
  React.useEffect(() => {
    if (!customers) {
      dispatch(getCustomers());
    }
  }, [customers]);

  React.useEffect(() => {
    if (customers && customers.length > 0 && customerId && !currentCustomer) {
      dispatch(setCustomerById(customerId));
    }
  }, [customers, customerId, currentCustomer]);

  React.useEffect(() => {
    if (currentCustomer && !loaded && !loading) {
      getMainBlog();
    }
  }, [loaded, loading, currentCustomer]);

  if (!loaded || !currentCustomer || !customers)
    return <LoadingScreen message={`Loading home page`} />;
  return (
    (<Box sx={{ flexGrow: 1 }}>
      <HideOnScroll>
        <AppBar>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              size="large">
              <MenuIcon />
            </IconButton>
            <Logo />
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
      <Container>
        <Outlet />
      </Container>
      {/* footer  */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 150,
          backgroundColor: "primary.main",
          color: "white",
          padding: 2,
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Typography>
            {"© "} {new Date().getFullYear()} {currentCustomer.name}
          </Typography>
          <Typography>
            {t("Desarrollado por")}{" "}
            <Link
              href={"https://arkadu.com"}
              target="_blank"
              sx={{ color: "white" }}
            >
              Arkadu.com
            </Link>
          </Typography>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          {/* Terminos y condiciones link to /terms */}
          <Link to="/terms" sx={{ color: "white" }}>
            <Typography>{t("Términos y condiciones")}</Typography>
          </Link>
          {/* Politica de privacidad a /privacy */}
          <Link to="/privacy" sx={{ color: "white" }}>
            <Typography>{t("Política de privacidad")}</Typography>
          </Link>
        </Box>
      </Box>
    </Box>)
  );
};

export default BlogLayout;
