import {
  AccountBoxRounded,
  LockOpenRounded,
  LogoutRounded,
  Person,
} from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useSnackbar } from "notistack";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import arkadu, { dbName } from "src/coreConfig";
import { logout, setAuthorized, setUserState } from "src/slices/users";
import { useDispatch, useSelector } from "src/store";
import axiosInstance from "src/utils/axios";

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
  },
  popover: {
    width: 200,
  },
}));

const Account = () => {
  const classes = useStyles();
  let navigate = useNavigate();
  const ref = useRef(null);
  const { t } = useTranslation();
  const { user, authorized, selectedIdentity } = useSelector(
    (state) => state.users
  );
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);
  const { currentCustomer } = useSelector((state) => state.customers);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    handleClose();
    localStorage.clear();
    await indexedDB.deleteDatabase(dbName);

    await dispatch(logout());
    var loginUrl = "";
    if (currentCustomer && currentCustomer.shortname) {
      loginUrl = `/${currentCustomer.shortname}`;
    }
    loginUrl += "/login";

    try {
      await axiosInstance.get(arkadu.urls.v2.user.logout);
    } catch (err) {
      console.log(err);
    } finally {
      navigate(loginUrl);
    }
  };

  if (!user) {
    return null;
  }

  return (
    <>
      <Button
        id="top-bar-account-menu"
        aria-controls={isOpen ? "top-bar-account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? "true" : undefined}
        onClick={handleOpen}
        ref={ref}
      >
        <Badge
          badgeContent={
            authorized ? (
              <LockOpenRounded size="small" sx={{ height: 16, width: 16 }} />
            ) : (
              0
            )
          }
          color="success"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <>
            <Avatar alt="User" className={classes.avatar} src={user.avatar} />
            <Typography
              variant="h6"
              color="inherit"
              sx={{
                display: { xl: "block", sm: "none" },
              }}
            >
              {user.name}
            </Typography>
          </>
        </Badge>
      </Button>
      <Menu
        id="top-bar-account-menu"
        aria-labelledby="top-bar-account-menu"
        anchorEl={ref.current}
        open={isOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {selectedIdentity && (
          <MenuItem
            onClick={() => {
              dispatch(setUserState({ identitySelection: true }));
            }}
            sx={{
              maxWidth: 200,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <ListItemIcon>
              <AccountBoxRounded fontSize="small" color="info" />
            </ListItemIcon>
            <ListItemText
              secondary={selectedIdentity.short_id}
              primary={selectedIdentity.legal_name}
            />
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            navigate("/app/account");
          }}
        >
          <ListItemIcon>
            <Person fontSize="small" color="info" />
          </ListItemIcon>
          <ListItemText primary={t("Cuenta")} />
        </MenuItem>
        {authorized && (
          <MenuItem
            onClick={() => {
              dispatch(setAuthorized(false));
            }}
          >
            <ListItemIcon>
              <LockOpenRounded fontSize="small" color="success" />
            </ListItemIcon>
            <ListItemText primary={t("Bloquear")} />
          </MenuItem>
        )}
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutRounded fontSize="small" color="primary" />
          </ListItemIcon>
          <ListItemText primary={t("Cerrar sesi�n")} />
        </MenuItem>
      </Menu>
      {/* <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Badge
          badgeContent={
            authorized ? (
              <LockOpenRounded size="small" sx={{ height: 16, width: 16 }} />
            ) : (
              0
            )
          }
          color="success"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <>
            <Avatar alt="User" className={classes.avatar} src={user.avatar} />

            <Typography
              variant="h6"
              color="inherit"
              sx={{
                display: { xl: "block", sm: "none" },
              }}
            >
              {user.name}
            </Typography>
          </>
        </Badge>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        // getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem
          onClick={() => {
            navigate("/app/account");
          }}
        >
          <ListItemIcon size="small">
            <Person color="info" />
          </ListItemIcon>
          <ListItemText>{t("Cuenta")}</ListItemText>
        </MenuItem>
        {authorized && (
          <MenuItem
            onClick={() => {
              dispatch(setAuthorized(false));
            }}
          >
            <ListItemIcon size="small">
              <LockOpenRounded color="info" />
            </ListItemIcon>
            <ListItemText>{t("Bloquear")}</ListItemText>
          </MenuItem>
        )}
        <MenuItem onClick={handleLogout}>
          <ListItemIcon size="small">
            <LogoutRounded color="primary" />
          </ListItemIcon>

          <ListItemText>{t("Cerrar sesi�n")}</ListItemText>
        </MenuItem>
      </Menu> */}
    </>
  );
};

export default Account;
