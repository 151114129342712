import React, { lazy } from "react";
import DashboardLayout from "src/layouts/DashboardLayout";
import MainLayout from "src/layouts/MainLayout";

const NotFoundView = lazy(() => import("src/views/errors/NotFoundView"));
const LoginView = lazy(() => import("src/views/auth/LoginView/index"));
const MailView = lazy(() => import("src/views/mail/MailView/index"));
const CertificateView = lazy(
  () => import("src/views/arkadu/certificate/index")
);
const AppointmentsCallMonitor = lazy(
  () =>
    import("src/views/arkadu/corporate/appointments/AppointmentsCallMonitor")
);
const AdminDashBoard = lazy(
  () => import("src/views/arkadu/dashboard/AdminDashBoard")
);
const TransferResult = lazy(
  () => import("src/views/arkadu/bank/TransferResult")
);
const SameUserTransfer = lazy(
  () => import("src/views/arkadu/bank/SameUserTransfer")
);
const BankProducts = lazy(() => import("src/views/arkadu/bank/BankProducts"));
const BankServiceAdmin = lazy(
  () => import("src/views/arkadu/bank/BankServiceAdmin")
);
const BankIndex = lazy(() => import("src/views/arkadu/bank/BankIndex"));
const SimpleRegistrationForm = lazy(
  () => import("src/views/arkadu/user/SimpleRegistrationForm")
);
const CustomerIndex = lazy(
  () => import("src/views/arkadu/module/CustomerIndex")
);
const DeclarationDetail = lazy(
  () => import("src/views/arkadu/taxes/DeclarationDetail")
);
const LicenseDetailView = lazy(
  () => import("src/views/arkadu/taxes/license/parts/LicenseDetailView")
);
const CertificateDetailsView = lazy(
  () => import("src/views/arkadu/taxes/CertificateDetailsView/index")
);
const NewPost = lazy(() => import("src/views/arkadu/module/Timeline/NewPost"));
const CustomerSettingsForm = lazy(
  () => import("src/views/arkadu/customer/forms/CustomerSettingsForm")
);
const SupportIndex = lazy(
  () => import("src/views/arkadu/support/SupportIndex")
);
const NewTicketForm = lazy(
  () => import("src/views/arkadu/support/NewTicketForm")
);
const TicketDetail = lazy(
  () => import("src/views/arkadu/support/TicketDetail")
);
const AdvertiseIndex = lazy(() => import("src/views/arkadu/advertise/index"));
const CivilIndex = lazy(() => import("src/views/arkadu/civil/index"));
const UrbanIndex = lazy(() => import("src/views/arkadu/urban/index"));
const StaffAdmin = lazy(
  () => import("src/views/arkadu/admin/corporate/parts/StaffAdmin")
);
const CorporateIndexView = lazy(
  () => import("src/views/arkadu/admin/corporate/CorporateIndexView")
);
const FixMotorIndex = lazy(
  () => import("src/views/arkadu/motor/FixMotorIndex")
);
const VehicleMotorDetail = lazy(
  () => import("src/views/arkadu/motor/VehicleMotorDetail")
);
const TransSetView = lazy(() => import("src/views/arkadu/trans/TransSetView"));
const TransIndex = lazy(() => import("src/views/arkadu/trans/index"));
const AddPeriod = lazy(
  () => import("src/views/arkadu/taxes/admin/period/AddPeriod")
);
const AddPeriodFee = lazy(
  () => import("src/views/arkadu/taxes/admin/period/AddPeriodFee")
);
const PeriodView = lazy(
  () => import("src/views/arkadu/taxes/admin/period/PeriodView")
);
const TaxesAdminIndex = lazy(
  () => import("src/views/arkadu/taxes/admin/index")
);
const AddFundAdmin = lazy(
  () => import("src/views/arkadu/accounts/AddFundAdmin")
);
const AddFundAdminStepper = lazy(
  () => import("src/views/arkadu/user/admin/components/AddFundAdminStepper")
);
const NewNotifyMessage = lazy(
  () => import("src/views/arkadu/notify/NewNotifyMessage")
);
const UserAdmin = lazy(() => import("src/views/arkadu/user/admin/UserAdmin"));
const CadasterAdminIndex = lazy(
  () => import("src/views/arkadu/cadaster/admin/index")
);
const DashboardAlternativeView = lazy(
  () => import("src/views/reports/DashboardAlternativeView/index")
);
const BlogIndex = lazy(() => import("src/views/arkadu/blog/BlogIndex"));

const public_routes = [
  {
    path: "404",
    element: <NotFoundView />,
  },
  {
    path: ":customerName?/login",
    element: <LoginView />,
  },
  {
    path: "certificate/validation/:id/",
    element: <CertificateView />,
  },
  {
    path: "certificate/:id/",
    element: <CertificateView />,
  },
  // {
  //   path: "",
  //   element: <BlogIndex />,
  // },
];

const customer_routes = [
  {
    path: ":customerName",
    // we need enclose mainLayout children and DashboardLayout children
    children: [
      {
        element: <MainLayout />,
        children: [
          {
            index: true,
            element: <CustomerIndex />,
          },
          {
            path: "AdminDashboard/:tabName?",
            element: <AdminDashBoard />,
          },
          {
            path: "monitor/*",
            element: <AppointmentsCallMonitor />,
          },
        ],
      },
      {
        element: <DashboardLayout />,
        children: [
          // user registration
          {
            path: "userRegistration/",
            element: <SimpleRegistrationForm />,
          },
          // bank
          {
            path: "bank/",
            children: [
              {
                index: true,
                element: <BankIndex />,
              },
              {
                path: "transfer/result/:id/",
                element: <TransferResult />,
              },
              {
                path: "transfer/same/",
                element: <SameUserTransfer />,
              },
              {
                path: ":serviceId/products/",
                element: <BankProducts />,
              },
              {
                path: "admin/:serviceId/:environmentId/",
                element: <BankServiceAdmin />,
              },
              {
                path: "admin/:serviceId/",
                element: <BankServiceAdmin />,
              },
            ],
          },
        ],
      },
    ],
  },
];

export { customer_routes, public_routes };

// const routes = [
//   {
//     exact: true,
//     path: "/404",
//     lazy: () => import("src/views/errors/NotFoundView"),
//   },
//   {
//     exact: true,
//     guard: GuestGuard,
//     path: ["/:customerName/login", "/login"],
//     lazy: () => import("src/views/auth/LoginView/index"),
//   },
//   {
//     exact: true,
//     path: "/email",
//     layout: DashboardLayout,
//     lazy: () => import("src/views/mail/MailView/index"),
//   },
//   {
//     exact: true,
//     path: "/certificate/validation/:id/",
//     lazy: () => import("src/views/arkadu/certificate/index"),
//   },
//   {
//     path: "/app",
//     guard: AuthGuard,
//     layout: DashboardLayout,
//     routes: app_routes,
//   },
//   {
//     path: "/subscriptions",
//     guard: AuthGuard,
//     layout: DashboardLayout,
//     routes: stripe_routes,
//   },
//   {
//     path: "*",
//     routes: [
//       {
//         path: "/:customerName/monitor/*",
//         layout: MainLayout,
//         guard: AuthGuard,
//         routes: [
//           {
//             exact: true,
//             path: "/:customerName/monitor/appointments/",
//             lazy: () =>
//               import(
//                 "src/views/arkadu/corporate/appointments/AppointmentsCallMonitor"
//               ),
//           },
//         ],
//       },
//       {
//         layout: MainLayout,
//         guard: AuthGuard,
//         exact: true,
//         path: [
//           "/:customerName/AdminDashboard/:tabName",
//           "/:customerName/AdminDashboard",
//         ],
//         lazy: () => import("src/views/arkadu/dashboard/AdminDashBoard"),
//       },
//       {
//         path: "/:customerName/",
//         layout: DashboardLayout,
//         routes: [
//           {
//             guard: AuthGuard,
//             path: "/:customerName/audit/*",
//             routes: audit_routes,
//           },
//           {
//             path: "/:customerName/login",
//             guard: GuestGuard,
//             lazy: () => import("src/views/auth/LoginView/index"),
//           },
//           {
//             path: "/:customerName/bank/",
//             layout: BankLayout,
//             guard: AuthGuard,
//             routes: [
//               {
//                 exact: true,
//                 path: "/:customerName/bank/transfer/result/:id/",
//                 lazy: () => import("src/views/arkadu/bank/TransferResult"),
//               },
//               {
//                 exact: true,
//                 path: "/:customerName/bank/transfer/same/",
//                 lazy: () => import("src/views/arkadu/bank/SameUserTransfer"),
//               },
//               {
//                 exact: true,
//                 path: "/:customerName/bank/:serviceId/products/",
//                 lazy: () => import("src/views/arkadu/bank/BankProducts"),
//               },
//               {
//                 exact: true,
//                 path: [
//                   "/:customerName/bank/admin/:serviceId/:environmentId/",
//                   "/:customerName/bank/admin/:serviceId/",
//                 ],
//                 lazy: () => import("src/views/arkadu/bank/BankServiceAdmin"),
//               },
//               {
//                 exact: true,
//                 path: "/:customerName/bank/",
//                 lazy: () => import("src/views/arkadu/bank/BankIndex"),
//               },
//             ],
//           },
//           {
//             path: "/:customerName/userRegistration/",
//             guard: AuthGuard,
//             lazy: () => import("src/views/arkadu/user/SimpleRegistrationForm"),
//           },
//           {
//             path: "/:customerName/taxes/*",
//             layout: TaxesLayout,
//             guard: AuthGuard,
//             routes: taxes_routes,
//           },
//           {
//             path: "/:customerName/service/",
//             guard: AuthGuard,
//             layout: ServicesLayout,
//             routes: service_routes,
//           },
//           {
//             path: "/:customerName/system/dev/",
//             guard: AuthGuard,
//             layout: DevLayout,
//             routes: dev_routes,
//           },
//           {
//             path: "/:customerName/notify/",
//             guard: AuthGuard,
//             routes: notify_routes,
//           },
//           {
//             path: "/:customerName/global/",
//             layout: GlobalLayout,
//             guard: AuthGuard,
//             routes: [
//               {
//                 exact: true,
//                 path: ["/:customerName/global/"],
//                 lazy: () => import("src/views/arkadu/global/GlobalIndex"),
//               },
//             ],
//           },
//           {
//             path: "/:customerName/customer/",
//             layout: CustomerLayout,
//             guard: AuthGuard,
//             routes: [
//               {
//                 exact: true,
//                 path: ["/:customerName/customer/"],
//                 lazy: () => import("src/views/arkadu/customer/CustomerIndex"),
//               },
//             ],
//           },
//           {
//             path: "/:customerName/erp",
//             guard: AuthGuard,
//             routes: erp_routes,
//           },
//           {
//             path: "/:customerName/socialresp",
//             guard: AuthGuard,
//             routes: socialresp_routes,
//           },
//           {
//             path: "/:customerName/eventads",
//             guard: AuthGuard,
//             layout: EventadsLayout,
//             routes: eventads_routes,
//           },
//           {
//             path: "/:customerName/corporate/",
//             guard: AuthGuard,
//             layout: CorporateLayout,
//             routes: corporate_routes,
//           },
//           {
//             exact: true,
//             path: "/:customerName/config",
//             lazy: () =>
//               import("src/views/arkadu/customer/forms/CustomerSettingsForm"),
//           },
//           {
//             exact: true,
//             guard: AuthGuard,
//             path: "/:customerName/",
//             lazy: () => import("src/views/arkadu/module/CustomerIndex"),
//           },
//           {
//             exact: true,
//             guard: AuthGuard,
//             path: "/:customerName/posts/new/",
//             lazy: () => import("src/views/arkadu/module/Timeline/NewPost"),
//           },
//           {
//             exact: true,
//             guard: AuthGuard,
//             path: "/:customerName/taxes/declaration/:declarationId/",
//             lazy: () => import("src/views/arkadu/taxes/DeclarationDetail"),
//           },
//           {
//             exact: true,
//             guard: AuthGuard,
//             path: "/:customerName/taxes/license/:licenseId",
//             lazy: () =>
//               import("src/views/arkadu/taxes/license/parts/LicenseDetailView"),
//           },
//           {
//             exact: true,
//             guard: AuthGuard,
//             path: "/:customerName/taxes/declaration/:id/certificate/",
//             lazy: () =>
//               import("src/views/arkadu/taxes/CertificateDetailsView/index"),
//           },
//           {
//             path: "/:customerName/accounts",
//             guard: AuthGuard,
//             layout: ShopLayout,
//             routes: account_routes,
//           },
//           {
//             path: "/:customerName/poll",
//             guard: AuthGuard,
//             layout: PollLayout,
//             routes: poll_routes,
//           },
//           {
//             path: "/:customerName/cadaster/",
//             guard: AuthGuard,
//             layout: CadasterLayout,
//             routes: cadaster_routes,
//           },
//           {
//             path: "/:customerName/urbin/",
//             layout: UrbinLayout,
//             guard: AuthGuard,
//             routes: urbin_routes,
//           },
//           {
//             path: "/:customerName/environment/",
//             guard: AuthGuard,
//             layout: EnvironmentLayout,
//             routes: environment_routes,
//           },
//           {
//             guard: AuthGuard,
//             layout: StampLayout,
//             path: ["/:customerName/stamp/"],
//             routes: stamp_routes,
//           },
//           {
//             path: "/:customerName/suppliers/",
//             guard: AuthGuard,
//             layout: SuppliersLayout,
//             routes: suppliers_routes,
//           },
//           {
//             path: "/:customerName/planning/",
//             guard: AuthGuard,
//             layout: PlanningLayout,
//             routes: planning_routes,
//           },
//           {
//             exact: true,
//             guard: AuthGuard,
//             path: "/:customerName/support/ticket/new/",
//             lazy: () => import("src/views/arkadu/support/NewTicketForm"),
//           },
//           {
//             exact: true,
//             guard: AuthGuard,
//             path: "/:customerName/support/ticket/detail/:id",
//             lazy: () => import("src/views/arkadu/support/TicketDetail"),
//           },
//           {
//             exact: true,
//             guard: AuthGuard,
//             path: "/:customerName/support/",
//             lazy: () => import("src/views/arkadu/support/SupportIndex"),
//           },
//           {
//             exact: true,
//             guard: AuthGuard,
//             path: "/:customerName/advertise/",
//             lazy: () => import("src/views/arkadu/advertise/index"),
//           },
//           {
//             exact: true,
//             guard: AuthGuard,
//             path: "/:customerName/civil/",
//             lazy: () => import("src/views/arkadu/civil/index"),
//           },
//           {
//             exact: true,
//             guard: AuthGuard,
//             path: "/:customerName/urban/",
//             lazy: () => import("src/views/arkadu/urban/index"),
//           },
//           {
//             exact: true,
//             guard: AuthGuard,
//             path: "/:customerName/corporateAdmin/users/",
//             lazy: () =>
//               import("src/views/arkadu/admin/corporate/parts/StaffAdmin"),
//           },
//           {
//             exact: true,
//             guard: AuthGuard,
//             path: "/:customerName/corporateAdmin/",
//             lazy: () =>
//               import("src/views/arkadu/admin/corporate/CorporateIndexView"),
//           },
//           {
//             exact: true,
//             guard: AuthGuard,
//             layout: VehicleMotorLayout,
//             path: "/:customerName/motor/fix/",
//             lazy: () => import("src/views/arkadu/motor/FixMotorIndex"),
//           },
//           {
//             exact: true,
//             guard: AuthGuard,
//             layout: VehicleMotorLayout,
//             path: [
//               "/:customerName/motor/inscription",
//               "/:customerName/motor/newVehicle",
//               "/:customerName/motor/:tabName",
//               "/:customerName/motor/",
//             ],
//             lazy: () => import("src/views/arkadu/motor/index"),
//           },
//           {
//             exact: true,
//             guard: AuthGuard,
//             layout: VehicleMotorLayout,
//             path: [
//               "/:customerName/motor/vehicle/statement/:vehicleId",
//               "/:customerName/motor/vehicle/:inscriptionId",
//             ],
//             lazy: () => import("src/views/arkadu/motor/VehicleMotorDetail"),
//           },
//           {
//             exact: true,
//             guard: AuthGuard,
//             path: "/:customerName/translate/:id",
//             lazy: () => import("src/views/arkadu/trans/TransSetView"),
//           },
//           {
//             exact: true,
//             guard: AuthGuard,
//             path: "/:customerName/translate/",
//             lazy: () => import("src/views/arkadu/trans/index"),
//           },
//           {
//             path: "/:customerName/admin/*",
//             routes: [
//               {
//                 exact: true,
//                 path: "/:customerName/admin/taxes/declaration/:id",
//                 guard: AuthGuard,
//                 lazy: () => import("src/views/arkadu/taxes/DeclarationDetail"),
//               },
//               {
//                 exact: true,
//                 path: "/:customerName/admin/taxes/period/add/",
//                 guard: AuthGuard,
//                 lazy: () =>
//                   import("src/views/arkadu/taxes/admin/period/AddPeriod"),
//               },
//               {
//                 exact: true,
//                 path: "/:customerName/admin/taxes/period/:id/addFee/",
//                 guard: AuthGuard,
//                 lazy: () =>
//                   import("src/views/arkadu/taxes/admin/period/AddPeriodFee"),
//               },
//               {
//                 exact: true,
//                 path: "/:customerName/admin/taxes/period/:id/",
//                 guard: AuthGuard,
//                 lazy: () =>
//                   import("src/views/arkadu/taxes/admin/period/PeriodView"),
//               },
//               {
//                 exact: true,
//                 path: "/:customerName/admin/taxes/:tabName",
//                 guard: AuthGuard,
//                 lazy: () => import("src/views/arkadu/taxes/admin/index"),
//               },
//               {
//                 exact: true,
//                 path: "/:customerName/admin/taxes/",
//                 guard: AuthGuard,
//                 lazy: () => import("src/views/arkadu/taxes/admin/index"),
//               },
//               {
//                 exact: true,
//                 guard: AuthGuard,
//                 path: [
//                   "/:customerName/admin/support/:userId/ticket/:ticketId",
//                   "/:customerName/admin/support/:userId",
//                   "/:customerName/admin/support/",
//                 ],
//                 lazy: () => import("src/views/arkadu/support/SupportIndex"),
//               },
//               {
//                 exact: true,
//                 path: "/:customerName/admin/accounts/",
//                 guard: AuthGuard,
//                 lazy: () => import("src/views/arkadu/accounts/AddFundAdmin"),
//               },
//               {
//                 exact: true,
//                 path: "/:customerName/admin/user/registration/",
//                 guard: AuthGuard,
//                 layout: UserLayout,
//                 lazy: () =>
//                   import("src/views/arkadu/user/SimpleRegistrationForm"),
//               },
//               {
//                 exact: true,
//                 path: "/:customerName/admin/user/:userId/deposit/",
//                 guard: AuthGuard,
//                 lazy: () =>
//                   import(
//                     "src/views/arkadu/user/admin/components/AddFundAdminStepper"
//                   ),
//               },
//               {
//                 exact: true,
//                 path: ["/:customerName/admin/user/:userId/notification/"],
//                 guard: AuthGuard,
//                 lazy: () => import("src/views/arkadu/notify/NewNotifyMessage"),
//               },
//               {
//                 exact: true,
//                 path: [
//                   "/:customerName/admin/user/:userId/:tabName",
//                   "/:customerName/admin/user/:userId",
//                 ],
//                 guard: AuthGuard,
//                 lazy: () => import("src/views/arkadu/user/admin/UserAdmin"),
//               },
//               {
//                 exact: true,
//                 path: "/:customerName/admin/cadaster/",
//                 guard: AuthGuard,
//                 lazy: () => import("src/views/arkadu/cadaster/admin/index"),
//               },
//               {
//                 exact: true,
//                 path: "/:customerName/admin/",
//                 guard: AuthGuard,
//                 lazy: () =>
//                   import("src/views/reports/DashboardAlternativeView/index"),
//               },
//             ],
//           },
//           // {
//           //   path: "*",
//           //   component: (
//           //     <Route path="/404" render={() => <Navigate to="/404" />} />
//           //   ),
//           // },
//         ],
//       },
//       {
//         exact: true,
//         layout: BlogLayout,
//         path: "/",
//         lazy: () => import("src/views/arkadu/blog/BlogIndex"),
//       },
//       // {
//       //   path: "*",
//       //   component: <Route path="/404" render={() => <Navigate to="/404" />} />,
//       // },
//     ],
//   },
// ];

// export default routes;
