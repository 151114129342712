import {
  AddCircleRounded,
  CheckCircleRounded,
  CircleRounded,
} from "@mui/icons-material";
import {
  FormHelperText,
  Grid2,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import useUser from "src/hooks/useUser";
import { setUserState } from "src/slices/users";
import { useDispatch, useSelector } from "src/store";

const SelectIdentity = () => {
  const {
    user,
    isAuthenticated,
    loaded,
    selectedIdentity,
    identities,
    identityTypes,
  } = useSelector((state) => state.users);

  const { t } = useTranslation();

  const initialized = React.useRef(false);
  const { currentCustomer } = useSelector((state) => state.customers);
  const [thisState, setThisState] = React.useState({
    countries: [],
    types: [],
    isProcessing: false,
    imageData: null,
    selectedImage: null,
    selectedIdentity: null,
    blurImage: true,
    imageData: null,
    ready: false,
    imageFile: null,
    dataFromImage: null,
    checkTime: 600,
  });
  const { identity } = useUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  console.log("imageData", thisState.imageData);

  return (
    <Grid2
      container
      spacing={3}
      sx={(theme) => ({
        margin: "auto",
        padding: "10px",
        // border: "1px solid #ccc",
        // borderRadius: "5px",
        // boxShadow: "0 0 10px rgba(0,0,0,0.1)",
        // backgroundColor: theme.palette.background,
        marginTop: "20px",
      })}
    >
      <Grid2>
        <Typography variant="h1">{t("Seleccionar Identidad")}</Typography>
        <FormHelperText>
          {t("Selecciona la identidad que deseas utilizar")}
        </FormHelperText>
        <FormHelperText>
          {t(
            "Puedes cambiar de identidad en cualquier momento usando el men� de la barra de navegaci�n"
          )}
        </FormHelperText>
        <MenuList
          sx={{
            padding: 0,
            margin: 0,
            listStyle: "none",
            "& > li": {
              padding: "10px",

              borderBottom: "1px solid #ccc",
              marginTop: "1rem",
              marginBottom: "1rem",
              "&:hover": {
                backgroundColor: "rgba(0,0,0,0.2)",
              },
            },
          }}
        >
          <MenuItem
            sx={{
              "&:hover": {
                backgroundColor: "rgba(0,0,0,0.2)",
              },
              padding: "10px",
              borderRadius: "15px",
              border: "1px solid #ccc",
              marginBottom: "1.2rem",
            }}
            onClick={() => {
              dispatch(setUserState({ identitySelection: false }));
              navigate("/app/identity/new");
            }}
          >
            <AddCircleRounded color="primary" />
            <Typography
              variant="h4"
              sx={{
                marginLeft: "10px",
              }}
            >
              {t("Nueva identidad")}
            </Typography>
          </MenuItem>
          {identities.length === 0 && (
            <MenuItem>
              <ListItemText primary={t("No tienes identidades registradas")} />
            </MenuItem>
          )}
          {identities.map((i) => (
            <MenuItem
              key={i.id}
              onClick={() => {
                identity.setSessionIdentity(i.id);
                dispatch(
                  setUserState({
                    selectedIdentity: i,
                    identitySelection: false,
                  })
                );

                if (currentCustomer) {
                  navigate(`/${currentCustomer.shortname}`);
                } else {
                  navigate("/app");
                }
              }}
              selected={thisState.selectedIdentity?.id === i.id}
            >
              <ListItemAvatar>
                {selectedIdentity?.id === i.id ? (
                  <CheckCircleRounded color="success" />
                ) : (
                  <CircleRounded color="disabled" />
                )}
              </ListItemAvatar>
              <ListItemText primary={i.legal_name} secondary={i.national_id} />
            </MenuItem>
          ))}
        </MenuList>
      </Grid2>
    </Grid2>
  );
};

export default SelectIdentity;
