import { lazy } from "react";
import AuthGuard from "./components/AuthGuard";
import GuestGuard from "./components/GuestGuard";
import DashboardLayout from "./layouts/DashboardLayout";
import taxes_routes_v6 from "./new_routes/taxes_routes_v6";
import { public_routes } from "./routesV6";
import BlogLayout from "./views/arkadu/blog/BlogLayout";
import LicenseListItem from "./views/arkadu/taxes/license/components/LicenseListItem";
import TaxesLayout from "./views/arkadu/taxes/TaxesLayout";
import UserLayout from "./views/arkadu/user/UserLayout";

const ModuleView = lazy(() => import("src/views/arkadu/module/ModuleView"));
const AccountView = lazy(() => import("src/views/account/AccountView"));
const BlogIndex = lazy(() => import("src/views/arkadu/blog/BlogIndex"));
const CustomerIndex = lazy(
  () => import("src/views/arkadu/module/CustomerIndex")
);
const newRoutes = [
  {
    element: <LicenseListItem />,
    path: "license-list-item",
  },
  {
    element: <GuestGuard />,
    children: public_routes,
  },
  {
    element: <BlogLayout />,
    children: [
      {
        index: true,
        element: <BlogIndex />,
      },
    ],
  },
  {
    element: <AuthGuard />,
    children: [
      {
        element: <DashboardLayout />,
        children: [
          {
            element: <UserLayout />,
            children: [
              {
                path: "app",
                children: [
                  {
                    index: true,
                    element: <ModuleView />,
                  },

                  {
                    path: "account",
                    element: <AccountView />,
                  },
                  {
                    path: "account/:tabName",
                    element: <AccountView />,
                  },
                ],
                handle: {
                  lazyChildren: () => import("src/new_routes/app_routes_v6"),
                },
              },
            ],
          },
          {
            path: ":customerName",
            children: [
              {
                index: true,
                element: <CustomerIndex />,
              },
              {
                element: <TaxesLayout />,
                children: [
                  {
                    path: "taxes",
                    children: taxes_routes_v6,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

export default newRoutes;
