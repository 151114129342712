import { lazy } from "react";

const SuppliersLayout = lazy(
  () => import("src/views/arkadu/suppliers/SuppliersLayout")
);
const SuppliersIndex = lazy(
  () => import("src/views/arkadu/suppliers/SuppliersIndex")
);
const SupplierDetail = lazy(
  () => import("src/views/arkadu/suppliers/SupplierDetail")
);
const BaseLayoutId = lazy(() => import("src/views/arkadu/BaseLayoutId"));

export default [
  {
    element: <SuppliersLayout />,
    children: [
      {
        index: true,
        element: <SuppliersIndex />,
      },
      {
        path: "detail/:id/:tabName",
        element: <SupplierDetail />,
      },
      {
        path: "detail/:id/",
        element: <SupplierDetail />,
      },
      {
        path: ":tabName",
        element: <SuppliersIndex />,
      },
    ],
  },
];
