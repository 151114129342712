import { lazy } from "react";

const UserLayout = lazy(() => import("src/views/arkadu/user/UserLayout"));

const AccountView = lazy(() => import("src/views/account/AccountView"));
const OwnedCustomers = lazy(
  () => import("src/views/arkadu/customer/OwnedCustomers")
);
const ModuleView = lazy(() => import("src/views/arkadu/module/ModuleView"));

const SimpleRegistrationForm = lazy(
  () => import("src/views/arkadu/user/SimpleRegistrationForm")
);
const CompanyDetail = lazy(
  () => import("src/views/arkadu/user/company/CompanyDetail")
);
const NewCompanySteps = lazy(
  () => import("src/views/account/AccountView/Steppers/NewCompanySteps")
);
const NewAddress = lazy(
  () => import("src/views/account/AccountView/NewAddress")
);
const NewIdentity = lazy(
  () => import("src/views/account/AccountView/NewIdentity")
);
const VerificationRequest = lazy(
  () => import("src/views/arkadu/user/Identity/VerificationRequest")
);
const UserPinChange = lazy(
  () => import("src/views/account/AccountView/Security/UserPinChange")
);
const NewVehicle = lazy(
  () => import("src/views/account/AccountView/NewVehicle")
);

const SelectIdentity = lazy(
  () => import("src/views/account/AccountView/SelectIdentity")
);
const NotifyIndex = lazy(() => import("src/views/arkadu/notify/index"));

export default [
  {
    index: true,
    element: <ModuleView />,
  },
  {
    path: "identity/new",
    element: <NewIdentity />,
  },
  {
    path: "identity",
    element: <SelectIdentity />,
  },
  {
    path: "account",
    element: <AccountView />,
  },
  {
    path: "account/registration/",
    element: <SimpleRegistrationForm />,
  },
  {
    path: "account/:tabName",
    element: <AccountView />,
  },
  {
    path: "account/companies/detail/:id",
    element: <CompanyDetail />,
  },
  {
    path: "account/companies/new",
    element: <NewCompanySteps />,
  },
  {
    path: "account/addresses/new",
    element: <NewAddress />,
  },
  {
    path: "account/identities/new",
    element: <NewIdentity />,
  },
  {
    path: "account/identity/verify/:IdentityId",
    element: <VerificationRequest />,
  },
  {
    path: "account/security/pinChange/",
    element: <UserPinChange />,
  },
  {
    path: "account/vehicles/new",
    element: <NewVehicle />,
  },
  {
    path: "notify/:tabName",
    element: <NotifyIndex />,
  },
  {
    path: "notify/",
    element: <NotifyIndex />,
  },
  {
    path: "organizations",
    element: <OwnedCustomers />,
  },
];
