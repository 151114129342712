import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  projects: null,
};

const slice = createSlice({
  name: "planning",
  initialState: initialState,
  currentSupplier: null,
  reducers: {
    setPlanningState: (state, action) => {
      Object.keys(action.payload).forEach((k) => {
        state[k] = action.payload[k];
      });
    },
  },
});

export const reducer = slice.reducer;

export const setPlanningState = (data) => async (dispatch) => {
  dispatch(slice.actions.setPlanningState(data));
};
