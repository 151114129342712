import { lazy } from "react";
import AuthGuard from "./components/AuthGuard";
import GuestGuard from "./components/GuestGuard";
import DashboardLayout from "./layouts/DashboardLayout";
import account_routes_v6 from "./new_routes/account_routes_v6";
import audit_routes_v6 from "./new_routes/audit_routes_v6";
import cadastre_routes_v6 from "./new_routes/cadastre_routes_v6";
import corporate_routes_v6 from "./new_routes/corporate_routes_v6";
import customer_user_routes_v6 from "./new_routes/customer_user_routes_v6";
import dev_routes_v6 from "./new_routes/dev_routes_v6";
import environment_routes_v6 from "./new_routes/environment_routes_v6";
import erp_routes_v6 from "./new_routes/erp_routes_v6";
import eventads_routes_v6 from "./new_routes/eventads_routes_v6";
import global_routes_v6 from "./new_routes/global_routes_v6";
import motor_routes_v6 from "./new_routes/motor_routes_v6";
import notify_routes_v6 from "./new_routes/notify_routes_v6";
import planning_routes_v6 from "./new_routes/planning_routes_v6";
import poll_routes_v6 from "./new_routes/poll_routes_v6";
import service_routes_v6 from "./new_routes/service_routes_v6";
import stamp_routes_v6 from "./new_routes/stamp_routes_v6";
import suppliers_routes_v6 from "./new_routes/suppliers_routes_v6";
import support_routes_v6 from "./new_routes/support_routes_v6";
import taxes_routes_v6 from "./new_routes/taxes_routes_v6";
import urbin_routes_v6 from "./new_routes/urbin_routes_v6";
import user_routes_v6 from "./new_routes/user_routes_v6";

import BlogLayout from "./views/arkadu/blog/BlogLayout";
import CadasterLayout from "./views/arkadu/cadastre/CadasterLayout";
import CorporateLayout from "./views/arkadu/corporate/CorporateLayout";
import LicenseListItem from "./views/arkadu/taxes/license/components/LicenseListItem";
import TaxesLayout from "./views/arkadu/taxes/TaxesLayout";
import UserLayout from "./views/arkadu/user/UserLayout";

const ModuleView = lazy(() => import("src/views/arkadu/module/ModuleView"));
const AccountView = lazy(() => import("src/views/account/AccountView"));
const BlogIndex = lazy(() => import("src/views/arkadu/blog/BlogIndex"));
const BlogPostDetail = lazy(
  () => import("src/views/arkadu/blog/BlogPostDetail")
);
const CustomerIndex = lazy(
  () => import("src/views/arkadu/module/CustomerIndex")
);
const NotFoundView = lazy(() => import("src/views/errors/NotFoundView"));
const LoginView = lazy(() => import("src/views/auth/LoginView/index"));
const CertificateView = lazy(
  () => import("src/views/arkadu/certificate/index")
);

const public_routes = [
  {
    path: "404",
    element: <NotFoundView />,
  },
  {
    path: ":customerName?/login",
    element: <LoginView />,
  },
  {
    path: "certificate/validation/:id/",
    element: <CertificateView />,
  },
  {
    path: "certificate/:id/",
    element: <CertificateView />,
  },
  // {
  //   path: "",
  //   element: <BlogIndex />,
  // },
];

const newRoutes = [
  {
    element: <LicenseListItem />,
    path: "license-list-item",
  },
  {
    element: <GuestGuard />,
    children: public_routes,
  },
  {
    element: <BlogLayout />,
    children: [
      {
        index: true,
        element: <BlogIndex />,
      },
      {
        path: ":customerName/blog/:id",
        element: <BlogPostDetail />,
      },
    ],
  },
  {
    element: <AuthGuard />,
    children: [
      {
        element: <DashboardLayout />,
        children: [
          {
            element: <UserLayout />,
            children: [
              {
                path: "app",
                children: user_routes_v6,
              },
            ],
          },
          {
            path: ":customerName",
            children: [
              {
                index: true,
                element: <CustomerIndex />,
              },
              {
                path: "accounts",
                children: account_routes_v6,
              },
              {
                element: <TaxesLayout />,
                children: [
                  {
                    path: "taxes",
                    children: taxes_routes_v6,
                  },
                ],
              },
              {
                element: <CadasterLayout />,
                children: [
                  {
                    path: "cadastre",
                    children: cadastre_routes_v6,
                  },
                ],
              },
              {
                element: <CorporateLayout />,
                children: [
                  {
                    path: "corporate",
                    children: corporate_routes_v6,
                  },
                ],
              },
              {
                path: "motor",
                children: motor_routes_v6,
              },
              {
                path: "eventads",
                children: eventads_routes_v6,
              },
              {
                path: "urbin",
                children: urbin_routes_v6,
              },
              {
                path: "environment",
                children: environment_routes_v6,
              },
              {
                path: "support",
                children: support_routes_v6,
              },
              {
                path: "service",
                children: service_routes_v6,
              },
              {
                path: "audit",
                children: audit_routes_v6,
              },
              {
                path: "notify",
                children: notify_routes_v6,
              },
              {
                path: "system/dev",
                children: dev_routes_v6,
              },
              { path: "user", children: customer_user_routes_v6 },
              {
                path: "poll",
                children: poll_routes_v6,
              },
              {
                path: "global",
                children: global_routes_v6,
              },
              {
                path: "erp",
                children: erp_routes_v6,
              },
              {
                path: "planning",
                children: planning_routes_v6,
              },
              {
                path: "suppliers",
                children: suppliers_routes_v6,
              },
              {
                path: "stamp",
                children: stamp_routes_v6,
              },
            ],
          },
        ],
      },
    ],
  },
];

export default newRoutes;
