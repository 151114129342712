import { lazy } from "react";

const EventAdsIndex = lazy(
  () => import("src/views/arkadu/eventads/EventadsIndex")
);
const EventAdsAdRequestDetail = lazy(
  () => import("src/views/arkadu/eventads/EventadsAdRequestDetail")
);
const EventAdsAdsUserIndex = lazy(
  () => import("src/views/arkadu/eventads/EventadsAdsUserIndex")
);

const eventads_routes_v6 = [
  {
    element: <EventAdsIndex />,
    index: true,
  },
  {
    element: <EventAdsAdRequestDetail />,
    path: "request/:id",
  },
  {
    element: <EventAdsIndex />,
    path: ":tabName",
  },
  {
    element: <EventAdsAdsUserIndex />,
    path: "company/:id",
  },
];

export default eventads_routes_v6;
