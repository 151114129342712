import { Box, LinearProgress, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import NProgress from 'nprogress';
import React, { useEffect } from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    minHeight: '100%',
    padding: theme.spacing(3)
  }
}));

const LoadingScreen = ({ message, ...rest }) => {
  const classes = useStyles();

  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <div className={classes.root}>
      <Box width={400}>
        <LinearProgress />
        {message ? (
          <Typography variant="subtitle2">{message}</Typography>
        ) : null}
      </Box>
    </div>
  );
};

export default LoadingScreen;
