import { lazy } from "react";

const VehicleMotorLayout = lazy(
  () => import("src/views/arkadu/motor/VehicleMotorLayout")
);
const VehicleMotorIndex = lazy(() => import("src/views/arkadu/motor/index"));
const VehicleMotorDetail = lazy(
  () => import("src/views/arkadu/motor/VehicleMotorDetail")
);
const FixMotorIndex = lazy(
  () => import("src/views/arkadu/motor/FixMotorIndex")
);

export default [
  {
    element: <VehicleMotorLayout />,
    children: [
      {
        index: true,
        element: <VehicleMotorIndex />,
      },
      {
        path: "vehicle/:inscriptionId",
        element: <VehicleMotorDetail />,
      },
      {
        path: "vehicle/statement/:vehicleId",
        element: <VehicleMotorDetail />,
      },
      {
        path: "inscription",
        element: <VehicleMotorIndex />,
      },
      {
        path: "newVehicle",
        element: <VehicleMotorIndex />,
      },
      {
        path: ":tabName",
        element: <VehicleMotorIndex />,
      },
    ],
  },
];
