import { lazy } from "react";

const SupportIndex = lazy(
  () => import("src/views/arkadu/support/SupportIndex")
);
const TicketDetail = lazy(
  () => import("src/views/arkadu/support/TicketDetail")
);
const NewTicketForm = lazy(
  () => import("src/views/arkadu/support/NewTicketForm")
);

export default [
  {
    index: true,
    element: <SupportIndex />,
  },
  {
    path: "ticket/new",
    element: <NewTicketForm />,
  },
  {
    path: "ticket/:id",
    element: <TicketDetail />,
  },
  {
    path: "admin/:userId/ticket/:ticketId",
    element: <SupportIndex />,
  },
  {
    path: "admin/:userId/",
    element: <SupportIndex />,
  },
  {
    path: "admin/",
    element: <SupportIndex />,
  },
];
