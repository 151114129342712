import { AutoDeleteRounded, AutoFixHigh } from "@mui/icons-material";
import {
  Box,
  Button,
  FormHelperText,
  Grid2 as Grid,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill-new";
import useAI from "src/hooks/useAI";
import AIActionButton from "./AIActionButton";

const useStyles = makeStyles({
  previewIA: {
    fontFamily: "Roboto,Helvetica, Arial, sans-serif",
    fontWeight: "400",
    "& li": {
      marginLeft: "2rem",
    },
  },
});

const CustomTextEditor = ({
  variant,
  mode = "richText",
  label,
  onChange,
  initialValue,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [thisState, setThisState] = React.useState({
    value: "",
    error: null,
    editorContent: initialValue || "",
    plainText: initialValue || "",
    waitingForAI: false,
    aiResponse: null,
  });
  const generatorPrefix =
    mode === "richText"
      ? "retorna HTML: "
      : "retorna texto plano con saltos de linea:";
  const { generateContent } = useAI();

  const handleChange = (html, d, s, editor) => {
    if (onChange) {
      onChange(html);
    }
    var plain = html;
    if (mode === "richText") {
      plain = editor.getText();
    }

    setThisState({
      ...thisState,
      editorContent: html,
      plainText: plain,
    });
  };

  const handleIARedaction = () => {
    if (thisState.plainText?.length < 50) {
      setThisState({
        ...thisState,
        error: t("El texto es muy corto"),
      });
      return;
    }
    setThisState({
      ...thisState,
      waitingForAI: true,
    });
    generateContent(
      `${generatorPrefix} ${thisState.editorContent}`,
      "redactor"
    ).then((res) => {
      if (res.res === 0) {
        setThisState({
          ...thisState,
          error: res.message,
          waitingForAI: false,
        });
        return;
      } else {
        setThisState({
          ...thisState,
          waitingForAI: false,
          aiResponse: res.data.replace("```html", "").replace("```", ""),
        });
      }
    });
  };

  React.useEffect(() => {
    setThisState({
      ...thisState,
      editorContent: initialValue || "",
      plainText: initialValue || "",
    });
  }, [initialValue]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid
          sx={{
            minHeight: "50px !important",
            "& .ql-container": {
              minHeight: "50px !important",
              color: "black",
            },
          }}
          size={12}
        >
          <Grid container spacing={2}>
            <Grid size={6}>
              {label && <Typography variant="h5">{label}</Typography>}
            </Grid>
            <Grid textAlign="right" size={6}>
              <AIActionButton
                onClick={handleIARedaction}
                label={t("Redacci�n con IA")}
                color="secondary"
              />
            </Grid>
          </Grid>
          {thisState.error && (
            <FormHelperText error>{thisState.error}</FormHelperText>
          )}
        </Grid>
        {thisState.aiResponse && (
          <Grid
            sx={{
              border: "1px solid #ccc",
              padding: "10px",
              margin: "10px",
              borderRadius: "5px",
            }}
            size={12}
          >
            <FormHelperText>{t("Texto mejorado")}</FormHelperText>
            <Grid
              dangerouslySetInnerHTML={{ __html: thisState.aiResponse }}
              className={classes.previewIA}
            />
            <br />
            <Grid container spacing={2}>
              <Grid size={6}>
                <Button
                  variant="contained"
                  startIcon={<AutoDeleteRounded />}
                  onClick={() => {
                    setThisState({
                      ...thisState,
                      aiResponse: null,
                    });
                  }}
                  size="small"
                  color="error"
                >
                  {t("Descartar cambios")}
                </Button>
              </Grid>
              <Grid size={6}>
                <Button
                  size="small"
                  variant="contained"
                  startIcon={<AutoFixHigh />}
                  onClick={() => {
                    const aiResponse = thisState.aiResponse;

                    setTimeout(() => {
                      setThisState({
                        ...thisState,
                        editorContent: aiResponse,
                      });
                    }, 100);
                    setTimeout(() => {
                      setThisState({
                        ...thisState,
                        editorContent: aiResponse,
                        aiResponse: null,
                      });
                    }, 800);
                  }}
                  color="success"
                >
                  {t("Aplicar cambios")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid size={12}>
          {thisState.waitingForAI && (
            <LinearProgress color="secondary" variant="indeterminate" />
          )}
          {mode === "richText" ? (
            <ReactQuill
              readOnly={thisState.waitingForAI}
              autoFocus
              theme="snow"
              value={thisState.editorContent}
              onChange={handleChange}
            />
          ) : (
            <TextField
              fullWidth
              multiline
              minRows={3}
              maxRows={10}
              value={thisState.editorContent}
              onChange={(e) => handleChange(e.target.value)}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
export default CustomTextEditor;
