import { ChangeCircle, DeleteRounded, SaveRounded } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  FormControlLabel,
  FormHelperText,
  Grid2 as Grid,
  InputAdornment,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { createTheme } from "@mui/system";
import { TimePicker } from "@mui/x-date-pickers";
import { format } from "date-fns";
import { useFormik } from "formik";
import moment from "moment";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "src/store";
import { getObjectElement } from "src/utils/dataRenders";
import { Yup } from "src/utils/utils";
import CustomDatePicker from "../CustomDatePicker";
import FilesDropzone from "../FilesDropzone";
import LoadingScreen from "../LoadingScreen";
import CustomTextEditor from "./CustomTextEditor";

const theme = createTheme();
const useStyles = makeStyles(() => ({
  smallInput: {
    "& .MuiInputBase-input, .MuiInputBase-root": {
      padding: `${theme.spacing(1)} !important`,
    },
  },
}));

const fieldTypeMap = {
  currency: "number",
  decimal: "number",
};
const RenderFieldInput = ({
  field,
  values,
  errors,
  touched,
  setFieldValue,
  handleChange,

  size,
  handleBlur,
}) => {
  const classes = useStyles();
  const mainClass = classes.smallInput;
  const { t, i18n } = useTranslation();

  const renderHelperText = (field, values, errors, touched) => {
    if (errors[field.id] && touched[field.id]) {
      return <FormHelperText error>{errors[field.id]}</FormHelperText>;
    }

    if (!field.helperText) {
      return null;
    } else if (typeof field.helperText === "function") {
      var hText = field.helperText(values);
      if (React.isValidElement(hText)) {
        return hText;
      }
      return (
        <FormHelperText sx={{ marginBottom: "1rem" }}>{hText}</FormHelperText>
      );
    } else if (React.isValidElement(field.helperText)) {
      return field.helperText;
    }

    return (
      <FormHelperText sx={{ marginBottom: "1rem" }}>
        {field.helperText}
      </FormHelperText>
    );
  };

  if (field.type === "file") {
    if (values[field.id]) {
      return (
        <Grid
          key={field.id}
          size={{
            xs: 12,
            md: 6,
            lg: 4,
          }}
        >
          <Typography>
            {field.label} {values[field.id][0].name}
          </Typography>
          <Button
            color="error"
            size="small"
            startIcon={<ChangeCircle />}
            onClick={() => {
              setFieldValue(field.id, null);
            }}
          >
            {t("Cambiar")}
          </Button>
        </Grid>
      );
    }
    return (
      <FilesDropzone
        message={field.label}
        uploadOnSelect={true}
        onUpload={(files) => {
          setFieldValue(field.id, files);
        }}
        options={field.options}
        help={field.helperText}
      />
    );
  }
  if (field.type === "textarea_single") {
    return (
      <Grid size={12}>
        <TextField
          className={mainClass}
          label={field.label}
          variant={"outlined"}
          id={field.id}
          onChange={handleChange}
          value={values[field.id]}
          error={Boolean(errors[field.id] && touched[field.id] && true)}
          // helperText={renderHelperText(field, values, errors, touched)}
          fullWidth
          maxRows={6}
          minRows={3}
          multiline={true}
          type={fieldTypeMap[field.type] || field.type || "text"}
          slotProps={{
            input: {
              startAdornment: field.startAdornment ? (
                <InputAdornment position="start">
                  {field.startAdornment}
                </InputAdornment>
              ) : null,
              endAdornment: field.endAdornment ? (
                <InputAdornment position="end">
                  {field.endAdornment}
                </InputAdornment>
              ) : null,
            },
          }}
          // field.optional
        />
        {renderHelperText(field, values, errors, touched)}
      </Grid>
    );
  }

  if (["richText", "textarea"].includes(field.type)) {
    return (
      <Grid size={12}>
        <CustomTextEditor
          label={field.label}
          mode={field.type}
          initialValue={values[field.id]}
          onChange={(v) => setFieldValue(field.id, v)}
        />
        {renderHelperText(field, values, errors, touched)}
      </Grid>
    );
  }
  if (field.type === "divider") {
    return (
      <Grid size={12}>
        {field.label && <Typography variant="h6">{field.label}</Typography>}
        <hr />
      </Grid>
    );
  }
  if (field.type === "select") {
    return (
      <Grid size={12}>
        <Autocomplete
          className={mainClass}
          fullWidth
          disableClearable={!field.optional}
          onChange={(e, v) => {
            setFieldValue(field.id, v);
          }}
          loading={field.isSearching}
          value={values[field.id]}
          getOptionLabel={(o) => {
            if (o && o.label) {
              return o.label;
            }
            if (field.getOptionLabel) {
              return field.getOptionLabel(o);
            } else {
              if (field.initialValue && field.options) {
                var _fo = field.options.find(
                  (_o) => _o.value === field.initialValue
                );
                if (_fo) return _fo.label;
              }
            }
            return "";
          }}
          isOptionEqualToValue={(o, v) => {
            if (o && o.value && values[field.id]) {
              return o.value === values[field.id].value;
            } else {
              var _v = field.options.find(
                (_o) => _o.value === values[field.id]
              );
              if (_v) {
                return _v.value === values[field.id];
              }
            }
            return o === v;
          }}
          options={field.options || []}
          id={field.id}
          renderOption={field.renderOption}
          renderInput={({ ...params }) => {
            console.log(values[field.id]);
            return (
              <>
                <TextField
                  {...params}
                  key={`${field.id}-select`}
                  className={mainClass}
                  label={field.label}
                  slotProps={{
                    input: {
                      ...params.InputProps,
                      startAdornment:
                        values[field.id] && values[field.id].startAdornment ? (
                          <InputAdornment position="start">
                            {values[field.id].startAdornment}
                          </InputAdornment>
                        ) : null,
                      endAdornment:
                        values[field.id] && values[field.id].endAdornment ? (
                          <InputAdornment position="end">
                            {values[field.id].endAdornment}
                          </InputAdornment>
                        ) : null,
                    },
                  }}
                  error={errors[field.id]}
                  // helperText={renderHelperText(field, values, errors, touched)}
                />
                {renderHelperText(field, values, errors, touched)}
              </>
            );
          }}
        />
      </Grid>
    );
  }
  if (field.type === "bool" || field.type === "boolean") {
    return (
      <Grid size={12}>
        <FormControlLabel
          className={mainClass}
          label={field.label}
          control={
            <Switch
              checked={Boolean(values[field.id])}
              onChange={() => setFieldValue(field.id, !values[field.id])}
              inputProps={{ "aria-label": "controlled" }}
              color="success"
            />
          }
        />
        {renderHelperText(field, values, errors, touched)}
      </Grid>
    );
  }
  if (field.type === "search") {
    return (
      <Grid size={12}>
        <Autocomplete
          className={mainClass}
          loading={field.isSearching}
          fullWidth
          getOptionLabel={field.getOptionLabel}
          options={field.options}
          disableClearable
          value={values[field.id]}
          isOptionEqualToValue={(o) => {
            if (o && values[field.id]) {
              if (o.value) {
                // console.log(o.value === values[field.id].value);
                return o.value === values[field.id].value;
              } else {
                var _v = field.options.find(
                  (_o) => _o.value === values[field.id].value
                );
                if (_v) {
                  return _v.value === values[field.id].value;
                } else {
                  _v = field.getOptionLabel(values[field.id]);
                  return _v === o.label;
                }
              }
            }
            return false;
          }}
          onChange={(e, v) => {
            // console.log(v, field.id, values[field.id]);

            setFieldValue(field.id, v);
          }}
          renderInput={(params) => (
            <>
              <TextField
                className={mainClass}
                {...params}
                error={Boolean(errors[field.id] && touched[field.id])}
                // helperText={renderHelperText(field, values, errors, touched)}
                label={field.label}
                onChange={(e) => {
                  if (e.target.value && e.target.value.length > 2) {
                    field.onSearch(e.target.value);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    field.onSearch(values[field.id]);
                  }
                }}
                slotProps={{
                  input: {
                    ...params.InputProps,
                    type: "search",
                  },
                }}
              />
              {renderHelperText(field, values, errors, touched)}
            </>
          )}
        />
      </Grid>
    );
  }
  if (field.type === "date") {
    return (
      <Grid size={12}>
        <CustomDatePicker
          label={field.label}
          id={field.id}
          locale={"es-VE"}
          onChange={(v) => {
            setFieldValue(field.id, format(v, "yyyy-MM-dd HH:mm:ss xxx"));
          }}
          value={values[field.id]}
          {...field.props}
        />
        {renderHelperText(field, values, errors, touched)}
      </Grid>
    );
  }
  if (field.type === "time") {
    return (
      <Grid size={12}>
        <TimePicker
          label={field.label}
          id={field.id}
          locale={"es-VE"}
          defaultTime={values[field.id]}
          onChange={(v) => {
            setFieldValue(field.id, v);
          }}
          value={values[field.id]}
          renderInput={(params) => (
            <>
              <TextField
                {...params}
                className={mainClass}
                error={Boolean(errors[field.id] && touched[field.id] && true)}
                // helperText={renderHelperText(field, values, errors, touched)}
              />
              {renderHelperText(field, values, errors, touched)}
            </>
          )}
          {...field.props}
        />
      </Grid>
    );
  }
  if (field.type === "number") {
    return (
      <Grid size={12}>
        <TextField
          className={mainClass}
          label={field.label}
          variant={field.type === "date" ? "standard" : "outlined"}
          id={field.id}
          onChange={(e) => {
            // parseFloat(e.target.value)
            try {
              // get decimal point at the end of the string
              var end = e.target.value.slice(-1);
              if (![".", ","].includes(end)) {
                end = "";
              }
              if (end === ",") {
                end = ".";
              }
              var n = parseFloat(e.target.value);
              if (isNaN(n)) {
                return;
              }
              setFieldValue(field.id, n + end);
            } catch (error) {
              return;
            }
            // handleChange
          }}
          value={values[field.id]}
          error={Boolean(errors[field.id] && touched[field.id] && true)}
          maxRows={1}
          minRows={1}
          multiline={false}
          type={"text"}
          slotProps={{
            input: {
              startAdornment: field.startAdornment ? (
                <InputAdornment position="start">
                  {field.startAdornment}
                </InputAdornment>
              ) : null,
              endAdornment: field.endAdornment ? (
                <InputAdornment position="end">
                  {field.endAdornment}
                </InputAdornment>
              ) : null,
            },
          }}

          // field.optional
        />
        {renderHelperText(field, values, errors, touched)}
      </Grid>
    );
  }

  return (
    <Grid size={12}>
      <TextField
        className={mainClass}
        label={field.label}
        variant={field.type === "date" ? "standard" : "outlined"}
        id={field.id}
        onChange={handleChange}
        value={values[field.id]}
        error={Boolean(errors[field.id] && touched[field.id] && true)}
        // helperText={renderHelperText(field, values, errors, touched)}
        fullWidth
        maxRows={1}
        minRows={1}
        multiline={false}
        type={fieldTypeMap[field.type] || field.type || "text"}
        slotProps={{
          input: {
            startAdornment: field.startAdornment ? (
              <InputAdornment position="start">
                {field.startAdornment}
              </InputAdornment>
            ) : null,
            endAdornment: field.endAdornment ? (
              <InputAdornment position="end">
                {field.endAdornment}
              </InputAdornment>
            ) : null,
          },
        }}
        // field.optional
      />
      {renderHelperText(field, values, errors, touched)}
    </Grid>
  );
};

const RenderForm = ({
  title = null,
  fieldSet = [],
  element = null,
  onCancel = () => {},
  onChange = () => {},
  showActions = true,
  onSubmit = () => {},
  disabled = false,
  onDelete = null,
  initialValues = {},
  validationSchema = Yup.object(),
  size = "medium",
  buttonsProps = {},
  sx = {},
}) => {
  const { t } = useTranslation();
  const formikHandle = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // console.log(values, formikHandle.values, fieldSet);
      var _values = {};
      fieldSet.forEach((f) => {
        if (showField(f) && f.type !== "divider") {
          if (f.type === "date") {
            _values[f.id] = moment(values[f.id]).format("YYYY-MM-DD");
          } else {
            _values[f.id] = values[f.id];
          }
        }
      });
      onSubmit(_values);
    },
  });

  const showField = (f) => {
    return (
      !f.conditionalBy ||
      Boolean(f.conditionalBy && f.conditionalBy(formikHandle.values))
    );
  };

  useEffect(() => {
    if (onChange) {
      onChange(formikHandle.values);
    }
  }, [formikHandle.values]);

  return (
    <form onSubmit={formikHandle.handleSubmit}>
      <Grid
        container
        spacing={1}
        sx={{
          display: "flex",
          margin: "0px auto !important",
          justifyContent: "space-between",
          padding: {
            xs: "0.5rem !important",
            md: "1rem !important",
            lg: "2rem !important",
          },
          ...sx,
        }}
        maxWidth="md"
      >
        {title && (
          <Grid size={12}>
            <Typography variant="h4">{title}</Typography>
          </Grid>
        )}
        {fieldSet.map((f, i) => {
          if (!f) return null;
          if (showField(f)) {
            return (
              <RenderFieldInput
                key={i}
                field={f}
                {...formikHandle}
                size={size}
              />
            );
          }
          return null;
        })}
      </Grid>
      {showActions && (
        <Grid
          container
          spacing={1}
          sx={{
            display: "flex",
            margin: "0px auto !important",
            justifyContent: "space-between",
            padding: { xs: "1rem !important", md: "1rem !important" },
          }}
          maxWidth="md"
        >
          <Button
            variant={buttonsProps?.cancel?.variant || "outlined"}
            color={buttonsProps?.cancel?.color || "error"}
            startIcon={buttonsProps?.cancel?.icon || <DeleteRounded />}
            onClick={() => {
              onCancel();
            }}
          >
            {buttonsProps?.cancel?.label || t("Cancelar")}
          </Button>
          {element && onDelete && (
            <Button
              startIcon={buttonsProps.delete?.icon || <DeleteRounded />}
              onClick={() => onDelete()}
              color={buttonsProps?.delete?.color || "error"}
              variant={buttonsProps?.delete?.variant || "outlined"}
            >
              {buttonsProps?.delete?.label || t("delete")}
            </Button>
          )}
          <Button
            variant={buttonsProps.submit?.variant || "outlined"}
            disabled={Boolean(disabled)}
            type="submit"
            color={buttonsProps.submit?.color || "success"}
            startIcon={buttonsProps.submit?.icon || <SaveRounded />}
          >
            {buttonsProps.submit?.label || t("save")}
          </Button>
        </Grid>
      )}
    </form>
  );
};

const FormBuilder = ({
  title = null,
  element = null,
  fieldSet = [],
  onCancel = () => {},
  showActions = true,
  onChange = () => {},
  buttonsProps = {},
  onSubmit = () => {},
  onDelete = null,
  disabled = false,
  size = "medium",
  sx = {},
}) => {
  const { t } = useTranslation();
  const { adminMode } = useSelector((state) => state.settings);
  const { currentCustomer } = useSelector((state) => state.customers);
  const { user } = useSelector((state) => state.users);
  const initialized = useRef(null);
  const [isSearching, setIsSearching] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState(null);
  const [initialValues, setInitialValues] = useState(null);
  const [validation, setValidation] = useState({});
  const [internalFieldSet, setInternalFieldSet] = useState(fieldSet);

  const validationTypes = {
    text: Yup.string(),
    phone: Yup.string(),
    email: Yup.string(),
    textarea: Yup.string(),
    number: Yup.number(),
    date: Yup.date(),
    currency: Yup.number(),
    decimal: Yup.number(),
    select: Yup.object(),
    bool: Yup.boolean(),
    boolean: Yup.boolean(),
    search: Yup.object(),
    time: Yup.string().test("is_time", t("Debe ser una hora v�lida"), (v) => {
      return moment(v, "HH:mm");
    }),
    file: Yup.mixed(),
    richText: Yup.string(),
    textarea_single: Yup.string(),
    divider: Yup.string(),
  };

  const getValidation = (f) => {
    if (f.validation) {
      return f.validation;
    }
    if (
      f.optional ||
      f.type === "bool" ||
      f.type === "boolean" ||
      f.type === "divider"
    ) {
      return validationTypes[f.type || "text"].optional().nullable();
    }
    var _v = validationTypes[f.type || "text"];
    if (f.minLength) {
      _v = _v.min(
        f.minLength,
        t("Debe tener al menos {{minLenght}} caracteres", {
          minLenght: f.minLenght,
        })
      );
    }
    if (f.maxLength) {
      _v = _v.max(
        f.maxLength,
        t("Debe tener como m�ximo {{maxLength}} caracteres", {
          maxLength: f.maxLength,
        })
      );
    }
    if (f.min) {
      _v = _v.min(f.min, t("Debe ser mayor o igual a {{min}}", { min: f.min }));
    }
    if (f.max) {
      _v = _v.max(f.max, t("Debe ser menor o igual a {{max}}", { max: f.max }));
    }

    return _v.required(t("Campo requerido"));
  };

  const parseFields = () => {
    var _ini = {}; // Initial values
    var _vali = {}; // validation schema from Yup
    var _interFields = [];
    var objectTypes = ["select", "search"];
    // Iterate over each field in the fieldSet
    fieldSet.map((field) => {
      // If the field is not defined, skip this iteration
      if (!field || field.type === "divider") return;

      // Clone the field to mutate options
      let clonedField = field;

      // Set the initial value of the field, defaulting to an empty string
      let fieldValue = field.initialValue || "";

      // If the field type is boolean, convert the initial value to a boolean
      if (field.type === "bool" || field.type === "boolean") {
        fieldValue = Boolean(field.initialValue);
      }

      // If an element is defined, the form will be used to edit the current element
      if (element) {
        // Get the initial value from the element
        fieldValue = element[field.id];

        // If a getValue function is defined for the field, use it to get the value
        if (field.getValue) {
          fieldValue = field.getValue(element);

          // If the field type is an object type and no options are defined,
          // set the options to contain the current element value
          if (
            objectTypes.includes(field.type) &&
            (!clonedField.options || clonedField.options.length === 0)
          ) {
            clonedField.options = [fieldValue];
          }
        } else {
          // If the field type is an object type
          if (objectTypes.includes(field.type)) {
            // If the field value is an object, get its id
            if (fieldValue && typeof fieldValue === "object") {
              fieldValue = fieldValue.id;
            }

            // If options are defined for the field, find the option that matches the field value
            if (field.options) {
              fieldValue = field.options.find(
                (option) => option.value === fieldValue
              );
            } else {
              // If the field name is not the same as the field id, get the label from the element using field.name
              if (field.name !== field.id) {
                let nameValue = getObjectElement(element, field.name);
                fieldValue = { value: fieldValue, label: nameValue };
              }

              // If no options are defined for the field, set the current value as the only option
              clonedField.options = [fieldValue];
            }
          }
        }
      } else {
        // If no element is defined and the field has options
        if (clonedField.options && clonedField.options.length > 0) {
          // If no initial value is defined for the field, set the first option as the initial value
          if (!clonedField.initialValue) {
            clonedField.initialValue = clonedField.options[0];
            fieldValue = clonedField.options[0];
          } else {
            // If the initial value of the field does not have a value property, find the option that matches the initial value
            if (!clonedField.initialValue.value) {
              clonedField.initialValue = clonedField.options.find(
                (option) => option.value === clonedField.initialValue
              );
              fieldValue = clonedField.initialValue;

              // If no matching option is found, set the first option as the initial value
              if (!clonedField.initialValue) {
                clonedField.initialValue = clonedField.options[0];
                fieldValue = clonedField.options[0];
              }
            }
          }
        }
      }

      // Add the cloned field to the interFields array
      _interFields.push(clonedField);

      // Set the initial value and validation for the field
      _ini[field.id] = fieldValue;
      _vali[field.id] = getValidation(clonedField);
    });

    // console.log(_ini, _vali, _interFields);
    setInternalFieldSet(_interFields); // internalFields mutated with element
    setInitialValues(_ini);
    setValidation(Yup.object().shape(_vali));
  };

  useEffect(() => {
    if (initialized.current === null) {
      initialized.current = true;
    }
  }, []);

  useEffect(() => {
    if (fieldSet) {
      parseFields();
    }
  }, [fieldSet]);
  if (!initialValues) {
    return <LoadingScreen message={t("Cargando formulario")} />;
  }
  // console.log(initialValues, element);
  return (
    <RenderForm
      title={title}
      onSubmit={onSubmit}
      onCancel={onCancel}
      onChange={onChange}
      onDelete={onDelete}
      element={element}
      fieldSet={fieldSet}
      disabled={disabled}
      initialValues={initialValues}
      showActions={showActions}
      buttonsProps={buttonsProps}
      validationSchema={validation}
      sx={sx}
      size={size}
    />
  );
};

const formField = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    "text",
    "email",
    "textarea",
    "textarea_single",
    "richText",
    "number",
    "currency",
    "select",
    "bool",
    "boolean",
    "search",
    "date",
    "file",
    "time",
    "divider",
  ]).isRequired,
  options: PropTypes.any,
  getOptionLabel: PropTypes.func,
  initialValue: PropTypes.any,
  helperText: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.func,
  ]),
  optional: PropTypes.bool,
  conditionalBy: PropTypes.func,
  props: PropTypes.object,
  validation: PropTypes.object,
  onSearch: PropTypes.func,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  name: PropTypes.string,
  getValue: PropTypes.func,
  isSearching: PropTypes.bool,
};

const commonProps = {
  // title can be string, bool or null
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  element: PropTypes.any,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  showActions: PropTypes.bool,
  fieldSet: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.shape(formField), PropTypes.any])
  ).isRequired,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  buttonsProps: PropTypes.shape({
    submit: PropTypes.shape({
      label: PropTypes.string,
      color: PropTypes.string,
      variant: PropTypes.string,
      icon: PropTypes.any,
    }),
    cancel: PropTypes.shape({
      label: PropTypes.string,
      color: PropTypes.string,
      variant: PropTypes.string,
      icon: PropTypes.any,
    }),
    delete: PropTypes.shape({
      label: PropTypes.string,
      color: PropTypes.string,
      variant: PropTypes.string,
      icon: PropTypes.any,
    }),
  }),
  disabled: PropTypes.bool,
  sx: PropTypes.object,
};

FormBuilder.propTypes = {
  ...commonProps,
};

RenderForm.propTypes = {
  ...commonProps,
};

export default FormBuilder;
export { RenderFieldInput };
