import { lazy } from "react";

const AccountingIndex = lazy(
  () => import("src/views/arkadu/erp/accounting/AccountingIndex")
);
const ErpInventoryIndex = lazy(
  () => import("src/views/arkadu/erp/inventory/ErpInventoryIndex")
);
const ErpEmployerStaff = lazy(
  () => import("src/views/arkadu/erp/staff/employee/ErpEmployerStaff")
);
const ErpStaffIndex = lazy(
  () => import("src/views/arkadu/erp/staff/ErpStaffIndex")
);
const ErpBudgetIndex = lazy(
  () => import("src/views/arkadu/erp/budget/ErpBudgetIndex")
);
const ErpSuppliersIndex = lazy(
  () => import("src/views/arkadu/erp/suppliers/ErpSuppliersIndex")
);
const ErpProjectIndex = lazy(
  () => import("src/views/arkadu/erp/project/ErpProjectIndex")
);
const ErpSecurityRoleDetail = lazy(
  () => import("src/views/arkadu/erp/security/ErpSecurityRoleDetail")
);
const ErpSecurityIndex = lazy(
  () => import("src/views/arkadu/erp/security/ErpSecurityIndex")
);
const ErpIndex = lazy(() => import("src/views/arkadu/erp/ErpIndex"));

export default [
  {
    index: true,
    element: <ErpIndex />,
  },
  {
    path: "accounting/:tabName",
    element: <AccountingIndex />,
  },
  {
    path: "accounting/",
    element: <AccountingIndex />,
  },
  {
    path: "inventory/",
    element: <ErpInventoryIndex />,
  },
  { path: "staff/employee/detail/:id", element: <ErpEmployerStaff /> },
  { path: "staff/:tabName", element: <ErpStaffIndex /> },
  { path: "staff/", element: <ErpStaffIndex /> },
  { path: "budget/", element: <ErpBudgetIndex /> },
  { path: "suppliers/", element: <ErpSuppliersIndex /> },
  { path: "project/", element: <ErpProjectIndex /> },
  { path: "security/roles/detail/:id", element: <ErpSecurityRoleDetail /> },
  { path: "security/:tabName", element: <ErpSecurityIndex /> },
  { path: "security/", element: <ErpSecurityIndex /> },
];
