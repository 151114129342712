import { TextField, useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import PinField from "react-pin-field";
const useStyles = makeStyles(() => ({
  pin: {
    border: "1px solid #d3d3d3",
    borderRight: "none",
    fontSize: "2rem",
    height: "4rem",
    outline: "none",
    textAlign: "center",
    "&:last-of-type": {
      borderRadius: "0 0.5rem 0.5rem 0",
      borderRight: "1px solid #d3d3d3",
    },
    "&:first-of-type": {
      borderRadius: "0.5rem 0 0 0.5rem",
    },
    transitionDuration: ".25s",
    transitionProperty: "color,border,box-shadow,-webkit-transform",
    transitionProperty: "color,border,box-shadow,transform",
    transitionProperty: "color,border,box-shadow,transform,-webkit-transform",
    width: "4rem",
  },
  pinConfirm: {
    border: "1px solid #d3d3d3",
    borderRight: "none",
    fontSize: "2rem",
    height: "4rem",
    outline: "none",
    textAlign: "center",
    "&:last-of-type": {
      borderRadius: "0 0.5rem 0.5rem 0",
      borderRight: "1px solid #d3d3d3",
    },
    "&:first-of-type": {
      borderRadius: "0.5rem 0 0 0.5rem",
    },
    transitionDuration: ".25s",
    transitionProperty: "color,border,box-shadow,-webkit-transform",
    transitionProperty: "color,border,box-shadow,transform",
    transitionProperty: "color,border,box-shadow,transform,-webkit-transform",
    width: "4rem",
  },
  smallPin: {
    width: "3rem",
  },
}));
const SecurePinField = ({
  length = 4,
  value = "",
  onChange = () => {},
  onComplete = () => {},
  type = "password",
  variant = "single",
}) => {
  const classes = useStyles();

  const initialized = useRef(null);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("lg"));
  const pinClasses = clsx(classes.pin, isSmall ? classes.smallPin : {});

  useEffect(() => {
    if (initialized.current === undefined) {
      initialized.current = true;
    }
  }, []);

  if (variant === "single") {
    return (
      <TextField
        type={type || "password"}
        variant={"standard"}
        value={value}
        name={"pin"}
        inputMode={"numeric"}
        onChange={(e) => {
          console.log(e.target.value);
          onChange(e.target.value);
        }}
      />
    );
  }

  return (
    <PinField
      onSubmit={onComplete}
      className={pinClasses}
      length={length || 4}
      onChange={(v) => {
        if (v.length === length) {
          onComplete(v);
        }
        onChange(v);
      }}
      type={type || "password"}
      inputMode="numeric"
    />
  );
};

SecurePinField.propTypes = {
  length: PropTypes.number,
};

export default SecurePinField;
