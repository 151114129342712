import { useNavigate } from "react-router-dom";

import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useParams } from "react-router";
import LottiePlayer from "src/components/LottieAnimation";
import { getCadasterConfig } from "src/slices/cadaster";
import { useDispatch, useSelector } from "src/store";
import { defaultErrorStates, defaultViewStates } from "src/utils/dataRenders";
import ErrorView from "src/views/errors/ErrorView";

export const CadasterAnimation = ({}) => {
  return (
    <LottiePlayer path="/static/lotties/real_estate_arkadu_animation.json" />
  );
};

const viewStates = {
  ...defaultViewStates,
};
const errorStates = [...defaultErrorStates];
const CadasterLayout = () => {
  const { t } = useTranslation();
  const { adminMode } = useSelector((state) => state.settings);
  const { currentCustomer } = useSelector((state) => state.customers);
  const { user } = useSelector((state) => state.users);
  const { property_types, property_uses, cadasterConfigLoaded } = useSelector(
    (state) => state.cadaster
  );
  const dispatch = useDispatch();
  const initialized = useRef(null);
  let navigate = useNavigate();
  const { id } = useParams();
  const [isSearching, setIsSearching] = useState(false);
  const handleSearch = (searchText) => {};
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [data, setData] = useState(null);
  const [state, setState] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const loadData = async () => {
    dispatch(
      getCadasterConfig({
        customer_id: currentCustomer.id,
        mode: adminMode ? "admin" : "user",
      })
    );
    return;
  };

  useEffect(() => {
    if (initialized.current === null) {
      initialized.current = true;
    }
  }, []);
  useEffect(() => {
    if (currentCustomer) {
      loadData();
    }
  }, [currentCustomer]);

  if (errorStates.includes(state)) {
    return <ErrorView message={errorMessage} />;
  }
  if (!cadasterConfigLoaded) {
    return <CadasterAnimation />;
  }

  return (
    <>
      <Outlet />
    </>
  );
};

export default CadasterLayout;
