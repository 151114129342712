import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import arkadu from "src/coreConfig";
import axios from "src/utils/axios";

const initialState = {
  supportUsers: [],
  currentSupportUser: null,
  currentSupportTicket: null,
  supportLoaded: false,
  sendingMessage: false,
  tickets: [],
  options: null,
  states: [],
  stateOptions: [],
  modules: [],
  filters: {
    order: "asc",
    state: "all",
    assignedTo: null,
    module: null,
    page: 1,
    paginatedBy: 25,
  },
};
const slice = createSlice({
  name: "support",
  initialState,
  reducers: {
    setSupportUsers: (state, action) => {
      const { tickets } = action.payload;
      state.tickets = tickets;
      const { users, states, filters } = action.payload;
      state.supportUsers = _.sortBy(users, [
        (_u) => _.min(_u.tickets, (_t) => _t.created),
      ]);
      state.states = states;
      state.filters = filters;
      state.supportLoaded = true;
    },
    setStates: (state, action) => {
      state.states = action.payload;

      var _oS = _.map(action.payload, (_o) => {
        return { label: _o.name, value: `${_o.id}` };
      });
      console.log(_oS, action.payload);
      state.stateOptions = _oS;
    },
    setSupportUploaded: (state, action) => {
      const { uploaded } = action.payload;
      state.uploaded = uploaded;
    },
    clearSupportUsers: (state, action) => {
      state.users = [];
      state.currentSupport = null;
      state.filters = initialState.filters;
    },
    updateSupportUser: (state, action) => {
      const { user } = action.payload;
      state.users = _.map(state.users, (_user) => {
        if (_user.id === user.id) {
          return user;
        }
        return _user;
      });
    },

    setCurrentUserSupport: (state, action) => {
      const { user } = action.payload;
      state.currentSupportUser = user;
      state.tickets = user.tickets;
      state.currentSupportTicket = state.currentSupportTicket
        ? _.find(user.tickets, {
            id: state.currentSupportTicket.id,
          })
        : null;
    },
    addNewTicket: (state, action) => {
      state.currentSupportUser.tickets = _.concat(
        action.payload,
        state.currentSupportUser.tickets
      );
    },
    setCurrentUserSupportById: (state, action) => {
      const { userId } = action.payload;
      state.currentSupportUser = _.find(
        state.supportUsers,
        (_su) => _su.id === parseInt(userId)
      );
    },
    sendingMessage: (state, action) => {
      state.sendingMessage = action.payload;
    },
    setCurrentUserSupportTicketById: (state, action) => {
      const { ticketId } = action.payload;
      state.currentSupportTicket = _.find(
        state.currentSupportUser.tickets,
        (_t) => _t.id === parseInt(ticketId)
      );
    },
    setCurrentUserSupportTicket: (state, action) => {
      state.currentSupportTicket = action.payload;
    },
    clearCurrentSupport: (state, action) => {
      state.currentSupportUser = null;
      state.currentSupportTicket = null;
    },
    setOptions: (state, action) => {
      state.options = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export const getSupportUsers = (data) => async (dispatch) => {
  try {
    const response = await axios.post(arkadu.urls.v2.support.ticket.list, {
      ...data,
    });

    dispatch(slice.actions.setSupportUsers(response.data.data));
    dispatch(slice.actions.setSupportUploaded({ upload: true }));
  } catch (err) {
    console.log(err);
  }
  // dispatch(slice.actions.setCurrentUserSupport(response.data.data));
  //
};

export const addNewTicket = (data) => async (dispatch) => {
  // var response = await axios.post(arkadu.urls.v2.support.ticket.new, data);
  dispatch(slice.actions.addNewTicket(data));
};
export const getUserTickets = (data) => async (dispatch) => {
  var response = await axios.post(arkadu.urls.v2.support.ticket.list, data, {
    withCredentials: true,
  });

  dispatch(slice.actions.setCurrentUserSupport({ user: response.data.data }));
  response = await axios.get(arkadu.urls.v2.support.ticket.options);
  dispatch(slice.actions.setOptions(response.data.data));
};
export const setCurrentUserSupport = (data) => async (dispatch) => {
  dispatch(slice.actions.setCurrentUserSupport({ user: data }));
};
export const setCurrentUserSupportById = (data) => async (dispatch) => {
  dispatch(slice.actions.setCurrentUserSupportById({ userId: data }));
};

export const clearCurrentSupport = (data) => async (dispatch) => {
  dispatch(slice.actions.clearCurrentSupport());
};

export const setCurrentSupportTicket = (data) => async (dispatch) => {
  dispatch(slice.actions.setCurrentUserSupportTicket(data));
};
export const newTicketComment = (data) => async (dispatch) => {
  dispatch(slice.actions.sendingMessage(true));
  var response = await axios.post(arkadu.urls.v2.support.ticket.comment, data);
  dispatch(slice.actions.setCurrentUserSupport({ user: response.data.data }));
  dispatch(slice.actions.sendingMessage(false));
};
export const setCurrentUserSupportTicketById = (data) => async (dispatch) => {
  dispatch(slice.actions.setCurrentUserSupportTicketById({ ticketId: data }));
};
export const setTicketStates = (data) => async (dispatch) => {
  dispatch(slice.actions.setStates(data));
};
