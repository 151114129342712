import { lazy } from "react";
import { useParams } from "react-router";

const NSInscription = lazy(
  () => import("src/views/arkadu/service/forms/NSInscription")
);
const NSServiceInvoiceDetail = lazy(
  () => import("src/views/arkadu/service/NSServiceInvoiceDetail")
);
const NSSearchOld = lazy(
  () => import("src/views/arkadu/service/extra/NSSearchOld")
);
const NSPaymentCollectorStatement = lazy(
  () => import("src/views/arkadu/service/NSPaymentCollectorStatement")
);
const NSPaymentCollectorDetail = lazy(
  () => import("src/views/arkadu/service/NSPaymentCollectorDetail")
);
const NSServiceCustomerDetail = lazy(
  () => import("src/views/arkadu/service/NSServiceCustomerDetail")
);
const NSCustomerInscription = lazy(
  () => import("src/views/arkadu/service/NSCustomerInscription")
);
const ServiceIndex = lazy(() => import("src/views/arkadu/service/index"));
const ServicesLayout = lazy(
  () => import("src/views/arkadu/service/NetsServiceLayout")
);

const IndexOrDetail = () => {
  const { serviceId, tabName } = useParams();

  const isId = !isNaN(serviceId);
  console.log("isId", isId, serviceId, tabName);
  if (serviceId && isId) {
    return <NSServiceCustomerDetail />;
  } else {
    return <ServiceIndex />;
  }
};

export default [
  {
    element: <ServicesLayout />,
    children: [
      {
        index: true,
        element: <ServiceIndex />,
      },
      { path: ":serviceId", element: <IndexOrDetail /> },
      { path: ":tabName", element: <IndexOrDetail /> },
      { path: "inscription", element: <NSInscription /> },
      { path: "invoice/:invoiceId", element: <NSServiceInvoiceDetail /> },
      { path: "searchOld", element: <NSSearchOld /> },
      {
        path: "payment/collector/statement/:id",
        element: <NSPaymentCollectorStatement />,
      },
      {
        path: "payment/collector/:id",
        element: <NSPaymentCollectorDetail />,
      },

      { path: "customers/inscription", element: <NSCustomerInscription /> },
      { path: ":tabName/:serviceId", element: <ServiceIndex /> },
    ],

    async pathRoutesOnNavigation({ path, patch }) {
      console.log("pathRoutesOnNavigation", path, patch);
    },
  },
];
