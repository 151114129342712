import {
  Avatar,
  Box,
  CircularProgress,
  colors,
  Drawer,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  SvgIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { Search as SearchIcon, XCircle as XIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useNavigate } from "react-router";
import TextInputSearch from "src/components/TextInputSearch";
import arkadu from "src/coreConfig";
import { useSelector } from "src/store";
import axios from "src/utils/axios";

const useStyles = makeStyles(() => ({
  drawer: {
    width: 500,
    maxWidth: "100%",
    backgroundColor: `${colors.common.white} !important`,
  },
}));

const Search = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState("");
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const { customers, currentCustomer } = useSelector(
    (state) => state.customers
  );
  let navigate = useNavigate();

  const { t } = useTranslation();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearch = async (v) => {
    try {
      setLoading(true);

      const response = await axios.post(arkadu.urls.v2.user.search, {
        customer_id: currentCustomer.id,
        searchText: v,
      });

      {
        /* console.log(response) */
      }
      if (response.data.res === 1) {
        setResults(response.data.data);
      } else {
        enqueueSnackbar(response.data.message);
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  if (!currentCustomer) {
    return null;
  }
  return (
    <>
      <Tooltip title="Search">
        <IconButton color="inherit" onClick={handleOpen} size="large">
          <SvgIcon fontSize="small">
            <SearchIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Drawer
        anchor="right"
        classes={{ paper: classes.drawer }}
        ModalProps={{ BackdropProps: { invisible: true } }}
        onClose={handleClose}
        open={isOpen}
        variant="temporary"
      >
        <PerfectScrollbar options={{ suppressScrollX: true }}>
          <Box p={3}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h4" color="textPrimary">
                {t("Buscar")}
              </Typography>
              <IconButton onClick={handleClose} size="large">
                <SvgIcon fontSize="small">
                  <XIcon />
                </SvgIcon>
              </IconButton>
            </Box>
            <Box mt={2}>
              <TextInputSearch onSearch={handleSearch} />
            </Box>

            <Box mt={4}>
              {isLoading ? (
                <Box display="flex" justifyContent="center">
                  <CircularProgress />
                </Box>
              ) : (
                <MenuList>
                  {results.map((result, i) => {
                    return (
                      <MenuItem
                        key={i}
                        onClick={() => {
                          navigate(
                            `/${currentCustomer.shortname}/user/admin/${result.id}`
                          );
                          handleClose();
                        }}
                      >
                        <ListItemIcon>
                          <Avatar
                            alt={result.fullname}
                            src={result.avatar}
                            sx={{
                              height: 32,
                              width: 32,
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={result.fullname}
                          secondary={result.email}
                        />
                      </MenuItem>
                    );
                  })}
                </MenuList>
              )}
            </Box>
          </Box>
        </PerfectScrollbar>
      </Drawer>
    </>
  );
};

export default Search;
