import { useNavigate } from "react-router-dom";

import {
  Avatar,
  Grid2 as Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuList,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Label from "src/components/Label";
import LoadingScreen from "src/components/LoadingScreen";
import arkadu, { media_url } from "src/coreConfig";
import { useDispatch, useSelector } from "src/store";
import axios from "src/utils/axios";

const AccountType = ({ accountType }) => {
  const { t } = useTranslation();
  const typeVerbose = {
    savings: {
      label: t("Ahorro"),
      color: "info",
    },
    checking: {
      label: t("Corriente"),
      color: "info",
    },
  };
  return (
    <Label color={typeVerbose[accountType]?.color ?? "info"}>
      {typeVerbose[accountType]?.label ?? accountType}
    </Label>
  );
};
const PaymentAccounts = () => {
  const { t } = useTranslation();
  const { adminMode } = useSelector((state) => state.settings);
  const { currentCustomer } = useSelector((state) => state.customers);
  const { user } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const initialized = useRef();
  let navigate = useNavigate();
  const [isSearching, setIsSearching] = useState(false);
  const handleSearch = (searchText) => {};
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState(null);

  const loadData = async () => {
    var res = await axios.post(arkadu.urls.v2);
    if (res.data.res === 1) {
      setData(res.data.data);
    } else {
      enqueueSnackbar(res.data.message || "error", { variant: "error" });
    }
  };

  useEffect(() => {
    if (initialized.current === undefined) {
      initialized.current = true;
    }
  }, []);
  if (!currentCustomer) {
    return <LoadingScreen message={t("Cargando componentes")} />;
  }

  return (
    <Grid container spacing={3}>
      <Grid size={12}>
        <MenuList>
          {currentCustomer.accounts?.map((a) => {
            if (!a.is_public || !a.transfers_enabled) return null;
            return (
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    src={media_url(a.image)}
                    sx={{ width: 64, height: 64 }}
                  />
                </ListItemAvatar>
                <ListItemText
                  sx={{ marginLeft: "18px" }}
                  primary={a.name}
                  secondary={
                    <React.Fragment>
                      <Typography sx={{ display: "inline-block" }}>
                        {a.account_number}{" "}
                      </Typography>
                      <Typography
                        sx={{ display: "inline-block", paddingLeft: "10px" }}
                      >
                        <AccountType accountType={a.account_type} />
                      </Typography>
                      <Typography>{a.holders_name}</Typography>
                      <Typography>{a.documentId}</Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
            );
          })}
        </MenuList>
      </Grid>
      {/* {currentCustomer.accounts ? (
        currentCustomer.accounts.map((a) => {
          if (!a.is_public) {
            return null;
          }

          return (
            <Grid item xs={12} md={6} lg={4} key={`${a.id}-pa`}>
            
              <ColorCard avatarImg={a.image} title={a.name}>
                <Table
                  sx={{
                    "& td": {
                      padding: "3px !important",
                      borderBottom: "none",
                    },
                  }}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell variant="head">{t("T�tular")}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{a.holders_name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">{t("N�mero")}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{a.account_number}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">{t("Doc ID")}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{a.documentId}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">{t("Tipo")}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {t(
                          a.account_type === "checking"
                            ? "Corriente"
                            : a.account_type
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </ColorCard>
            </Grid>
          );
        })
      ) : (
        <Typography>
          {t("No se encontraron cuentas para registrar pagos en este momento.")}
        </Typography>
      )} */}
    </Grid>
  );
};

export default PaymentAccounts;
