import { lazy } from "react";

const UrbinLayout = lazy(() => import("src/views/arkadu/urbin/UrbinLayout"));
const UrbinIndex = lazy(() => import("src/views/arkadu/urbin/UrbinIndex"));
const UrbinRequestDetail = lazy(
  () => import("src/views/arkadu/urbin/UrbinRequestDetail")
);

export default [
  {
    element: <UrbinLayout />,
    children: [
      {
        index: true,
        element: <UrbinIndex />,
      },
      {
        path: "request/detail/:requestId",
        element: <UrbinRequestDetail />,
      },
      {
        path: ":tabName",
        element: <UrbinIndex />,
      },
    ],
  },
];
