import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  requestOptions: {
    categories: [],
    priorities: [],
    status: [],
    loaded: false,
  },
};

const slice = createSlice({
  name: "nets_dev",
  initialState,
  reducers: {
    setDevState: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      });
    },
  },
});

export const setDevState = (data) => async (dispatch) => {
  dispatch(slice.actions.setDevState(data));
};

export const reducer = slice.reducer;
