import { createSlice } from "@reduxjs/toolkit";
import arkadu from "src/coreConfig";
import axios from "src/utils/axios";

const initialState = {
  countries: [],
  geoLoaded: false,
};

const slice = createSlice({
  name: "geo",
  initialState,
  reducers: {
    setCountries: (state, action) => {
      state.countries = action.payload;
      state.geoLoaded = true;
    },
  },
});

export const reducer = slice.reducer;

export const loadCountries = (data) => async (dispatch) => {
  var response = await axios.post(arkadu.urls.geo.country_list, {
    ...data,
    is_active: 0,
  });
  dispatch(slice.actions.setCountries(response.data.data));
};
