import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  auditTypes: [],
  auditStatus: [],
  currentAudit: null,
  currentSummon: null,
  summonStatus: [],
  summonsCount: 0,
  departments: [],
  currentAuditItems: {
    licenses: [],
    addresses: [],
  },
  audits: [],
  auditsCount: 0,
  summons: [],
  selectedAuditId: null,
  auditError: null,
  loaded: false,
  auditLoaded: false,
};

const slice = createSlice({
  name: "audit",
  initialState,
  reducers: {
    setAuditItems(state, action) {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      });
    },
  },
});

export const reducer = slice.reducer;

export const setAuditItems = (data) => async (dispatch) => {
  console.log("setAuditItems  data", data);
  dispatch(slice.actions.setAuditItems(data));
};

export default slice;
