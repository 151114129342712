import { createSlice } from "@reduxjs/toolkit";
import arkadu from "src/coreConfig";
import axios from "src/utils/axios";

const initialState = {
  mainTopics: [],
  currentTopic: null,
  helpOpen: false,
  loadingHelp: false,
};

const slice = createSlice({
  name: "help",
  initialState,

  reducers: {
    setCurrentTopic: (state, action) => {
      state.currentTopic = action.payload;
    },
    toggleHelp: (state, action) => {
      state.helpOpen = Boolean(action.payload);
    },
    setMainTopics: (state, action) => {
      state.mainTopics = action.payload;
    },
    setLoading: (state, action) => {
      state.loadingHelp = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export const setCurrentTopic = (data) => async (dispatch) => {
  dispatch(slice.actions.setCurrentTopic(data));
};

export const getHelpTopics = (data) => async (dispatch) => {
  dispatch(slice.actions.setLoading(true));
  const response = await axios.post(arkadu.urls.v2.help.topic, data);

  dispatch(slice.actions.setMainTopics(response.data.data));
  dispatch(slice.actions.setLoading(false));
};

export const getHelpById = (id) => async (dispatch) => {
  var response = await axios.get(`${arkadu.urls.v2.help.getByID}${id}`);
  dispatch(slice.actions.setCurrentTopic(response.data.data));
  dispatch(toggleHelp(true));
};

export const toggleHelp = (data) => async (dispatch) => {
  dispatch(slice.actions.toggleHelp(data));
};

export default slice;
