import { AccountBoxRounded, ArrowDropDown } from "@mui/icons-material";
import { SvgIcon, TextField } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import { setUserState } from "src/slices/users";
import { useDispatch, useSelector } from "src/store";

const IdentityMenu = () => {
  const { selectedIdentity } = useSelector((state) => state.users);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!selectedIdentity) {
    return null;
  }
  return (
    <TextField
      size="small"
      sx={(theme) => ({
        width: {
          xs: "100%",
          sm: "200px",
        },
        "& .MuiInputBase-root": {
          fontSize: "0.75rem",
        },
        backgroundColor: theme.palette.background.paper,
        borderRadius: "5px",
      })}
      onClick={() => {
        dispatch(setUserState({ identitySelection: true }));
      }}
      slotProps={{
        input: {
          startAdornment: (
            <SvgIcon fontSize="small">
              <AccountBoxRounded />
            </SvgIcon>
          ),
          endAdornment: (
            <SvgIcon fontSize="small">
              <ArrowDropDown />
            </SvgIcon>
          ),
        },
      }}
      value={`${selectedIdentity.short_id} - ${selectedIdentity.legal_name}`}
    />
  );
};

export default IdentityMenu;
