import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  taxUnits: null,
  currentTaxUnit: null,
  currencies: null,
  currentCurrency: null,
  arrearsRate: null,
  globalPermissions: {
    addTaxUnit: false,
    addCurrency: false,
    addArrearsRate: false,
  },
};

const slice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setGlobalItem: (state, action) => {
      const { name, data } = action.payload;
      if (name) {
        state[name] = data;
      }
    },
    resetGlobals: (state, action) => {
      state = initialState;
    },
  },
});

export const reducer = slice.reducer;

export const setGlobalItem = (data) => async (dispatch) => {
  dispatch(slice.actions.setGlobalItem(data));
};

export const resetGlobals = (data) => async (dispatch) => {
  dispatch(slice.actions.resetGlobals());
};
