import React from "react";
import { useTranslation } from "react-i18next";
import { dbName, server } from "src/coreConfig";
import { setUserState } from "src/slices/users";
import { useDispatch, useSelector } from "src/store";
import useAsyncRequest from "./useAsyncRequest";
import { useCanDo } from "./useCando";
import useStoreDB from "./useStoreDB";

const userUrls = {
  getProfile: `${server}/api/v2/user/user/getProfile/`,
  get: `${server}/api/v2/user/user/get/`,
  address: `${server}/api/v2/user/address/`,
  assisted: `${server}/api/v2/user/user/assisted/`,
  change_email: `${server}/api/v2/user/user/changeEmail/`,
  company: `${server}/api/v2/user/company/`,
  company_update: `${server}/api/v2/user/company/update/`,
  company_member: `${server}/api/v2/user/company/member/`,
  companySearch: `${server}/api/v2/user/company/search/`,
  document: {
    download: `${server}/api/v2/user/document/download/`,
    list: `${server}/api/v2/user/document/`,
  },
  // document: `${server}/api/v2/user/document/`,
  folder: `${server}/api/v2/user/folder/`,
  get_device_uuid: `${server}/api/v2/user/device/getDevUID/`,
  identity: {
    getTypes: `${server}/api/v2/user/identity/getTypes/`,
    base: `${server}/api/v2/user/identity/`,
    checkIdentity: `${server}/api/v2/user/identity/checkIdentity/`,
    delete: `${server}/api/v2/user/identity/delete/`,
    new: `${server}/api/v2/user/identity/new/`,
    setMain: `${server}/api/v2/user/identity/setMain/`,
    update: `${server}/api/v2/user/identity/update/`,
    setSessionIdentity: `${server}/api/v2/user/identity/setSessionIdentity/`,
  },
  identityCompanies: `${server}/api/v2/user/company/identityCompanies/`,
  identityFile: `${server}/api/v2/user/identity/addFile/`,
  logout: `${server}/api/v2/user/user/logout/`,
  phone: {
    delete: `${server}/api/v2/user/phone/delete/`,
    new: `${server}/api/v2/user/phone/new/`,
    update: `${server}/api/v2/user/phone/update/`,
  },
  pinAuthorization: `${server}/api/v2/user/user/pinAuthorization/`,
  role: {
    addPermissions: `${server}/api/v2/user/role/addPermissions/`,
    deletePermission: `${server}/api/v2/user/role/deletePermission/`,
    detail: `${server}/api/v2/user/role/detail/`,
    list: `${server}/api/v2/user/role/`,
    options: `${server}/api/v2/user/role/options/`,
    addUser: `${server}/api/v2/user/role/addUser/`,
    removeUser: `${server}/api/v2/user/role/removeUser/`,
    getUserPermissions: `${server}/api/v2/user/role/getUserPermissions/`,
    getUserLogs: `${server}/api/v2/user/role/getUserLogs/`,
    addMember: `${server}/api/v2/user/role/addMember/`,
    deleteMember: `${server}/api/v2/user/role/deleteMember/`,
    updateMember: `${server}/api/v2/user/role/updateMember/`,
  },
  search: `${server}/api/v2/user/user/search/`,
  setPin: `${server}/api/v2/user/user/setPin/`,
  update: `${server}/api/v2/user/user/update/`,
  userAddresses: `${server}/api/v2/user/address/userAddresses/`,
  userIdentities: `${server}/api/v2/user/identity/userIdentities/`,
  getIpInfo: `${server}/api/v2/user/user/getIpInfo/`,
};

const useUser = () => {
  const { currentCustomer } = useSelector((state) => state.customers);
  const {
    user,
    loaded,
    isAuthenticated,
    ipLocations,
    members,
    roles,
    selectedIdentity,
  } = useSelector((state) => state.users);
  const { adminMode } = useSelector((state) => state.settings);
  const { api } = useAsyncRequest();
  const { canDo } = useCanDo("nets_user");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { read, write, error, dbLoading, dbInitialized } = useStoreDB(dbName);

  const getProfile = async () => {
    try {
      var res = await api(
        userUrls.getProfile,
        {
          customer_id: currentCustomer?.id,
          mode: adminMode ? "admin" : "user",
        },
        {},
        t("Obteniendo perfil"),
        true
      );
      if (res.status === 403) {
        dispatch(setUserState({ isAuthenticated: false, loaded: true }));
        return null;
      }
      if (res.data && res.data.user) {
        var accessToken = localStorage.getItem("accessToken");
        if (accessToken) {
          res.data.user.accessToken = accessToken;
        }
        // saveUserToDB(res.data.user);
        var extendedUser = {
          ...res.data.user,
          last_login: new Date(),
          selectedIdentity: selectedIdentity,
        };
        write("user", extendedUser);
        dispatch(
          setUserState({
            user: res.data.user,
            identities: res.data.user.identities,
            loaded: true,
            isAuthenticated: true,
          })
        );
      }
      return res.data.data;
    } catch (e) {
      console.log("getProfile error", e);
      return null;
    }
  };

  const getUser = async (id, onBackground) => {
    var res = await api(
      userUrls.get,
      {
        id: id,
        customer_id: currentCustomer.id,
        mode: adminMode ? "admin" : "user",
      },
      {},
      t("Obteniendo usuario"),
      onBackground
    );
    return res.data.data;
  };

  const addUserRole = async ({ roleId, userId }) => {
    return await api(
      userUrls.role.addUser,
      {
        role_id: roleId,
        user_id: userId,
        customer_id: currentCustomer.id,
      },
      {},
      t("Agregando rol")
    );
  };
  const removeUserRole = async ({ roleId, userId }) => {
    return await api(
      userUrls.role.removeUser,
      {
        role_id: roleId,
        user_id: userId,
        customer_id: currentCustomer.id,
      },
      {},
      t("Eliminando rol")
    );
  };
  const roleList = async () => {
    var res = await api(
      userUrls.role.list,
      {
        customer_id: currentCustomer.id,
        mode: adminMode ? "admin" : "user",
      },
      {},
      t("Obteniendo roles")
    );
    console.log("roleList", res);
    dispatch(
      setUserState({
        roles: res.data.data.roles,
        members: res.data.data.members,
        role_users: res.data.data.role_users,
      })
    );
  };

  const addMember = async (data) => {
    var res = await api(
      userUrls.role.addMember,
      {
        ...data,
        customer_id: currentCustomer.id,
      },
      {},
      t("Agregando miembro")
    );
    // add or update member

    var membersUpdated = members.filter((item) => {
      return item.id !== res.data.data.id;
    });
    membersUpdated = [res.data.data, ...membersUpdated];

    dispatch(
      setUserState({
        members: membersUpdated,
      })
    );
  };

  const deleteMember = async (id) => {
    var res = await api(
      userUrls.role.deleteMember,
      {
        user_id: id,
        customer_id: currentCustomer.id,
      },
      {},
      t("Eliminando miembro")
    );
    dispatch(
      setUserState({
        members: members.filter((item) => item.user.id !== id),
      })
    );
  };

  const getUserPermissions = async (userId) => {
    var res = await api(
      userUrls.role.getUserPermissions,
      {
        user_id: userId,
        customer_id: currentCustomer.id,
      },
      {},
      t("Obteniendo permisos")
    );
    return res.data.data;
  };
  const getUserLogs = async (userId, page) => {
    var res = await api(
      userUrls.role.getUserLogs,
      {
        user_id: userId,
        customer_id: currentCustomer.id,
        page: page || 1,
        page_size: 20,
      },
      {},
      null
    );
    return res.data.data;
  };
  const getIpInfo = async (ip) => {
    if (ipLocations[ip]) return ipLocations[ip];
    var res = await api(
      userUrls.getIpInfo,
      {
        ip: ip,
      },
      {},
      null
    );
    dispatch(
      setUserState({
        ipLocations: {
          ...ipLocations,
          [ip]: res.data.data,
        },
      })
    );
    return res.data.data;
  };

  const checkUser = async () => {
    var user = await read("user");
    console.log("read user", user);
    // if last login is more than 1 day, then load from server
    if (user && new Date() - user.last_login > 86400000) {
      user = null;
    }
    if (user) {
      dispatch(
        setUserState({
          user,
          selectedIdentity: user.selectedIdentity,
          loaded: true,
          isAuthenticated: true,
          identities: user.identities || [],
        })
      );
      return;
    }
    // if not user, then check from server
    getProfile();
  };

  const getIdentityTypes = async () => {
    var res = await api(
      userUrls.identity.getTypes,
      {},
      {},
      t("Obteniendo tipos de identidad")
    );
    if (res.status === 200) {
      dispatch(setUserState({ identityTypes: res.data.data }));
    } else {
      console.log("error", res);
    }
  };
  const newIdentity = async (values) => {
    try {
      var res = await api(
        userUrls.identity.new,
        {
          ...values,
        },
        {},
        t("Creando identidad")
      );
      dispatch(
        setUserState({
          user: res.data.data,
          identities: res.data.data.identities || [],
          selectedIdentity: res.data.data.identities.find(
            (item) => item.national_id === values.national_id
          ),
        })
      );
      setSessionIdentity(
        res.data.data.identities.find(
          (item) => item.national_id === values.national_id
        ).id
      );
      return res.data.data;
    } catch (e) {
      console.log("newIdentity error", e);
      return null;
    }
  };

  const setSessionIdentity = async (identityId) => {
    var res = await api(
      userUrls.identity.setSessionIdentity,
      {
        id: identityId,
      },
      {},
      t("Estableciendo identidad"),
      true
    );
    if (!res.status === 200) {
      console.log("error", res);
      enqueueSnackbar(res.data.message, { variant: "error" });
    }
  };

  React.useEffect(() => {
    console.log("useUser", user, loaded, isAuthenticated);
    if (!user && !loaded && !isAuthenticated && dbInitialized) {
      checkUser();
    }
    if (user && selectedIdentity) {
      if (user.selectedIdentity !== selectedIdentity) {
        // save selectedIdentity to db
        write("user", {
          ...user,
          selectedIdentity: selectedIdentity,
        });
      }
    }
  }, [user, loaded, isAuthenticated, dbInitialized, selectedIdentity]);
  return {
    role: {
      addUser: addUserRole,
      removeUser: removeUserRole,
      list: roleList,
      getUserPermissions: getUserPermissions,
      getUserLogs: getUserLogs,
      addMember,
      deleteMember,
      updateMember: addMember,
    },
    getUser,
    getIpInfo,
    getProfile,
    identity: {
      getTypes: getIdentityTypes,
      new: newIdentity,
      setSessionIdentity,
    },
  };
};

export default useUser;
