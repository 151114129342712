import React, { createContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router";
import LoadingScreen from "src/components/LoadingScreen";
import arkadu from "src/coreConfig";
import useTaxes from "src/hooks/useTaxes";
import useWebSocket from "src/hooks/useWebSocket";
import { setCustomerPeriods } from "src/slices/customers";
import {
  setCurrentLicense,
  setTaxesElement,
  setUpdatedTaxes,
} from "src/slices/taxes";
import { useDispatch, useSelector } from "src/store";
import ErrorView from "src/views/errors/ErrorView";

const TaxesContext = createContext();

const TaxesLayout = ({ ...rest }) => {
  const {
    licenses,
    currentLicense,
    liquorActivities,
    currentCustomerClassifier,
    licenseProcedures,
    stats,
    stats_analysis,
  } = useSelector((state) => state.taxes);
  const { currentCustomer, periods } = useSelector((state) => state.customers);
  const { adminMode } = useSelector((state) => state.settings);
  const [lastMode, setLastMode] = useState(null);
  const { t } = useTranslation();
  const [error, setError] = useState();
  const dispatch = useDispatch();
  const { getTaxesConfig } = useTaxes();
  const [fetchRequests, setFetchRequests] = useState([]);
  const requestTypes = {
    license: "license_detail",
    license_declarations: "license_declarations",
    license_search: "license_search",
  };
  let statsDebounce = useRef(null);

  const initialized = useRef(false);
  const { sendWs, ws, wsOnline, wsMessages, lastMessage } = useWebSocket(
    arkadu.urls.webSocket.taxes
  );

  const refreshTaxes = () => {
    if (ws && ws.current && wsOnline && currentCustomer) {
      sendWs(
        JSON.stringify({
          type: "tax_request",
          message: {
            type: "refresh_taxes",
            customer_id: currentCustomer.id,
            mode: adminMode ? "admin" : "user",
          },
        })
      );
    }
  };

  // const getStats = () => {
  //   if (!adminMode) {
  //     return;
  //   }
  //   if (statsDebounce.current) {
  //     clearTimeout(statsDebounce.current);
  //   }
  //   statsDebounce.current = setTimeout(() => {
  //     if (ws && ws.current && wsOnline && currentCustomer) {
  //       sendWs(
  //         JSON.stringify({
  //           type: "tax_request",
  //           message: {
  //             type: "tax_stats",
  //             customer_id: currentCustomer.id,
  //             mode: adminMode ? "admin" : "user",
  //           },
  //         })
  //       );
  //     }
  //   }, 500);
  // };

  useEffect(() => {
    // if (wsOnline && currentCustomer) {
    //   refreshTaxes();
    //   getStats();
    // }
    // sendWs(JSON.stringify({
    //     type: "tax_request",
    //     message: {
    //         type: requestTypes[r.itemName],
    //         id: r.itemId,
    //         customer_id: currentCustomer.id,
    //         mode: adminMode ? 'admin': 'user'
    //     }
    // }));
    // if(ws && ws.current && wsOnline ) {
    //     if(fetchRequests.length > 0) {
    //         var fR = fetchRequests;
    //         var processed = [];
    //         fR.forEach((r, i) => {
    //             try {
    //                 ws.current.send(JSON.stringify({
    //                     type: "tax_request",
    //                     message: {
    //                         type: requestTypes[r.itemName],
    //                         id: r.itemId,
    //                         customer_id: currentCustomer.id,
    //                         mode: adminMode ? 'admin': 'user'
    //                     }
    //                 }));
    //                 processed.push(i);
    //             } catch (e) {
    //             }
    //         });
    //         setFetchRequests(prevState => (_.map((f,id ) => {
    //             if(!processed.includes(id)) {
    //                 return f;
    //             }
    //         })));
    //     }
    // }
  }, [ws, wsOnline, fetchRequests, adminMode]);

  const fetchTaxesItem = ({ itemName, itemId }) => {
    if (!itemName) {
      return;
    }
    var msg = JSON.stringify({
      type: "tax_request",
      message: {
        type: requestTypes[itemName] || itemName,
        id: itemId,
        customer_id: currentCustomer.id,
        mode: adminMode ? "admin" : "user",
      },
    });
    sendWs(msg);
    // setFetchRequests(prevState => (_.concat(prevState, [{itemName, itemId}])));
  };
  useEffect(() => {
    if (!periods && currentCustomer && currentCustomer.periods) {
      dispatch(setCustomerPeriods(currentCustomer.periods));
    }
  }, [currentCustomer, periods]);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      if (currentCustomer && !currentCustomerClassifier) {
        getTaxesConfig();
      }
    }
  }, [currentCustomerClassifier, currentCustomer]);

  // useEffect(() => {
  //     if (currentCustomer && !licenses && !initialRequested) {
  //         setInitialRequested(true);
  //         getLicenses();
  //     }
  // }, [currentCustomer, licenses]);
  // useEffect(() => {
  //     if (ws && ws.current && wsOnline && currentCustomer && !licenses) {
  //         refreshTaxes();
  //     }
  // }, [ws, wsOnline, currentCustomer]);

  useEffect(() => {
    if (lastMode === null) {
      setLastMode(adminMode);
    } else {
      if (lastMode !== adminMode) {
        setLastMode(adminMode);

        if (ws && ws.current && wsOnline && currentCustomer) {
          refreshTaxes();
        }
      }
    }
  }, [adminMode]);

  useEffect(() => {
    if (lastMessage && lastMessage.type) {
      console.log("TaxesLayout -> lastMessage", lastMessage);
      switch (lastMessage.type) {
        case "tax_error":
          setError(lastMessage.description);
          break;
        case "taxes_updated":
          dispatch(setUpdatedTaxes(lastMessage));
          break;
        case "license_detailed":
          dispatch(setCurrentLicense(lastMessage.data));
          break;
        case "tax_monitor_stats":
          dispatch(
            setTaxesElement({
              stats: lastMessage.data,
            })
          );
          break;
        case "tax_stats_periods_analysis":
          if (lastMessage.data?.analysis) {
            dispatch(
              setTaxesElement({
                stats_analysis: {
                  ...(stats_analysis || {}),
                  periods: lastMessage.data?.analysis,
                },
              })
            );
          }
          break;
      }
    }
  }, [lastMessage]);

  useEffect(() => {
    console.log("TaxesLayout -> stats", stats);
  }, [stats]);

  if (!currentCustomer && !currentCustomerClassifier) {
    return <LoadingScreen message={t("cargando componentes...")} />;
  }
  if (error) {
    return <ErrorView message={error} />;
  }

  return (
    <TaxesContext.Provider value={{ ws, wsOnline, fetchTaxesItem, sendWs }}>
      <Outlet />
    </TaxesContext.Provider>
  );
};
export { TaxesContext };
export default TaxesLayout;
