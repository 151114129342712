import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  premium: false,
  status: "inactive",
  message: "",
  success: false,
  sessionId: "",
  customer: null,
  subscriptions: [],
  price: null,
  product: null,
  loading: false,
  error: null,
  loaded: false,
};

const premiumSlice = createSlice({
  name: "premium",
  initialState,
  reducers: {
    setPremium: (state, action) => {
      state.premium = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setPremiumItems: (state, action) => {
      Object.keys(action.payload).map((item) => {
        state[item] = action.payload[item];
      });
    },
  },
});

export const { setPremium, setStatus, setPremiumItems } = premiumSlice.actions;

export const reducer = premiumSlice.reducer;

export const loadSubscriptions = () => async (dispatch, getState) => {
  const { premium } = getState();
  if (premium.loading) {
    return;
  }
  dispatch(setPremiumItems({ loading: true }));
  try {
    const response = await fetch("/stripe/check-subscriptions/");
    const data = await response.json();
    console.log("loadSubscriptions: data: ", data);

    if (data.res === 1) {
      dispatch(
        setPremiumItems({
          subscriptions: data.data,
          loading: false,
          loaded: true,
        })
      );
    } else {
      dispatch(setPremiumItems({ error: data.message, loading: false }));
    }
  } catch (error) {
    dispatch(setPremiumItems({ error: error.message, loading: false }));
  }
};
